import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";
import _ from "lodash";
import React from "react";

export default (props) => {
  const [documentTypes, setDocumentTypes] = React.useState<any>([]);
  const [loadingFilter, setloadingFilter] = React.useState<any>(true);
  // Utils AutoCopmlete
  const getParams = () => {
    let myParams: any = {
      limit: 99999,
    };
    return myParams;
  };
  const getConvertLists = (datas) => {
    return convertToSelect({
      datas: datas,
      labelName: "name",
      valueName: "id",
    });
  };
  // Get Request
  const getDocumentTypes = async () => {
    let endpoint = `document_types`;
    const res = await req.get({ endpoint, params:getParams() });
    if (res.status == "success") {
      await setDocumentTypes(getConvertLists(res.data));
    }
  };

  const initFunction = async () => {
    await setloadingFilter(true)
    await Promise.all([
      getDocumentTypes()
    ])
    await setloadingFilter(false)
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    documentTypes,
    loadingFilter,
  };
};
