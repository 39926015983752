const kindList = [
  { value: "car", label: "Car", text: "Car" },
  { value: "truck", label: "Truck", text: "Truck" },
  { value: "bus", label: "Bus", text: "Bus" },
  { value: "motorcycle", label: "Motorcycle", text: "Motorcycle" },
  { value: "bike", label: "Bike", text: "Bike" },
  { value: "plane", label: "Plane", text: "Plane" },
  { value: "train", label: "Train", text: "Train" },
  { value: "boat", label: "Boat", text: "Boat" },
  { value: "ship", label: "Ship", text: "Ship" },
  { value: "other", label: "Other", text: "Other" },
];
export { kindList };
