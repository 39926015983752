import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import dayjs from "dayjs";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, handleSubmit } = props;
  const { fileThumbnail } = useLogic(props);
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24} className="mb15">
          <Row>
            <Col span={10}>Tipe Kendaraan</Col>
            <Col span={14}>{values?.header_meta?.type_vehicle}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Field name="name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama File",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: STNK"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="description">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Deskripsi",
                  labelAlign: "left",
                  noReq: true,
                }}
                type="AntInput"
                placeholder="Ex: Hasil scan dari stnk mobil"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mb5">
          <UploadFile
            setFile={(file) => setFieldValue("file", file)}
            initDataName={fileThumbnail}
            label="File Dok."
            extLists={[
              "pdf",
              "jpg",
              "jpeg",
              "png",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
              "doc",
              "docx",
            ]}
            invalidMessage="You can upload only Document/Image/PPt/Xls File !"
            isReq
          />
        </Col>

        <Col span={24}>
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
