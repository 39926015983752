import React from "react";
import { Select } from "antd";
import s from "./index.module.scss";
import { FaSearch } from "react-icons/fa";
import { convertToSelect } from "app/utils/functions";
import { useHistory } from "react-router-dom";
import menus from "app/_components/layout/private/components/sider/main/menus";

const Option = Select.Option;

const menuOptions = convertToSelect({
  datas: menus,
  labelName: "label",
  valueName: "slug",
});

const Index: React.FC<any> = () => {
  const { push } = useHistory();
  const onSelect = (value) => {
    push(value);
  };
  return (
    <Select
      showSearch
      placeholder="Search Menu"
      className={s.search}
      suffixIcon={<FaSearch className={s.icon} />}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      bordered={false}
      onSelect={onSelect}
    >
      {menuOptions.map((item, i) => (
        <Option key={i} value={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};
export default Index;
