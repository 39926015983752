// // setter
// localStorage.setItem('myData','asd');
// // getter
// localStorage.getItem('myData');
// // remove
// localStorage.removeItem('myData');
// // remove all
// localStorage.clear();
export const setEmailLocal = (email: string) => {
  localStorage.setItem("email", email);
};
export const getEmailLocal = (): any => {
  return localStorage.getItem("email") ?? false;
};
export const setEmailRemember = (email: string) => {
  localStorage.setItem("email_remember", email);
};
export const getEmailRemember = (): any => {
  return localStorage.getItem("email_remember") ?? false;
};

export const clear = (key): void => {
  localStorage.removeItem(key);
};
export const clearAll = (): void => {
  localStorage.clear();
};
export default {};
