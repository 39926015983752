import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { NAMESPACES, PUBLIC_URL } from "app/utils/consts";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    supportedLngs: ["en", "id"],
    debug: false,
    defaultNS: "_common",
    ns: NAMESPACES,
    backend: {
      loadPath: () => {
        return `${PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`;
        // // check the domain
        // return process.env.NODE_ENV !== "production"
        //   ? `/locales/{{lng}}/{{ns}}.json`
        //   : `/static/app/static/locales/{{lng}}/{{ns}}.json`;
      },
    },
    load: "languageOnly",
    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
