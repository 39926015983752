import React from "react";
import { Button, Avatar, Image, Typography, Tag } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";

const { Text } = Typography;

dayjs.extend(relativeTime);

type TAction = {
  editAction: (record: any) => any;
  delAction: (record: any) => any;
};

const columns = (actions: TAction): any => {
  return [
    {
      title: "Nama Vendor",
      dataIndex: "name",
      sorter: true,
      render: (item, record) => {
        return item ? item : "--";
      },
    },
    {
      title: "No Telp. ",
      dataIndex: "telp",
      width: 185,
      sorter: true,
      render: (item) => {
        let items: any = "--";
        if (item) {
          items = item.split(";");
          return items.map((tmp) => {
            return <p>{tmp}</p>;
          });
        }
        return items;
      },
    },
    {
      title: "Alamat ",
      dataIndex: "address",
      sorter: true,
      render: (item) => {
        return item ? item : "--";
      },
    },
    {
      title: "Diperbarui pada",
      dataIndex: "updated_at",
      width: 125,
      render: (item, record) => {
        if (item) return dayjs(item).format("DD-MMM-YYYY") ?? "--";
        return "--";
      },
    },
    {
      title: "Diperbarui oleh",
      render: (item, record) => {
        return (
          <div>
            <p>{record?.updater?.name ?? "--"}</p>
            <p>{record?.updater?.email}</p>
          </div>
        );
      },
    },
    {
      title: "Aksi",
      width: 100,
      align: "center",
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
