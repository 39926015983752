import React from "react";
import _ from "lodash";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import { Row, Col, Button, Divider } from "antd";
import useLogic from "./_logic";
import Form from "../_components/form";

const Index: React.FC<any> = () => {
  const { onSubmit } = useLogic();

  return (
    <React.Fragment>
      <Layout>
        <div className="content_wrapper">
          <Row>
            <Col span={24}>
              <Form onSubmit={onSubmit} />
            </Col>
          </Row>
        </div>
      </Layout>
    </React.Fragment>
  );
};
export default Index;
