import React from "react";
import { IoIosTrash } from "react-icons/io";
import { AiOutlinePushpin, AiOutlineDelete } from "react-icons/ai";
import { Row, Button, Tag, Dropdown, Menu, message, Popconfirm } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import s from "./context.module.scss";
import _ from "lodash";

dayjs.extend(relativeTime);

interface IProps {
  key?: any;
  children?: React.ReactElement;
  role: any;
  actions: {
    delAction: (roleName: string) => any;
    defaultAction: (roleName: string) => any;
  };
}

const Index: React.FC<IProps> = (props: IProps) => {
  const [visible, setVisible] = React.useState(false);
  const { defaultAction, delAction } = props.actions;
  const { role } = props;
  const handleMenuClick = ({ key }) => {
    if (key !== "2") setVisible(false);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        className={s.contextMenu}
        onClick={() => {
          defaultAction(role.name);
          setVisible(false);
        }}
      >
        <AiOutlinePushpin /> Set Default
      </Menu.Item>
      <Menu.Item key="2">
        <Popconfirm
          title="Apakah anda yakin ?"
          onConfirm={() => {
            delAction(role.name);
            setVisible(false);
          }}
          okText="Yes"
          cancelText="No"
        >
          <div className={s.contextMenu}>
            <AiOutlineDelete />
            Hapus
          </div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["contextMenu"]}
      visible={visible}
      onVisibleChange={(e) => setVisible(e)}
    >
      {props.children}
    </Dropdown>
  );
};
export default Index;
