import * as layout from "./form_layout";
import { Button, Col, Form, Row, Checkbox } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import { FiTrello, FiLock } from "react-icons/fi";
import AntFields from "app/_components/ant_design/ant_custom";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
  isCurrent: boolean;
}

const Index = (props: ITranslate) => {
  const { handleSubmit, isSubmitting, values, isCurrent } = props;
  const { formLayout } = layout;
  return (
    <Form {...formLayout}>
      <Row className="mb15" style={{ marginTop: "-15px" }}>
        <div>
          <span className="text-bold">Catatan : </span>
          <span className="text-red">Mohon diperhatikan !</span>
          <p className="text-muted text-xs">
            Setelah mengubah password, password akan langsung terganti.
          </p>
          <p className="text-muted text-xs">
            Dimana jika anda akan login kembali, gunakan password yang baru !
          </p>
        </div>
      </Row>
      <Row align="middle">
        {!isCurrent && (
          <Col span={24}>
            <Field name="old_password">
              {(fieldProps) => (
                <AntFields
                  {...fieldProps}
                  formItem={{
                    label: "Passowrd Lama",
                    noReq: true,
                    labelAlign: "left",
                  }}
                  prefix={<FiLock />}
                  type="AntInputPassword"
                  placeholder="Ex: Insert Old Password"
                />
              )}
            </Field>
          </Col>
        )}
        <Col span={24}>
          <Field name="new_password">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Password Baru",
                  noReq: true,
                  labelAlign: "left",
                }}
                prefix={<FiLock />}
                type="AntInputPassword"
                placeholder="Ex: Ex4mpl3_p4sswd"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="confirm_password">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Ulangi Password",
                  noReq: true,
                  labelAlign: "left",
                }}
                prefix={<FiLock />}
                type="AntInputPassword"
                placeholder="Ex: Ex4mpl3_p4sswd"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mt5">
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Simpan
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
