import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selector as selectorDocumentVehicles } from "app/redux/document_vehicles";
import { selector as selectorVehicles } from "app/redux/vehicles";
import {
  get as getDocumentVehicles,
  del as deleteDocumentVehicles,
} from "app/req/document_vehicles";
import { removeEmpty } from "app/utils/functions";
import { useParams } from "react-router";

dayjs.extend(relativeTime);

const Index = (props) => {
  const pageParams = useParams<any>();
  // Change Module
  const datas = useSelector(selectorDocumentVehicles).records;
  const pagination = useSelector(selectorDocumentVehicles).pagination;
  const dataHeader = useSelector(selectorVehicles).detail;

  const [loading, setLoading] = React.useState(true);
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const delAction = async (record) => {
    const res = await deleteDocumentVehicles(record.id);
    if (res) {
      await fetchSetData();
      message.success(`Success Delete Document Vehicle ${record.name}`);
    }
  };

  const columnActions = {
    delAction,
  };
  const fetchSetData = async (params?: any) => {
    const idParent = pageParams?.id;
    setLoading(true);
    params = {
      ...params,
    };
    await Promise.all([getDocumentVehicles(idParent, params)]);
    setLoading(false);
  };

  const [initData, setInitData] = React.useState<any>(null);
  const [visibleAdd, setVisibleAdd] = React.useState(false);

  const addShowModal = async (record) => {
    const tmpInit = {
      header_meta: {
        vehicle_id: dataHeader?.id,
        name_vehicle: dataHeader?.name,
        type_vehicle: dataHeader?.plate?.type,
      },
    };
    setInitData(tmpInit);
    setVisibleAdd(true);
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = [];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    // End Filter
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort, filter });
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    const limit = 15;
    if (mounted) fetchSetData({ page: 1, limit });
    return () => (mounted = false);
    // End Safety
  }, [props.searchText]);
  React.useEffect(() => {
    let mounted = true;
    const limit = 15;
    if (mounted) {
      fetchSetData({ page: 1, limit });
    }
    return () => (mounted = false);
  }, []);

  return {
    // Function
    onChangeTable,
    setVisibleAdd,
    addShowModal,
    // State/Const
    dataHeader,
    datas,
    loading,
    paginationProps,
    initData,
    visibleAdd,
    columnActions,
  };
};
export default Index;
