import * as layout from "./form_layout";
import { Row, Spin, Button, Form, Col, Divider, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import { IValues } from "./form_proto";
import React from "react";
import _, { includes } from "lodash";
import { AiOutlineSave } from "react-icons/ai";
import { GrUndo } from "react-icons/gr";
import { camelCaseSpaces } from "app/utils/functions";
import {
  TPermission,
  objPermissions,
  stringPermissions,
} from "app/utils/permissions";
import { useSelector } from "react-redux";
import { selector as selectorPermissions } from "app/redux/permissions";
import useLogic from "./_logic";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index: React.FC<any> = (props: ITranslate) => {
  const {
    convertMenusToId,
    convertPermissionsToId,
    formLayout,
    isSubmitting,
    permissions,
    initPermissions,
    handleCheckAll,
    handleSwitch,
    handleReset,
    handleSubmit,
  } = useLogic(props);
  return (
    <Form {...formLayout}>
      <Spin spinning={isSubmitting}>
        <Row gutter={[15, 5]}>
          {permissions.map((item, i) => {
            const tmpSubject = item.subject;
            const tmpPermissions = item.permissions;
            let indexInit = _.findIndex(initPermissions, {
              subject: tmpSubject,
            });
            let tmpSubjectCamelCases = camelCaseSpaces(tmpSubject);
            tmpSubjectCamelCases = convertMenusToId(tmpSubjectCamelCases);
            return (
              <Col span={12} key={i}>
                <Row justify="space-between">
                  <p className="text-bold">{tmpSubjectCamelCases}</p>
                  <div>
                    <span className="mr5">Select All</span>
                    <Switch
                      size="small"
                      onChange={(check) => {
                        handleCheckAll(tmpSubject, check);
                      }}
                    />
                  </div>
                </Row>
                <Divider />
                {tmpPermissions.map((permItem, i2) => {
                  let isChecked = false;
                  let checkPermission: any = false;
                  if (indexInit >= 0) {
                    checkPermission = _.get(
                      initPermissions,
                      `[${indexInit}].permissions`
                    );
                  }
                  if (_.includes(checkPermission, permItem)) {
                    isChecked = true;
                  }
                  let tmpPermItem = camelCaseSpaces(permItem);
                  tmpPermItem = convertPermissionsToId(tmpPermItem);
                  return (
                    <Row justify="space-between" key={i2}>
                      <p>{tmpPermItem}</p>
                      <Switch
                        size="small"
                        checked={isChecked}
                        onChange={(check) =>
                          handleSwitch(tmpSubject, permItem, check)
                        }
                      />
                    </Row>
                  );
                })}
              </Col>
            );
          })}
        </Row>
        {!_.isEmpty(permissions) && (
          <Row justify="end" className="mt40">
            <Button
              icon={<GrUndo />}
              className="p-hor mr10"
              onClick={handleReset as any}
            >
              Reset
            </Button>
            <Button
              icon={<AiOutlineSave />}
              type="primary"
              className="p-hor"
              htmlType="submit"
              onClick={handleSubmit as any}
            >
              Simpan
            </Button>
          </Row>
        )}
      </Spin>
    </Form>
  );
};
export default withTranslation()(Index as any);
