import { Table, message, Row, Col, Card, Spin, Button } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import _ from "lodash";
import useLogic from "./_logic";
import Top from "./top";
import Filter from "./filter";
import ModalDetail from "./modal/detail";
import ModalEdit from "./modal/edit";
import DropdownParent from "./dropdown_parent";

dayjs.extend(relativeTime);

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    // Function
    setVisibleDetail,
    onChangeTable,
    // State/Const
    datas,
    loading,
    paginationProps,
    parentBilling,
    columnActions,
    visibleDetail,
    initData,
    fetchSetData,
    setVisibleEdit,
    visibleEdit,
  } = useLogic(props);
  return (
    <React.Fragment>
      <Spin spinning={loading} tip="Mohon Tunggu ...">
        <p className="text-bold mb15">Detail Tagihan</p>
        <Top initData={parentBilling} />
        <Row className="mb25" justify="space-between" align="middle">
          <Filter />
          <DropdownParent
            parentBilling={parentBilling}
            fetchSetData={fetchSetData}
          />
        </Row>
        <Table
          columns={columns(columnActions)}
          dataSource={datas}
          rowKey={(record) => record.id}
          size="middle"
          showSorterTooltip={false}
          onChange={onChangeTable}
          pagination={{
            ...paginationProps,
            hideOnSinglePage: false,
            showSizeChanger: true,
            showTotal: (total, range) => {
              return (
                <div style={{ position: "absolute", left: 0 }}>
                  Daftar mulai {range[0]}-{range[1]} dari {total} Item
                </div>
              );
            },
          }}
        />
      </Spin>
      <ModalDetail
        initData={initData}
        setVisible={setVisibleDetail}
        visible={visibleDetail}
      />
      <ModalEdit
        initData={initData}
        setVisible={setVisibleEdit}
        visible={visibleEdit}
        onSubmit={fetchSetData}
      />
    </React.Fragment>
  );
};
export default Index;
