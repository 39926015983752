import * as Yup from "yup";

export const valid = Yup.object().shape({
  show_owner: Yup.boolean(),
  owner_id: Yup.string()
    .nullable()
    .when("show_owner", {
      is: true,
      then: Yup.string().nullable().required("Is Required !"),
    }),
  brand_id: Yup.string().nullable().required("Is Required !"),
  vehicle_model_id: Yup.string().nullable().required("Is Required !"),
  plate: Yup.object({
    name: Yup.string().nullable().required("Is Required !"),
    plate_number: Yup.string().nullable().required("Is Required !"),
    type: Yup.string().nullable().required("Is Required !"),
    production_year: Yup.string().nullable().required("Is Required !"),
    fuel: Yup.string().nullable().required("Is Required !"),
  }),
  location: Yup.object({
    location_id: Yup.string().nullable().required("Is Required !"),
  }),
  plate_tax: Yup.object({
    plate_expired_month: Yup.string().nullable().required("Is Required !"),
    expired_date: Yup.string().nullable().required("Is Required !"),
  }),
});
export const validEdit = Yup.object().shape({
  show_owner: Yup.boolean(),
  owner_id: Yup.string()
    .nullable()
    .when("show_owner", {
      is: true,
      then: Yup.string().nullable().required("Is Required !"),
    }),
  brand_id: Yup.string().nullable().required("Is Required !"),
  vehicle_model_id: Yup.string().nullable().required("Is Required !"),
  plate: Yup.object({
    name: Yup.string().nullable().required("Is Required !"),
    plate_number: Yup.string().nullable().required("Is Required !"),
    type: Yup.string().nullable().required("Is Required !"),
    production_year: Yup.string().nullable().required("Is Required !"),
    fuel: Yup.string().nullable().required("Is Required !"),
  }),
  plate_tax: Yup.object({
    plate_expired_month: Yup.string().nullable().required("Is Required !"),
    expired_date: Yup.string().nullable().required("Is Required !"),
  }),
});
