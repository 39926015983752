import React from "react";
import { Button, Avatar, Image, Typography, Tag, Tooltip } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces, convertToRupiah } from "app/utils/functions";
import { FaEye } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";

dayjs.extend(relativeTime);

const columns = (actions): any => {
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp, label) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            {label}
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  const checkStatus = (dataStatus) => {
    if (dataStatus === "active") {
      dataStatus = <Tag color="geekblue">Aktif</Tag>;
    } else {
      dataStatus = <Tag>Non Aktif</Tag>;
    }
    return dataStatus;
  };
  return [
    {
      title: "Nomor SPK",
      dataIndex: "contract_number",
      render: (item, record) => {
        let tmp = item || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Harga Sewa",
      dataIndex: "price",
      render: (item, record) => {
        let tmp = item || false;
        return tmp ? convertToRupiah(tmp) : "--";
      },
    },
    {
      title: "Periode Rental",
      dataIndex: "start",
      render: (item, record) => {
        let awal = item || false;
        if (awal) awal = dayjs(record?.start).format("DD MMMM YYYY");
        let akhir = item || false;
        if (akhir) akhir = dayjs(record?.end).format("DD MMMM YYYY");

        let diffRentangTempo = dayjs(record?.end, "YYYY-MM-DD");
        const diffRentangTempoText = diffRentangTempo.diff(
          record?.start,
          "month"
        );
        return (
          <div>
            <span>{awal}</span>
            <span className="mr10 ml10">-</span>
            <span>{akhir}</span>
            <span className="ml25">({diffRentangTempoText} Bulan)</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 80,
      render: (item, record) => {
        let tmp = _.get(record, "status") || false;
        return checkStatus(tmp);
      },
    },
    {
      title: "Aksi",
      dataIndex: "status",
      width: 80,
      render: (item, record) => {
        return (
          <Button
            icon={<AiOutlineEdit />}
            onClick={() => actions.editAction(record)}
          >
            Edit
          </Button>
        );
      },
    },
  ];
};

export default columns;
