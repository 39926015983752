export interface IValues {
  id?: string;
  number?: string;
  price?: string;
  periode_from?: any;
  periode_to?: any;
  invoice_date?: any;
  expired_date?: any;
  invoice_file?: any;
  efaktur_file?: any;
}

const proto: IValues = {};
export default proto;
