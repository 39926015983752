import React from "react";
import { Menu } from "antd";
import { useHistory, Link } from "react-router-dom";
import { getAuthorize } from "app/utils/permissions";
import menus, { TParentMenuItem } from "./menus";
import s from "./index.module.scss";
import { AppstoreOutlined } from "@ant-design/icons";
import useLogic from "./_logic";

const { SubMenu } = Menu;

const Index: React.FC<any> = (props: any) => {
  const { defaultSelectedKeys, checkIsAuthMenu } = useLogic(props);
  let isEmptyTransaction = true;
  let isEmptyMaster = true;
  let isEmptySetting = true;
  const checkMenuParentShow = () => {
    menus.map((item) => {
      let { icon, label, slug, subjectCode } = item;
      const isAllowed = checkIsAuthMenu(subjectCode);
      if (isAllowed) {
        if (item.parent == "transaction") isEmptyTransaction = false;
        if (item.parent == "master") isEmptyMaster = false;
        if (item.parent == "setting") isEmptySetting = false;
      }
    });
  };

  const itemMenuComp = (parentItem: TParentMenuItem) => {
    let tmpComp = [];
    menus.map((item) => {
      let { icon, label, slug, subjectCode } = item;
      const isAllowed = checkIsAuthMenu(subjectCode);
      const MyIcon = icon;
      if (isAllowed && item.parent == parentItem) {
        tmpComp.push(
          <Menu.Item key={slug} icon={<MyIcon />}>
            <Link to={slug}>{label}</Link>
          </Menu.Item>
        );
      }
    });
    return tmpComp;
  };

  checkMenuParentShow();

  return (
    <div className={s.nav_main}>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={["transaction", "master", "setting"]}
      >
        <Menu.Item key="/" icon={<AppstoreOutlined />}>
          <Link to="/">Dashboard</Link>
        </Menu.Item>
        {!isEmptyTransaction && (
          <SubMenu key="transaction" title="Data Transaksi">
            {itemMenuComp("transaction")}
          </SubMenu>
        )}
        {!isEmptyMaster && (
          <SubMenu key="master" title="Data Master">
            {itemMenuComp("master")}
          </SubMenu>
        )}
        {!isEmptySetting && (
          <SubMenu key="setting" title="Data Pengaturan">
            {itemMenuComp("setting")}
          </SubMenu>
        )}
      </Menu>
    </div>
  );
};
export default Index;
