import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  get as getVendors,
  del as delVendors,
  patch as patchVendors,
} from "app/req/vendors";
import { selector as selectorVendors } from "app/redux/vendors";
import { useHistory } from "react-router";

dayjs.extend(relativeTime);

const Index = (props) => {
  // Change Module
  const { push } = useHistory();
  const datas = useSelector(selectorVendors).records;
  const pagination = useSelector(selectorVendors).pagination;

  const [loading, setLoading] = React.useState(true);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [initData, setInitData] = React.useState<any>(null);
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: any) => {
    setLoading(true);
    const tmpFilter = _.get(params, "filter");
    const filter = { ...tmpFilter, name: props.searchText };
    params = {
      ...params,
      filter,
    };
    await getVendors(params);
    setLoading(false);
  };
  const delAction = async (record) => {
    setInitData(null);
    const res = await delVendors(record.id);
    if (res) {
      message.success(`Success Delete Data`);
      fetchSetData();
    }
  };

  // In Bellow is Standarized
  const editAction = async (record) => {
    push(`/vendors/edit/${record.id}`);
  };
  const columnActions = {
    delAction,
    editAction,
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;

    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort });
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    const limit = 15;
    if (mounted) fetchSetData({ page: 1, limit });
    return () => (mounted = false);
    // End Safety
  }, [props.searchText]);
  React.useEffect(() => {
    let mounted = true;
    const limit = 15;
    if (mounted) fetchSetData({ page: 1, limit });
    return () => (mounted = false);
  }, []);

  return {
    // Function
    onChangeTable,
    setVisibleEdit,
    editAction,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    visibleEdit,
    initData,
  };
};
export default Index;
