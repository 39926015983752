import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import Req from "app/utils/http_client_query_builder";
import dayjs from "dayjs";
import { Tooltip } from "antd";

export default (props) => {
  const { values } = props;
  const getFileThumnail = () => {
    let filePdfTmp: any = _.get(values, "file");
    if (filePdfTmp) {
      if (typeof filePdfTmp == "object") {
        filePdfTmp = filePdfTmp?.name;
      } else if (filePdfTmp != "" || filePdfTmp != "empty") {
        filePdfTmp = filePdfTmp;
      } else {
        filePdfTmp = filePdfTmp ? filePdfTmp : "--";
      }
    }

    return {
      filePdfThumbnail: filePdfTmp,
    };
  };
  const [vendorsLoading, setVendorsLoading] = React.useState(false);
  const [vendorsLists, setVendorsLists] = React.useState<any>([]);
  // Utils AutoCopmlete
  const getParams = ({ search, id }) => {
    let myParams: any = {
      filter: { name: search },
      limit: 9999999999,
    };
    if (id) {
      myParams = {
        ...myParams,
        custom: { id },
      };
    }
    return myParams;
  };
  const getConvertLists = (datas) => {
    return convertToSelect({
      datas: datas,
      labelName: "name",
      valueName: "id",
    });
  };

  const checkerPromise = async ({
    ref_id = null,
    promise = null,
    setLoading = null,
  }) => {
    let tmp_ref_id = _.get(values, ref_id);
    let tmp = null;
    tmp = await promise({ search: "", id: null });
    return tmp;
  };

  // Vendors
  const onSearchVendors = async (search) => {
    await getVendors({ search, id: null });
  };
  const getVendors = async ({ search, id }) => {
    setVendorsLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `vendors`;
    const res = await Req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setVendorsLists(tmpLists);
    }
    setVendorsLoading(false);
  };

  const promiseVendors = async () => {
    const ref_id = "vendor_id";
    return await checkerPromise({
      ref_id: ref_id,
      promise: getVendors,
      setLoading: setVendorsLoading,
    });
  };
  React.useEffect(() => {
    promiseVendors();
  }, []);
  return {
    onSearchVendors,
    vendorsLoading,
    vendorsLists,
    filePdfThumbnail: getFileThumnail().filePdfThumbnail,
  };
};
