import {
  get as getReq,
  post as postReq,
  patch as patchReq,
  del as delReq,
  IParamsURLSimple,
  IParamsURL,
} from "app/utils/http_client_query_builder";
import {
  TPermission,
  objPermissions,
  stringPermissions,
} from "app/utils/permissions";
import { errMsg } from "./global_req";
import store from "app/redux/store";
import { setData } from "app/redux/auth/reducer";
import _ from "lodash";

export const postForgotPassword = async (data) => {
  const res = await postReq({ endpoint: "forgot_password", data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return true;
};
export const postLogin = async (data) => {
  const res = await postReq({ endpoint: "login", data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
export const postLogout = async (data) => {
  const res = await postReq({ endpoint: "logout" });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return true;
};
export const getProfile = async () => {
  const res = await getReq({ endpoint: "profile" });
  if (res.status == "error") return null;
  if (_.get(res, "status") == "success") {
    const tmpStrPermissions = _.get(res, "data.permissions") || [];
    let dataObjectPermissions = objPermissions(tmpStrPermissions);
    const resData: any = {
      record: res.data,
      login: true,
      objPermissions: dataObjectPermissions,
    };
    store.dispatch(setData(resData));
  } else {
    const resData = {
      login: false,
      record: null,
      objPermissions: null,
    };
    store.dispatch(setData(resData));
  }
  return res;
};
