import { Row, Spin } from "antd";
import Layout from "app/_components/layout/private";
import MoonLoader from "react-spinners/MoonLoader";
import React from "react";

const Index = () => {
  return (
    <Row align="middle" justify="center" style={{ minHeight: "100vh" }}>
      <Spin
        indicator={<MoonLoader size={50} color="#b72018" />}
        spinning={true}
      />
    </Row>
  );
};

export default Index;
