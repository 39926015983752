import {
  Button,
  Col,
  Form,
  Row,
  Upload,
  message,
  Typography,
  Tooltip,
} from "antd";
import React from "react";
import _ from "lodash";
import {
  AiOutlinePlusCircle,
  AiOutlineDelete,
  AiOutlineUpload,
} from "react-icons/ai";
import TextLabel from "app/_components/ant_design/private_label";
import { API_HOST } from "app/utils/consts";
import s from "./upload_file.module.scss";
import Asterisk from "app/_components/other/asterisk";

const { Text } = Typography;

interface IProps {
  label: string;
  setFile: (file: any) => any;
  initDataName: string;
  extLists: Array<string>;
  invalidMessage: string;
  isReq?: boolean;
}

const dummyRequest = (options) => {
  const { onSuccess } = options;
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const Index = (props: IProps) => {
  const beforeUpload = (file) => {
    const ext = file.name.substr(file.name.lastIndexOf(".") + 1);
    // const extLists = ["xls", "xlsx", "csv", "doc", "docx"];
    const { extLists, invalidMessage } = props;
    const isAcceptedFile = extLists.includes(ext);
    if (!isAcceptedFile) {
      message.error(invalidMessage);
    }
    const isLt2M = file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
      message.error("File must smaller than 100 MB!");
    }
    return isAcceptedFile && isLt2M;
  };
  const [fileName, setFileName] = React.useState<any>(props.initDataName);
  React.useEffect(() => {
    if (props.initDataName !== "empty") {
      setFileName(props.initDataName);
    }
  }, [props.initDataName]);
  const handleChange = (info) => {
    const { originFileObj } = info.file;
    if (originFileObj) {
      setFileName(originFileObj.name);
      props.setFile(originFileObj);
    }
  };
  const handleRemove = () => {
    props.setFile("empty");
    setFileName(false);
  };
  const uploadButton = (
    <Row justify="space-between">
      <Tooltip title="Click me to Upload File">
        <Button>
          <AiOutlineUpload /> Upload File
        </Button>
      </Tooltip>
    </Row>
  );
  const textCopylable = `${API_HOST}/storage${fileName ? fileName : ""}`;
  return (
    <Row>
      <Col span={8}>
        <TextLabel isRequired={props.isReq} label={props.label} />
      </Col>
      <Col span={16}>
        <Form.Item wrapperCol={{ span: 24 }} className={s.formUploadFullWidth}>
          <Upload
            multiple={false}
            showUploadList={false}
            style={{ width: "100%" }}
            listType="text"
            fileList={[]}
            onPreview={() => {}}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            customRequest={dummyRequest}
          >
            <div>{uploadButton}</div>
          </Upload>
          <Row className="mt5">
            <Text
              ellipsis
              style={{ width: "300px" }}
              copyable={{
                text: textCopylable,
                tooltips: "Copy Link",
              }}
            >
              File Name : {fileName ? fileName : "--"}
            </Text>
          </Row>
        </Form.Item>
      </Col>
    </Row>
  );
};
export default Index;
