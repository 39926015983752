import _ from "lodash";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

interface IPhone {
  pre_phone: string;
  post_phone: string;
}
const removeEmptyUndefined = (obj) => {
  Object.keys(obj).forEach((key) => {
    const isEmpty = obj[key] === undefined;
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (isEmpty) {
      delete obj[key];
    }
  });
  return obj;
};
const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    const isEmpty = obj[key] === undefined || obj[key] === null;
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (isEmpty) {
      delete obj[key];
    }
  });
  return obj;
};
const convertToSelect = (conf: {
  datas: Array<any>;
  valueName?: string; // Default valueName = 'id'
  labelName?: string; // Default labelName = 'value'
}): Array<any> => {
  const { datas } = conf;
  const valueName = _.get(conf, "valueName") || "id";
  const labelName = _.get(conf, "labelName") || "name";
  let tmpData: Array<{ value: any; label: any }> = [];
  let i = 0;
  if (!_.isEmpty(datas)) {
    for (i = 0; i < datas.length; i++) {
      let value, label;
      value = datas[i][valueName];
      label = datas[i][labelName];
      // delete datas[i].id;
      // delete datas[i].name;
      tmpData.push({ value, label });
    }
  }
  return tmpData;
};
const convertToFilterAndSelect = (conf: {
  datas: Array<any>;
  valueName?: string; // Default valueName = 'id'
  labelName?: string; // Default labelName = 'value'
}): Array<any> => {
  const { datas } = conf;
  const valueName = _.get(conf, "valueName") || "id";
  const labelName = _.get(conf, "labelName") || "name";
  let tmpData: Array<{ value: any; label: any; text: any }> = [];
  let i = 0;
  if (!_.isEmpty(datas)) {
    for (i = 0; i < datas.length; i++) {
      let value, label, text;
      value = datas[i][valueName];
      label = datas[i][labelName];
      text = datas[i][labelName];
      // delete datas[i].id;
      // delete datas[i].name;
      tmpData.push({ value, label, text });
    }
  }
  return tmpData;
};

const phoneJoiner = (phone: IPhone): string => {
  const pre = _.get(phone, "pre_phone") || "";
  const post = _.get(phone, "post_phone") || "";
  const hasil = `${pre} ${post}`;
  return hasil;
};
const phoneSplitter = (phone: string): IPhone => {
  let tmpArr: Array<string> = [""];
  if (typeof phone === "string") {
    tmpArr = phone.split(" ");
  }
  let pre = _.get(tmpArr, "[0]") || "";
  let post = _.get(tmpArr, "[1]") || "";
  if (post === "") {
    post = pre;
    pre = "+62";
  }
  const hasil: IPhone = {
    pre_phone: pre,
    post_phone: post,
  };
  return hasil;
};

const formatterCurrency = (value) =>
  value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const parserCurrency = (value) => value.replace(/\$\s?|(,*)/g, "");

const isMatch = (a: any, b: any) => {
  const res = JSON.stringify(a) === JSON.stringify(b);
  return res;
};

const camelCaseSpaces = (text) => {
  return (
    text
      .toLowerCase()
      // Replaces any - or _ characters with a space
      .replace(/[-_]+/g, " ")
      // Removes any non alphanumeric characters
      .replace(/[^\w\s]/g, "")
      // Uppercases the first character in each group immediately following a space
      // (delimited by spaces)
      // .replace(/ (.)/g, function ($1) { return $1.toUpperCase(); })
      // // Removes spaces
      // .replace(/ /g, '');
      // To ComelCase Without Space
      .replace(/(?:(^.)|(\s+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase();
      })
      // Camelcase To Spaces (First String always UPPERCASE)
      .replace(/([A-Z]+)/g, " $1")
      .replace(/^ /, "")
  );
};

const timeConvert = (menitNya) => {
  const num = menitNya;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return {
    hour: rhours,
    minute: rminutes,
  };
};

const generateIndex = (excluded: Array<number>): number => {
  let hasil = 0;
  let isDone = false;
  let i = 0;
  while (!isDone) {
    const isExist = excluded.includes(i);
    if (!isExist) {
      hasil = i;
      isDone = true;
    }
    i++;
  }
  return hasil;
};
// Normalize Filter
const getObjectFilter = (values) => {
  let newFilterObject = {};
  Object.keys(values).forEach((key) => {
    const value = values[key];
    if (!_.isEmpty(value) && value !== "all") {
      newFilterObject = {
        ...newFilterObject,
        [key]: value,
      };
    }
  });
  return newFilterObject;
};

function convertToRupiah(angka) {
  let rupiah = "";
  let angkarev = angka.toString().split("").reverse().join("");
  for (let i = 0; i < angkarev.length; i++)
    if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
  return (
    "Rp. " +
    rupiah
      .split("", rupiah.length - 1)
      .reverse()
      .join("")
  );
}
/**
 * Usage example:
 * alert(convertToRupiah(10000000)); -> "Rp. 10.000.000"
 */

function convertToAngka(rupiah) {
  return parseInt(rupiah.replace(/,.*|[^0-9]/g, ""), 10);
}

type TDownloadAll = {
  urls: Array<string>;
  zipFilename: string;
};
function downloadAll(paramDownload: TDownloadAll) {
  let { urls, zipFilename } = paramDownload;
  const zip = new JSZip();
  let count = 0;
  urls.forEach(async function (url) {
    const urlArr = url.split("/");
    let filename: any = urlArr[urlArr.length - 1];

    try {
      const file = await JSZipUtils.getBinaryContent(url);
      zip.file(filename, file, { binary: true });
      count++;
      if (count === urls.length) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, zipFilename);
        });
      }
    } catch (err) {}
  });
}

export {
  convertToRupiah,
  convertToAngka,
  camelCaseSpaces,
  timeConvert,
  isMatch,
  parserCurrency,
  formatterCurrency,
  phoneJoiner,
  phoneSplitter,
  generateIndex,
  getObjectFilter,
  convertToSelect,
  convertToFilterAndSelect,
  removeEmpty,
  removeEmptyUndefined,
  downloadAll,
};
