import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Checkbox } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FiTrello } from "react-icons/fi";
import AntFields from "app/_components/ant_design/ant_custom";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const history = useHistory();
  const { formLayout } = layout;
  const { handleSubmit, isSubmitting } = props;
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Field name="name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Akses Grup",
                  noReq: true,
                  labelAlign: "left",
                }}
                prefix={<FiTrello />}
                type="AntInput"
                placeholder="Ex: Insert Role Name"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mt5">
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Simpan
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
