import { IValues as IValuesLookup } from "app/pages/private/billings/_components/form/form_proto";
export interface IValues {
  id?: string;
  billing_id?: string;
  tmpBillingLookup?: IValuesLookup;
  billing_pay?: {
    pay_date?: any;
    evidence_file?: any;
  };
}

const proto: IValues = {};
export default proto;
