import { Table, message, Row, Col, Card, Spin, Button } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import _ from "lodash";
import useLogic from "./_logic";
import Top from "./top";
import Filter from "./filter";
import ModalDetail from "./modal/detail";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ModalRenew from "app/pages/private/documents/dashboard/_components/modal/renew";
import ModalEdit from "./modal/edit";

dayjs.extend(relativeTime);

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    // Function
    setVisibleDetail,
    onChangeTable,
    // State/Const
    datas,
    loading,
    paginationProps,
    parentBilling,
    columnActions,
    visibleDetail,
    initData,
    fetchSetData,
    visibleRenew,
    setVisibleRenew,
    renewAction,
    visibleEdit,
    setVisibleEdit,
  } = useLogic(props);
  return (
    <React.Fragment>
      <Spin spinning={loading} tip="Mohon Tunggu ...">
        <p className="text-bold mb15">Detail Dokumen</p>
        <Top initData={parentBilling} />
        <Row className="mb25" justify="space-between" align="middle">
          <Filter />
          <Button
            icon={<AiOutlinePlusCircle />}
            className="p-hor"
            onClick={() => setVisibleRenew(true)}
            type="default"
          >
            Perbarui Dokumen
          </Button>
        </Row>
        <Table
          columns={columns(columnActions)}
          dataSource={datas}
          rowKey={(record) => record.id}
          size="middle"
          showSorterTooltip={false}
          onChange={onChangeTable}
          pagination={{
            ...paginationProps,
            hideOnSinglePage: false,
            showSizeChanger: true,
            showTotal: (total, range) => {
              return (
                <div style={{ position: "absolute", left: 0 }}>
                  Daftar mulai {range[0]}-{range[1]} dari {total} Item
                </div>
              );
            },
          }}
          scroll={{ x: "100%" }}
        />
      </Spin>
      <ModalDetail
        initData={initData}
        setVisible={setVisibleDetail}
        visible={visibleDetail}
      />
      <ModalRenew
        initData={{
          tmpDocumentLookup: parentBilling,
          document_id: parentBilling?.id,
        }}
        setVisible={setVisibleRenew}
        visible={visibleRenew}
        onSubmit={renewAction}
      />
      <ModalEdit
        setVisible={setVisibleEdit}
        visible={visibleEdit}
        initData={initData}
        onSubmit={fetchSetData}
      />
    </React.Fragment>
  );
};
export default Index;
