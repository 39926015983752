import * as Yup from "yup";

export const valid = Yup.object().shape({
  title: Yup.string().nullable().required("Is Required !"),
  pic_telp: Yup.number()
    .min(99, "Min 3 digit")
    .max(9999999999999, "Max 13 digit")
    .typeError("Only Number Allowed !"),
  billing_pay: Yup.object({
    price: Yup.string().nullable().required("Is Required !"),
    number: Yup.string().nullable().required("Is Required !"),
    periode_from: Yup.string().nullable().required("Is Required !"),
    periode_to: Yup.string().nullable().required("Is Required !"),
    invoice_date: Yup.string().nullable().required("Is Required !"),
    expired_date: Yup.string().nullable().required("Is Required !"),
    invoice_file: Yup.mixed().required("Is Required !"),
    // efaktur_file: Yup.mixed().required("Is Required !"),
  }),
});
