export interface IValues {
  id?: string;
  isYearly?: boolean;
  isEdit?: boolean;
  header_meta?: {
    vehicle_id?: any;
    expired_date?: any;
  };
  // position?: {
  //   position_status_id?: any;
  //   position_date?: any;
  // };
  plate_expired_month?: any;
  expired_date?: any;
}

const proto: IValues = {
  isYearly: false,
  isEdit: false,
};
export default proto;
