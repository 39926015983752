export interface IValues {
  id?: string;
  vendor_id?: string;
  name?: string;
  number?: string;
  pic_name?: string;
}

const proto: IValues = {};
export default proto;
