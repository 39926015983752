import {
  AppstoreOutlined,
  MonitorOutlined,
  UserOutlined,
  CoffeeOutlined,
  HddOutlined,
  GroupOutlined,
  OneToOneOutlined,
  AuditOutlined,
} from "@ant-design/icons";
export type TParentMenuItem = "transaction" | "master" | "setting";
export type TMenu = {
  slug: string;
  label: string;
  icon: any;
  subjectCode: string;
  parent: TParentMenuItem;
};
const menusTransaction: TMenu[] = [
  {
    label: "Tagihan",
    slug: "/billings",
    icon: HddOutlined,
    subjectCode: "billings",
    parent: "transaction",
  },
  {
    label: "Kendaraan",
    slug: "/vehicles",
    icon: HddOutlined,
    subjectCode: "vehicles",
    parent: "transaction",
  },
  {
    label: "Dokumen",
    slug: "/documents",
    icon: HddOutlined,
    subjectCode: "documents",
    parent: "transaction",
  },
];
const menusSetting: TMenu[] = [
  {
    label: "Pengguna",
    slug: "/users",
    icon: AuditOutlined,
    subjectCode: "users",
    parent: "setting",
  },
  {
    label: "Akses Grup",
    slug: "/roles",
    icon: AuditOutlined,
    subjectCode: "roles",
    parent: "setting",
  },
];
const menusMaster: TMenu[] = [
  {
    label: "Vendors",
    slug: "/vendors",
    icon: OneToOneOutlined,
    subjectCode: "vendors",
    parent: "master",
  },
  {
    label: "Tipe Dokumen",
    slug: "/document_types",
    icon: OneToOneOutlined,
    subjectCode: "document_types",
    parent: "master",
  },
  {
    label: "Merek Kendaraan",
    slug: "/brands",
    icon: OneToOneOutlined,
    subjectCode: "brands",
    parent: "master",
  },
  {
    label: "Variant Kendaraan",
    slug: "/vehicle_variants",
    icon: OneToOneOutlined,
    subjectCode: "vehicle_models",
    parent: "master",
  },
  {
    label: "Model Kendaraan",
    slug: "/vehicle_models",
    icon: OneToOneOutlined,
    subjectCode: "vehicle_models",
    parent: "master",
  },
  {
    label: "Lokasi Proyek",
    slug: "/locations",
    icon: OneToOneOutlined,
    subjectCode: "locations",
    parent: "master",
  },
  // {
  //   label: "Departemen",
  //   slug: "/departments",
  //   icon: OneToOneOutlined,
  //   subjectCode: "departments",
  //   parent: "master",
  // },
];
const menus: TMenu[] = [...menusMaster, ...menusSetting, ...menusTransaction];
export default menus;
