import {
  get as getReq,
  post as postReq,
  patch as patchReq,
  del as delReq,
  IParamsURLSimple,
  IParamsURL,
} from "app/utils/http_client_query_builder";
import { errMsg } from "./global_req";
import store from "app/redux/store";
import { setData } from "app/redux/vehicle_variants";

export const get = async (tmpParams?: IParamsURLSimple) => {
  let params: IParamsURL = tmpParams;
  const res = await getReq({ endpoint: "vehicle_variants", params });
  if (res.status == "error") {
    return null;
  }
  let datas = res.data;
  const pagination = res.pagination;
  store.dispatch(setData({ records: datas, pagination }));
  return true;
};
export const post = async (data) => {
  const res = await postReq({ endpoint: `vehicle_variants`, data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return true;
};
export const patch = async (id, data) => {
  const res = await patchReq({ endpoint: `vehicle_variants/${id}`, data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return true;
};
export const del = async (id) => {
  const res = await delReq({ endpoint: `vehicle_variants/${id}` });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return true;
};
