import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^\S[a-zA-Z0-9_\-]{2,25}$/,
      "Insert in Alphanumeric or Symbol _- (Min 2 Char)",
    )
    .strict(true)
    .required("Role name Is Required !"),
});
export default validationSchema;
