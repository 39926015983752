import {
  patch as patchReq,
  post as postReq,
  del as delReq,
  get as getReq,
} from "app/utils/http_client_query_builder";
import { camelCaseSpaces } from "app/utils/functions";
import { message } from "antd";
import store from "app/redux/store";
import { setData } from "app/redux/roles/reducer";
import { errMsg } from "./global_req";

export const get = async () => {
  const res = await getReq({
    endpoint: "roles",
    params: { sort: "-updated_at" },
  });
  if (res.status == "error") {
    // errMsg(res);
    return null;
  }
  const datas = res.data;
  store.dispatch(setData(datas));
  return res;
};
export const post = async (data) => {
  const res = await postReq({ endpoint: "roles", data: data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
export const patch = async (roleName, data) => {
  const res = await patchReq({
    endpoint: `roles/${roleName}`,
    data: data,
  });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
export const del = async (nameRole) => {
  const res = await delReq({ endpoint: `roles/${nameRole}` });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
export const setDefault = async (nameRole) => {
  const res = await postReq({ endpoint: `roles/${nameRole}/default` });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
