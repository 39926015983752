import * as Yup from "yup";

export const valid = Yup.object().shape({
  location_id: Yup.string().required("Is Required !"),
  movement_date: Yup.string().nullable().required("Is Required !"),
  pic_telp: Yup.number()
    .min(99, "Min 3 digit")
    .max(9999999999999, "Max 13 digit")
    .typeError("Only Number Allowed !"),
});
