import React from "react";
import { Menu, Dropdown, Button } from "antd";
import dayjs from "dayjs";
import { CgShortcut } from "react-icons/cg";
import { RiFileExcel2Line } from "react-icons/ri";

type TProps = {
  onClickExport: any;
  onClickExportAllDatas: any;
};

const Index: React.FC<TProps> = (props: TProps) => {
  const menu = (
    <Menu>
      <Menu.Item onClick={() => props.onClickExport()}>
        <span>Export By Filter</span>
      </Menu.Item>
      <Menu.Item onClick={() => props.onClickExportAllDatas()}>
        <span>Semua List Data</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      className="mr5"
      overlay={menu}
      placement="bottomCenter"
      trigger={["click"]}
    >
      <Button icon={<RiFileExcel2Line />}>Export Ke Excel</Button>
    </Dropdown>
  );
};
export default Index;
