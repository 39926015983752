import React from "react";
import s from "./index.module.scss";

const Index: React.FC<any> = () => {
  return (
    <React.Fragment>
      <div className={s.nav_logo}>
        <a>
          <img src={process.env.PUBLIC_URL + "/images/logo_hki.png"} />
        </a>
        {/* <h1>HKI GA</h1> */}
        <span className={s.subtitle}>Manajemen GA</span>
        <span className={s.subtitle}></span>
      </div>
      <hr className={s.divider} />
    </React.Fragment>
  );
};
export default Index;
