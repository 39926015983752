import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, post } from "app/req/vehicles";
import { useHistory } from "react-router";
import _ from "lodash";

export default () => {
  const { push } = useHistory();
  // getAuthorize("vehicles", "create");
  const onSubmit = async (values) => {
    // Change to From
    let valuesFormData = new FormData();
    let valuesOri = {
      ...values,
    };
    const tmpPlate = values.plate;
    const tmpPlateTax = values.plate_tax;
    const tmpLocation = values.location;
    const tmpKir = values.kir;
    const tmpInsurance = values.insurance;
    const tmpRentVehicle = values.rent_vehicle;
    delete valuesOri.plate;
    delete valuesOri.plate_tax;
    delete valuesOri.location;
    delete valuesOri.kir;
    delete valuesOri.insurance;
    delete valuesOri.rent_vehicle;
    for (let key in values) {
      valuesFormData.append(key, valuesOri[key]);
    }
    for (let key in tmpPlate) {
      valuesFormData.append(`plate[${key}]`, tmpPlate[key]);
    }
    for (let key in tmpPlateTax) {
      valuesFormData.append(`plate_tax[${key}]`, tmpPlateTax[key]);
    }
    for (let key in tmpLocation) {
      valuesFormData.append(`location[${key}]`, tmpLocation[key]);
    }
    for (let key in tmpKir) {
      valuesFormData.append(`kir[${key}]`, tmpKir[key]);
    }
    for (let key in tmpInsurance) {
      valuesFormData.append(`insurance[${key}]`, tmpInsurance[key]);
    }
    for (let key in tmpRentVehicle) {
      valuesFormData.append(`rent_vehicle[${key}]`, tmpRentVehicle[key]);
    }
    // End Change To Form

    const res = await post(valuesFormData);
    if (res) {
      message.success("Data Has Been Added");
      push("/vehicles");
    }
  };

  return {
    onSubmit,
  };
};
