import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, post } from "app/req/vehicle_variants";
import _ from "lodash";

export default () => {
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [search, setSearch] = React.useState("");
  getAuthorize("vehicle_models", "show");
  const addAction = async (values) => {
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    const res = await post(valuesFormData);
    if (res) {
      message.success("Success Add Data");
      await get();
    }
  };

  return {
    visibleAdd,
    setVisibleAdd,
    visibleEdit,
    setVisibleEdit,
    search,
    setSearch,
    addAction,
  };
};
