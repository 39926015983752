import React from "react";
import { Layout, Dropdown, Menu, message, Col, Row } from "antd";
import s from "./index.module.scss";
import { FaHeart, FaHeartBroken, FaLanguage } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;

const menu = (onClick) => {
  const dummyLang = () => {
    message.info("Available In FULL Version !");
  };
  const idLang = () => onClick("id");
  const enLang = () => onClick("en");
  return (
    <Menu>
      <Menu.Item onClick={dummyLang}>Indonesia</Menu.Item>
      <Menu.Item onClick={dummyLang}>English</Menu.Item>
    </Menu>
  );
};

const Index: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
  };
  const DropdownLang = (
    <div className={s.lang}>
      <Dropdown overlay={() => menu(handleClick)} placement="topCenter">
        <FaLanguage />
      </Dropdown>
    </div>
  );
  return (
    <Footer className={s.footer}>
      Copyright - 2020 ©
      <span className="text-bold ml5 mr5">PT. Hutama Karya Infrastruktur.</span>
    </Footer>
  );
};
export default Index;
