import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  get as getDocumentTypes,
  del as delDocumentTypes,
  patch as patchDocumentTypes,
} from "app/req/document_types";
import { selector as selectorDocumentTypes } from "app/redux/document_types";

dayjs.extend(relativeTime);

const Index = (props) => {
  // Change Module
  const datas = useSelector(selectorDocumentTypes).records;
  const pagination = useSelector(selectorDocumentTypes).pagination;

  const [loading, setLoading] = React.useState(true);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [initData, setInitData] = React.useState<any>(null);
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetBrands = async (params?: any) => {
    setLoading(true);
    const tmpFilter = _.get(params, "filter");
    const filter = { ...tmpFilter, name: props.searchText };
    params = {
      ...params,
      filter,
    };
    await getDocumentTypes(params);
    setLoading(false);
  };
  const editAction = async (values) => {
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    const res = await patchDocumentTypes(initData.id, valuesFormData);
    if (res) {
      message.success(`Success Edit Data`);
      fetchSetBrands();
    }
  };
  const delAction = async (record) => {
    setInitData(null);
    const res = await delDocumentTypes(record.id);
    if (res) {
      message.success(`Success Delete Data`);
      fetchSetBrands();
    }
  };

  // In Bellow is Standarized
  const editShowModal = async (record) => {
    setVisibleEdit(true);
    setInitData(record);
  };
  const columnActions = {
    delAction,
    editAction: editShowModal,
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = ["kind"];
    let isFilter = false;
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) isFilter = true;
    });
    if (isFilter) filter = filters;
    // End Filter

    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetBrands({ page, limit, sort, filter });
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    const limit = 15;
    if (mounted) fetchSetBrands({ page: 1, limit });
    return () => (mounted = false);
    // End Safety
  }, [props.searchText]);
  React.useEffect(() => {
    let mounted = true;
    const limit = 15;
    if (mounted) fetchSetBrands({ page: 1, limit });
    return () => (mounted = false);
  }, []);

  return {
    // Function
    onChangeTable,
    setVisibleEdit,
    editAction,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    visibleEdit,
    initData,
  };
};
export default Index;
