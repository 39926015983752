import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { useHistory } from "react-router";
import _ from "lodash";
import { query } from "js-query-builder";
import { getHeader } from "app/utils/http_client_query_builder";
import axios from "axios";
import { API_URL } from "app/utils/consts";
import { useSelector } from "react-redux";
import { selector } from "app/redux/vehicles";
import { get, setFilter, clearFilter, setSearch } from "app/req/vehicles";
import dot from "dot-object";

export default () => {
  const filter = useSelector(selector).filter;
  const search = useSelector(selector).search;
  const [filterToggle, setFilterToggle] = React.useState(false);
  const { push } = useHistory();

  const exportExcel = async (isAllExport: boolean = false) => {
    const FileDownload = require("js-file-download");
    let paramsUrl = query();
    if (!isAllExport) {
      paramsUrl.filter({ ...filter });
      paramsUrl.param({ search });
    }
    paramsUrl = paramsUrl.sort("expired_date");
    paramsUrl = paramsUrl.build();
    paramsUrl = decodeURIComponent(paramsUrl);
    await axios({
      url: `${API_URL}/vehicles/export${paramsUrl}`,
      method: "GET",
      responseType: "blob", // Important
      headers: getHeader(),
      withCredentials: true,
    }).then((response) => {
      FileDownload(response.data, "vehiclesExport.xlsx");
    });
  };

  getAuthorize("vehicles", "show");
  const addAction = (values) => {
    push(`/vehicles/add`);
  };

  const onSearch = async (search = "") => {
    await setSearch(search);
    await get();
  };

  const onSubmitFilterAdvance = async (values) => {
    const dottedObj = dot.dot(values);
    // const properties = Object.keys(dottedObj);
    // await properties.map((item) => {
    //   console.log(item, dottedObj[item]);
    // });
    const filter = dottedObj;
    await setFilter(filter);
    await get();
  };

  const onResetFilterAdvance = async () => {
    await clearFilter();
    await get();
  };

  let isAddButton = false;
  const addPermission = getAuthorize("vehicles", "create", true, true);
  if(addPermission) isAddButton = true;

  return {
    addAction,
    exportExcel,
    filterToggle,
    setFilterToggle,
    onSubmitFilterAdvance,
    onResetFilterAdvance,
    onSearch,
    isAddButton
  };
};
