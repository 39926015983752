import * as Yup from "yup";

export const valid = Yup.object().shape({
  isYearly: Yup.boolean(),
  // position: Yup.object().shape({
  //   position_status_id: Yup.string().required("Is Required !"),
  //   position_date: Yup.string().required("Is Required !"),
  // }),
  expired_date: Yup.mixed().nullable().required("Is Required !"),
});
