export interface IValues {
  editEmail?: boolean;
  name: string;
  email: string;
  password?: string;
  gender: string;
  telp?: string;
  telp_postfix?: string;
  is_notifiable?: any;
  location_id?: any;
  photo?: any;
}

const proto: IValues = {
  editEmail: true,
  name: "",
  email: "",
  password: "",
  gender: "male",
  telp_postfix: "",
  is_notifiable: false,
  telp: "",
};
export default proto;
