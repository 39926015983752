import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";

export default (props) => {
  const { values } = props;
  const getFileThumnail = () => {
    let filePdfTmp: any = _.get(values, "pdf_file");
    if (filePdfTmp) {
      if (typeof filePdfTmp == "object") {
        filePdfTmp = filePdfTmp?.name;
      } else if (filePdfTmp != "" || filePdfTmp != "empty") {
        filePdfTmp = filePdfTmp;
      } else {
        filePdfTmp = filePdfTmp ? filePdfTmp : "--";
      }
    }
    return {
      filePdfThumbnail: filePdfTmp,
    };
  };
  return {
    getFileThumnail,
  };
};
