import React from "react";
import _ from "lodash";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import { Row, Col, Button, Divider, Spin } from "antd";
import useLogic from "./_logic";
import DetailComp from "./_components";

const Index: React.FC<any> = () => {
  const { loading } = useLogic();
  return (
    <React.Fragment>
      <Layout>
        <div className="content_wrapper">
          <Col span={24}>
            <DetailComp />
          </Col>
        </div>
      </Layout>
    </React.Fragment>
  );
};
export default Index;
