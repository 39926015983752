import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selector as selectorAuth } from "app/redux/auth";

const checkAuth = () => {
  let { login } = useSelector(selectorAuth);
  // login = true;
  return login;
};

const PrivateRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  let isAuth = checkAuth();
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuth ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
const PublicRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  let isAuth = checkAuth();
  return (
    <Route
      {...rest}
      component={(props) =>
        !isAuth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export { PrivateRoute, PublicRoute };
