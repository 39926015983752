// Import
import reducer from "./reducer";
export * from "./reducer";
import { TypeReducer as TypeReducerTmp } from "./types";
import { TypeData as TypeDataTmp } from "./types";
export type TypeReducer = TypeReducerTmp;
export type TypeData = TypeDataTmp;
import { RootState } from "app/redux/root_reducers";
// Re-Exporting
export const selector = (state: RootState) => state.vehicles.data;
export default reducer;
