import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, post } from "app/req/vendors";
import { selector as selectorVendors } from "app/redux/vendors";
import { useParams } from "react-router";
import _ from "lodash";
import { useSelector } from "react-redux";

export default () => {
  const [loading, setLoading] = React.useState(true);
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const initData = useSelector(selectorVendors).records[0];

  getAuthorize("vendors", "update");
  const { id } = useParams<any>();
  const initFunction = async () => {
    setLoading(true);
    await get({ custom: { id } });
    setLoading(false);
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    initData,
    loading,
    setVisibleAdd,
    visibleAdd,
  };
};
