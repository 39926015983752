import {
  get as getReq,
  post as postReq,
  patch as patchReq,
  del as delReq,
  IParamsURLSimple,
  IParamsURL,
} from "app/utils/http_client_query_builder";
import { errMsg } from "./global_req";
import store from "app/redux/store";
import { setData } from "app/redux/document_by_types/reducer";

export const get = async (tmpParams?: IParamsURLSimple) => {
  let params: IParamsURL = tmpParams;
  const res = await getReq({ endpoint: `documents/group_by_types`, params });
  if (res.status == "error") {
    return null;
  }
  let datas = res.data;
  store.dispatch(setData([...datas]));
  return true;
};
