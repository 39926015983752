import React from "react";
import { Button, Avatar, Image, Typography, Tag, Tooltip } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";
import { FaEye } from "react-icons/fa";

dayjs.extend(relativeTime);

type TAction = {
  detailAction?: (record: any) => any;
  renewAction?: (record: any) => any;
  editAction?: (record: any) => any;
  delAction?: (record: any) => any;
};

const columns = (actions: TAction): any => {
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp, label) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            {label}
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  return [
    {
      title: "File Dokumen",
      sorter: true,
      render: (item, record) => {
        const tmp = _.get(record, "pdf_url") || false;
        return getRenderFile(tmp, "File Dokumen");
      },
    },
    {
      title: "No. Dokumen",
      dataIndex: "number",
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "number") || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Tgl. Dikeluarkan",
      dataIndex: "effective_date",
      width: 150,
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "effective_date") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Tgl. Masa Berlaku",
      dataIndex: "expired_date",
      width: 150,
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "expired_date") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Status Notif.",
      dataIndex: "status",
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "status") || false;
        if (tmp) tmp = camelCaseSpaces(tmp);
        if (tmp === "Warning") {
          tmp = <Tag color="geekblue">Nyala</Tag>;
        } else if (tmp === "Active") {
          tmp = <Tag color="red">Mati</Tag>;
        } else {
          tmp = <Tag>Diarsipkan</Tag>;
        }
        return tmp;
      },
    },
    {
      title: "Aksi",
      align: "center" as "center",
      width: 80,
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
