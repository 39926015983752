import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";
import dayjs from "dayjs";

export default (props) => {
  const { values } = props;
  const getFileThumnail = (): {
    invoicePdfThumbnail: any;
    efakturPdfThumbnail: any;
  } => {
    let invoicePdf: any = _.get(values, "billing_pay.invoice_file");
    let efakturPdf: any = _.get(values, "billing_pay.efaktur_file");
    const checkFile = (tmpCheckFile) => {
      if (tmpCheckFile) {
        if (typeof tmpCheckFile == "object") {
          tmpCheckFile = tmpCheckFile?.name;
        } else if (tmpCheckFile != "" || tmpCheckFile != "empty") {
          tmpCheckFile = tmpCheckFile;
        } else {
          tmpCheckFile = tmpCheckFile ? tmpCheckFile : "--";
        }
      }
      return tmpCheckFile;
    };
    invoicePdf = checkFile(invoicePdf);
    efakturPdf = checkFile(efakturPdf);

    return {
      invoicePdfThumbnail: invoicePdf,
      efakturPdfThumbnail: efakturPdf,
    };
  };
  // Change RangePicker
  const onChangePeriode = async (value) => {
    const periode_from = dayjs(value[0]);
    const periode_to = dayjs(value[1]);
    await props.setFieldValue("periode_from", periode_from);
    await props.setFieldValue("periode_to", periode_to);
  };

  return {
    getFileThumnail,
    onChangePeriode,
  };
};
