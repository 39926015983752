import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import { formatterCurrency, parserCurrency } from "app/utils/functions";
import { AiOutlineSave } from "react-icons/ai";
import dayjs from "dayjs";
const { RangePicker } = Datepicker;

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, handleSubmit } = props;
  const { getFileThumnail, onChangePeriode } = useLogic(props);
  const { efakturPdfThumbnail, invoicePdfThumbnail } = getFileThumnail();
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Row className="mb25">
            <Col span={6}>
              <TextLabel isRequired label="Rentang Periode" />
            </Col>
            <Col span={18}>
              <RangePicker
                clearIcon={false}
                ranges={{
                  "Bulan Sekarang": [
                    dayjs().startOf("month"),
                    dayjs().endOf("month"),
                  ],
                }}
                value={[values?.periode_from, values?.periode_to]}
                onChange={onChangePeriode}
                style={{ width: "100%" }}
                disabled={isSubmitting}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Field name="invoice_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tgl. Invoice",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-02-02"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="expired_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Jatuh Tempo",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-02-02"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="number">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nomor Invoice",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: INV-001"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="price">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nominal",
                  labelAlign: "left",
                }}
                type="AntInputNumber"
                parser={parserCurrency}
                formatter={formatterCurrency}
                placeholder="Ex: 2500000"
              />
            )}
          </Field>
        </Col>

        <Col span={24} className="mb5">
          <Col span={24} className="mb5">
            <UploadFile
              setFile={(file) => setFieldValue("invoice_file", file)}
              initDataName={invoicePdfThumbnail}
              label="Invoice File"
              extLists={[
                "pdf",
                "jpg",
                "jpeg",
                "png",
                "ppt",
                "pptx",
                "xls",
                "xlsx",
                "doc",
                "docx",
              ]}
              invalidMessage="You can upload only Document/Image/PPt/Xls File !"
              isReq
            />
          </Col>
          <Col span={24} className="mb5">
            <UploadFile
              setFile={(file) => setFieldValue("efaktur_file", file)}
              initDataName={efakturPdfThumbnail}
              label="E-Faktur File"
              extLists={[
                "pdf",
                "jpg",
                "jpeg",
                "png",
                "ppt",
                "pptx",
                "xls",
                "xlsx",
                "doc",
                "docx",
              ]}
              invalidMessage="You can upload only Document/Image/PPt/Xls File !"
              isReq
            />
          </Col>
        </Col>
        <Col span={24}>
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
