import { adapterToEditForm } from "./adapter";
import {
  get as getVendors,
  del as delVendors,
  patch as patchVendors,
} from "app/req/vendors";
import { message } from "antd";
import { useParams } from "react-router";

export default (props) => {
  const { id } = useParams<any>();
  const onSubmit = async (values) => {
    const res = await patchVendors(values.id, values);
    if (res) {
      await await getVendors({ custom: { id } });
      message.success("Success Edit Data");
    }
  };
  const initData = adapterToEditForm(props.initData);
  return {
    onSubmit,
    initData,
  };
};
