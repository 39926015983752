import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  get as getBillings,
  del as delBillings,
  patch as patchBillings,
  setPagination as setPaginationBillings,
  setFilter as setFilterBillings,
  setCustom as setCustomBillings,
  clearFilter as clearFilterBillings,
  setSort as setSortBillings,
} from "app/req/billings";
import { get as getVendors } from "app/req/vendors";
import { post as postBillingPays } from "app/req/billing_pays";
import { selector as selectorBillings } from "app/redux/billings";
import { selector as selectorVendors } from "app/redux/vendors";
import {
  adapterToEditForm,
  adapterToRenewForm,
  adapterToSetStatusForm,
} from "../../_components/adapter";
import { convertToFilterAndSelect } from "app/utils/functions";
import { patch } from "app/req/billings";
import { removeEmpty, downloadAll } from "app/utils/functions";
import { useHistory, useLocation } from "react-router";
import { API_HOST } from "app/utils/consts";
import axios from "axios";
import fileDownload from "js-file-download";
import { getDatas as getDatasWithFilter } from "./filter/_logic";
import queryString from "query-string";

dayjs.extend(relativeTime);

type TFetchData = {
  page?: any;
  limit?: any;
  sort?: any;
  filter?: any;
};

const Index = (props) => {
  const { push } = useHistory();
  const queryUrl = useLocation<any>()?.search;
  const query: any = queryString.parse(queryUrl);
  // Change Module
  const datas = useSelector(selectorBillings).records;
  const loading = useSelector(selectorBillings).loading;
  const pagination = useSelector(selectorBillings).pagination;
  let dataVendors = useSelector(selectorVendors).records;
  const dataFilters = {
    dataVendors: convertToFilterAndSelect({
      datas: dataVendors,
      labelName: "name",
      valueName: "id",
    }),
  };

  const [initData, setInitData] = React.useState<any>(null);
  const [visibleRenew, setVisibleRenew] = React.useState(false);
  const [visibleSetStatus, setVisibleSetStatus] = React.useState(false);
  const [loadingLocal, setLoadingLocal] = React.useState(false);
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: TFetchData | any) => {
    if (!params?.filter?.status_pay) {
      params = { ...params, filter: { ...params.filter, status_pay: [] } };
    }
    if (!params?.filter?.vendor_id)
      params = { ...params, filter: { ...params.filter, vendor_id: [] } };

    await setFilterBillings(params?.filter);
    await setPaginationBillings({
      current_page: params?.page,
      per_page: params?.limit,
    });
    await setCustomBillings(params?.custom);
    await setSortBillings(params?.sort);
    await Promise.all([getBillings(), getVendors({ limit: 9999 })]);
  };
  const delAction = async (record) => {
    setInitData(null);
    const res = await delBillings(record.id);
    if (res) {
      message.success(`Success Delete Data`);
    }
  };
  const downloadAction = async (record) => {
    setInitData(null);
    await setLoadingLocal(true);
    message.info("Please Allow To Multiple Download in TopRight Alert !");
    let links = [
      // "https://api-hkiga.azhari.my.id/documents/invoice-pdf-3f727147-d35b-4d2a-8c1f-5f51dd1e31f8.pdf",
      // "https://api-hkiga.azhari.my.id/documents/efaktur-pdf-3f727147-d35b-4d2a-8c1f-5f51dd1e31f8.pdf",
      `${API_HOST}/storage${record.billing_pay_active.invoice_url}`,
      `${API_HOST}/storage${record.billing_pay_active.efaktur_url}`,
    ];
    let counter = 0;
    let promises = [];
    links.map((item) => {
      const tmpPromise = axios({
        url: `https://cors-anywhere.herokuapp.com/${item}`,
        method: "GET",
        responseType: "blob", // important
      });
      promises.push(tmpPromise);
    });
    const resPromises = await Promise.all(promises);
    resPromises.map((res, idx) => {
      let fileName = `Billings-Invoice-${record.id}`;
      if (counter == 1) fileName = `Billings-EFaktur-${record.id}`;
      let strItemUrl = links[idx].split(".");
      const extension = strItemUrl.pop();
      fileName = `${fileName}.${extension}`;
      fileDownload(res.data, fileName);
      counter++;
    });
    await setLoadingLocal(false);
  };
  const setStatusShowModal = async (record) => {
    setVisibleSetStatus(true);
    const convertedData = adapterToSetStatusForm(record);
    setInitData(convertedData);
  };
  const refetchAfterMutate = async () => {
    await getBillings()
  }

  // In Bellow is Standarized
  const detailAction = async (record) => {
    push(`/billings/detail/${record.id}`);
  };
  const renewShowModal = async (record) => {
    setVisibleRenew(true);
    const convertedData = adapterToRenewForm(record);
    setInitData(convertedData);
  };
  const editShowModal = async (record) => {
    push(`/billings/edit/${record.id}`);
  };
  const columnActions = {
    detailAction,
    renewAction: renewShowModal,
    delAction,
    editAction: editShowModal,
    downloadAction,
    setStatusAction: setStatusShowModal,
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = ["status_pay"];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    let filterVendorId = null;
    if (filters?.vendor_id) filterVendorId = filters?.vendor_id[0];
    // End Filter
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    const custom = {
      vendor_id: filterVendorId,
    };
    await fetchSetData({ page, limit, sort, filter, custom });
  };
  const initFunction = async () => {
    const limit = 15;
    await clearFilterBillings();
    if (!_.isEmpty(query)) {
      const tmpBetween = query?.between_expired_date.split(",");
      const firstDate = tmpBetween[0];
      const lastDate = tmpBetween[1];
      await setLoadingLocal(true);
      await setPaginationBillings({
        per_page: 15,
      });
      await getDatasWithFilter(firstDate, lastDate);
      await setLoadingLocal(false);
    } else {
      await fetchSetData({ page: 1, limit });
    }
  };
  React.useEffect(() => {
    initFunction();
  }, []);

  return {
    // Function
    onChangeTable,
    setVisibleRenew,
    setVisibleSetStatus,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    visibleRenew,
    visibleSetStatus,
    initData,
    dataFilters,
    loadingLocal,
    fetchSetData,
    refetchAfterMutate
  };
};
export default Index;
