import React from "react";
import { Button, Avatar, Image, Typography, Tag, Tooltip } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces, convertToRupiah } from "app/utils/functions";
import { FaEye } from "react-icons/fa";

dayjs.extend(relativeTime);

type TAction = {
  detailAction?: (record: any) => any;
  editAction?: (record: any) => any;
  delAction?: (record: any) => any;
};

const columns = (actions: TAction): any => {
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            {tmp}
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  return [
    // {
    //   title: "File Invoice",
    //   dataIndex: "invoice_url",
    //   width: 200,
    //   render: (item, record) => {
    //     const tmp = _.get(record, "invoice_url") || false;
    //     return getRenderFile(tmp);
    //   },
    // },
    {
      title: "Nomor Tagihan",
      dataIndex: "number",
      width: 200,
      render: (item, record) => {
        let tmp = _.get(record, "number") || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Nominal",
      dataIndex: "price",
      render: (item, record) => {
        let tmp = _.get(record, "price") || "--";
        return convertToRupiah(tmp);
      },
    },
    {
      title: "Jatuh Tempo",
      dataIndex: "expired_date",
      width: 200,
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "expired_date") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Status Notif.",
      dataIndex: "status",
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "status") || false;
        if (tmp) tmp = camelCaseSpaces(tmp);
        if (tmp === "Warning") {
          tmp = <Tag color="geekblue">Nyala</Tag>;
        } else if (tmp === "Active") {
          tmp = <Tag color="red">Mati</Tag>;
        } else {
          tmp = <Tag>Diarsipkan</Tag>;
        }
        return tmp;
      },
    },
    {
      title: "Status Bayar",
      dataIndex: "pay_date",
      render: (item, record) => {
        let tmp = _.get(record, "pay_date") || false;
        if (!tmp) {
          tmp = <Tag color="red">Belum Bayar</Tag>;
        } else if (tmp) {
          tmp = <Tag color="green">Lunas</Tag>;
        }
        return tmp;
      },
    },
    {
      title: "Aksi",
      align: "center" as "center",
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
