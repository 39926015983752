// Import
import reducer from "./reducer";
export * from "./reducer";
import { TypeReducer as TypeReducerTmp } from "./types";
import { TypeData as TypeDataTmp } from "./types";
import { RootState } from "app/redux/root_reducers";
export type TypeReducer = TypeReducerTmp;
export type TypeData = TypeDataTmp;
// Re-Exporting
export const selector = (state: RootState) => state.counter.data;
export default reducer;
