import * as layout from "./form_layout";
import { Button, Col, Form, Row, Checkbox, Tooltip } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FiLock, FiMail } from "react-icons/fi";
import AntFields from "app/_components/ant_design/ant_custom";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import { FiLogIn } from "react-icons/fi";
import useLogic from "./_logic";
import s from "./index.module.scss";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { handleSign } = useLogic(props);
  const { handleSubmit, isSubmitting, values, setFieldValue } = props;
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Field name="password">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Password",
                  labelAlign: "left",
                }}
                prefix={<FiLock />}
                type="AntInputPassword"
                placeholder="Insert Password"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="password_confirm">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Password Confirm",
                  labelAlign: "left",
                }}
                prefix={<FiLock />}
                type="AntInputPassword"
                placeholder="Insert Password Confirm"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Row justify="end" align="middle">
            <Button
              onClick={handleSubmit as any}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              className="p-hor"
              icon={<AiOutlineSave />}
            >
              Simpan Password
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
