import { message } from "antd";
import React from "react";
import { useHistory } from "react-router";
import { clearAll } from "app/utils/local_storage";

export default (props) => {
  const { push } = useHistory();
  const handleSignOut = () => {
    clearAll();
    push("/login");
  };
  return {
    handleSignOut,
  };
};
