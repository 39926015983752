import React from "react";
import { useHistory } from "react-router-dom";
import { getAuthorize } from "app/utils/permissions";
import menus from "./menus";

export default (props) => {
  const { location } = useHistory();
  const { pathname } = location;
  let exactPathname: any = pathname.split("/");
  exactPathname = `/${exactPathname[1]}`;
  let defaultSelectedKeys: Array<string> = [];
  defaultSelectedKeys.push(exactPathname);

  const checkIsAuthMenu = (subjectCode) => {
    let isAuthorize = true;
    menus.map((item) => {
      if (item.subjectCode == subjectCode) {
        const showPermission = getAuthorize(item.subjectCode, "show", true);
        const selfPermission = getAuthorize(item.subjectCode, "self", true);
        isAuthorize = showPermission || selfPermission;
      }
    });
    return isAuthorize;
  };

  return {
    defaultSelectedKeys,
    checkIsAuthMenu,
  };
};
