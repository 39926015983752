import React from "react";
import { Layout, Tooltip, Menu, Dropdown, Col, Row, Button } from "antd";
import LeftMenu from "./search_menu";
import RightMenu from "./right_menu";
import s from "./index.module.scss";
import { AiOutlineMenu } from "react-icons/ai";
import MobileDrawer from "./mobile_drawer";
import useLogic from "./_logic";
const { Header } = Layout;

const Index: React.FC<any> = () => {
  const { visibleDrawer, setVisibleDrawer } = useLogic();
  const DesktopTopComp = (
    <Header>
      <div />
      <RightMenu />
    </Header>
  );
  const MobileTopComp = (
    <Header>
      <MobileDrawer setVisible={setVisibleDrawer} visible={visibleDrawer} />
      <Button
        className={s.myButtonMenu}
        icon={<AiOutlineMenu />}
        onClick={() => setVisibleDrawer(true)}
      />
      <RightMenu />
    </Header>
  );
  return (
    <div className={s.content_header}>
      <Row>
        <Col xs={0} xl={24}>
          {DesktopTopComp}
        </Col>
        <Col xs={24} xl={0}>
          {MobileTopComp}
        </Col>
      </Row>
    </div>
  );
};
export default Index;
