import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import {
  get,
  post,
  setSearch,
  clearFilter,
  setFilter,
} from "app/req/billings";
import _ from "lodash";
import { query } from "js-query-builder";
import { getHeader } from "app/utils/http_client_query_builder";
import axios from "axios";
import { API_URL } from "app/utils/consts";
import { useSelector } from "react-redux";
import { selector as selectorBillings } from "app/redux/billings";
import dot from 'dot-object'

export default () => {
  const filter = useSelector(selectorBillings).filter;
  const sort = useSelector(selectorBillings).sort;
  const custom = useSelector(selectorBillings).custom;
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [filterToggle, setFilterToggle] = React.useState(false);

  const exportExcel = async () => {
    const FileDownload = require("js-file-download");
    let paramsUrl = query();
    paramsUrl.filter({ ...filter });
    if (custom)
      for (let key in custom) {
        if (custom[key]) paramsUrl.param(key, custom[key]);
      }
    if (sort) paramsUrl.sort(sort);
    paramsUrl = paramsUrl.build();
    paramsUrl = decodeURIComponent(paramsUrl);
    await axios({
      url: `${API_URL}/billings/export${paramsUrl}`,
      method: "GET",
      responseType: "blob", // Important
      headers: getHeader(),
      withCredentials: true,
    }).then((response) => {
      FileDownload(response.data, "billingsExport.xlsx");
    });
  };

  const onClickExport = () => {
    exportExcel();
  };

  const onSearch = async (search = "") => {
    await setSearch(search);
    await get();
  };

  const onSubmitFilterAdvance = async (values) => {
    const dottedObj = dot.dot(values);
    const filter = dottedObj;
    await setFilter(filter);
    await get();
  };

  const onResetFilterAdvance = async () => {
    await clearFilter();
    await get();
  };

  getAuthorize("billings", "show");
  const addAction = async (values) => {};

  let isAddButton = false;
  const addPermission = getAuthorize("billings", "create", true, true);
  if(addPermission) isAddButton = true;

  return {
    visibleAdd,
    setVisibleAdd,
    visibleEdit,
    setVisibleEdit,
    addAction,
    exportExcel,
    onClickExport,
    onSearch,
    onSubmitFilterAdvance,
    onResetFilterAdvance,
    filterToggle,
    setFilterToggle,
    isAddButton
  };
};
