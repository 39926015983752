import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { Modal } from "antd";
import useLogic from "./_logic";
import s from "./index.module.scss";
dayjs.extend(relativeTime);

type TProps = {
  initData: any;
};

const Index: React.FC<TProps> = (props: TProps) => {
  const { showData } = useLogic(props);
  return (
    <table>
      <tbody>
        {showData.map((item, idx) => {
          return (
            <tr key={idx}>
              <td className={s.label}>{item.label}</td>
              <td className={s.colon}>:</td>
              <td className={s.value}>{item.value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default Index;
