import {
  get as getReq,
  post as postReq,
  patch as patchReq,
  del as delReq,
  IParamsURLSimple,
  IParamsURL,
} from "app/utils/http_client_query_builder";
import { errMsg } from "./global_req";
import store from "app/redux/store";
import { setData } from "app/redux/kirs/reducer";

export const get = async (idVehicle, tmpParams?: IParamsURLSimple) => {
  let params: IParamsURL = tmpParams;
  const res = await getReq({
    endpoint: `kirs/${idVehicle}`,
    params,
  });
  if (res.status == "error") {
    return null;
  }
  let datas = res.data;
  const pagination = res.pagination;
  store.dispatch(setData({ records: datas, pagination }));
  return true;
};
export const post = async (idVehicle, data) => {
  const res = await postReq({
    endpoint: `kirs/${idVehicle}`,
    data,
  });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return true;
};
export const patch = async (id, data) => {
  const res = await patchReq({
    endpoint: `kirs/${id}`,
    data,
  });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return true;
};
export const del = async (id) => {
  const res = await delReq({
    endpoint: `kirs/${id}`,
  });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return true;
};
