const kindList = [
  { value: "car", label: "Mobil", text: "Mobil" },
  { value: "truck", label: "Truk", text: "Truk" },
  { value: "bus", label: "Bis", text: "Bis" },
  { value: "motorcycle", label: "Motor", text: "Motor" },
  { value: "bike", label: "Sepeda", text: "Sepeda" },
  // { value: "plane", label: "Pesawat", text: "Pesawat" },
  // { value: "train", label: "Kereta", text: "Kereta" },
  // { value: "boat", label: "Perahu", text: "Perahu" },
  // { value: "ship", label: "Kapal", text: "Kapal" },
  { value: "other", label: "Lainya", text: "Lainya" },
];
export { kindList };
