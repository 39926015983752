import React, { useRef } from "react";
import _ from "lodash";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import { Row, Col, Button, Divider } from "antd";
import Table from "./_components/table";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Input } from "antd";
import useLogic from "./_logic";
import Filter from "./_components/filter";
import FilterAdditional from "./_components/filter_additional/form";
import FilterIndicator from "./_components/filter_indicator";
import DropdownExport from "./_components/dropdown_export";

const Index: React.FC<any> = () => {
  const {
    addAction,
    exportExcel,
    filterToggle,
    setFilterToggle,
    onSubmitFilterAdvance,
    onResetFilterAdvance,
    onSearch,
    isAddButton,
  } = useLogic();
  return (
    <React.Fragment>
      <Layout>
        <div className="content_wrapper">
          <Row
            align="middle"
            justify="space-between"
            className="mb10"
            gutter={[15, 15]}
          >
            <Col>
              <Filter />
            </Col>
            <Col>
              <DropdownExport
                onClickExport={exportExcel}
                onClickExportAllDatas={() => exportExcel(true)}
              />
              {isAddButton && (
                <Button
                  icon={<AiOutlinePlusCircle />}
                  className="p-hor"
                  onClick={addAction}
                  type="primary"
                >
                  Tambah Data
                </Button>
              )}
            </Col>
          </Row>
          <Row
            align="middle"
            justify="space-between"
            className="mb10"
            gutter={[15, 15]}
          >
            <Col span={24}>
              <FilterIndicator
                onFilterToggle={setFilterToggle}
                filterToggle={filterToggle}
                onSearch={onSearch}
              />
            </Col>
            {filterToggle && (
              <Col span={24}>
                <Divider className="mb15" />
                <FilterAdditional
                  onSubmit={onSubmitFilterAdvance}
                  onReset={onResetFilterAdvance}
                />
              </Col>
            )}
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Table />
            </Col>
          </Row>
        </div>
      </Layout>
    </React.Fragment>
  );
};
export default Index;
