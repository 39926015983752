import React from "react";
import { Typography, Row, Col, Button } from "antd";
import { ArrowLeftOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { AiOutlineLeftCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import useLogic from "./_logic";
import s from "./index.module.scss";

function Page(props) {
  const { push } = useHistory();
  const { handleHome } = useLogic();
  return (
    <div className={s.background}>
      <Row align="middle"></Row>
      <Row align="middle" justify="center">
        <div className={s.wrapper}>
          <p className={s.title}>404 Not Found</p>
          <p className={s.subtitle}>Halaman yang anda cari tidak ditemukan</p>
          <p className={s.text}>Butuh Bantuan atau Kembali ke home</p>
          <Button
            icon={<AiOutlineLeftCircle />}
            size="large"
            className={s.button}
            onClick={handleHome}
          >
            Kembali Ke Home
          </Button>
          <span className="pointer ml15 text-lg" onClick={handleHome}>
            Bantuan
          </span>
        </div>
      </Row>
    </div>
  );
}
export default Page;
