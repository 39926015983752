import React from "react";
import { getProfile } from "app/req/auth";

export default () => {
  const [loading, setLoading] = React.useState(true);
  const initial = async () => {
    setLoading(true);
    await getProfile();
    setLoading(false);
  };
  return { loading, initial };
};
