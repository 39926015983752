import React from "react";
import { Button, Avatar, Image, Typography, Tag } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";

const { Text } = Typography;

dayjs.extend(relativeTime);

type TAction = {
  editAction: (record: any) => any;
  delAction: (record: any) => any;
};

const columns = (actions: TAction): any => {
  return [
    {
      title: "Nama Lokasi",
      dataIndex: "name",
      width: 225,
      sorter: true,
      render: (item, record) => {
        return item && item;
      },
    },
    // {
    //   title: "Deskripsi",
    //   dataIndex: "description",
    //   render: (item) => {
    //     return item && item;
    //   },
    // },
    {
      title: "Dibuat pada",
      dataIndex: "created_at",
      width: 125,
      render: (item, record) => {
        if (item) return dayjs(item).format("DD-MMM-YYYY") ?? "--";
        return "--";
      },
    },
    {
      title: "Dibuat oleh",
      render: (item, record) => {
        return (
          <div>
            <p>{record?.creator?.name ?? "--"}</p>
            <p>{record?.creator?.email}</p>
          </div>
        );
      },
    },
    {
      title: "Diperbarui pada",
      dataIndex: "updated_at",
      width: 125,
      render: (item, record) => {
        if (item) return dayjs(item).format("DD-MMM-YYYY") ?? "--";
        return "--";
      },
    },
    {
      title: "Diperbarui oleh",
      render: (item, record) => {
        return (
          <div>
            <p>{record?.updater?.name ?? "--"}</p>
            <p>{record?.updater?.email}</p>
          </div>
        );
      },
    },
    {
      title: "Aksi",
      width: 100,
      align: "center",
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
