import React from "react";
import { Button, Avatar, Image, Typography, Tag, Space, Input } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { camelCaseSpaces } from "app/utils/functions";

const { Text } = Typography;

dayjs.extend(relativeTime);

const columns = (): any => {
  const checkStatus = (dataStatus) => {
    if (dataStatus === "active" || dataStatus === "warning") {
      dataStatus = <Tag color="geekblue">Aktif</Tag>;
    } else {
      dataStatus = <Tag>Tidak Aktif</Tag>;
    }
    return dataStatus;
  };
  return [
    {
      title: "Tanggal Posisi STNK",
      dataIndex: "position_date",
      render: (item, record) => {
        let tmp = _.get(record, "position_date") || false;
        if (tmp) {
          tmp = dayjs(tmp).format("DD MMMM YYYY");
        } else {
          tmp = "--";
        }
        return tmp;
      },
    },
    {
      title: "Status Posisi STNK",
      dataIndex: "position_status.name",
      render: (item, record) => {
        const tmp = _.get(record, "position_status.name") || "--";
        return tmp;
      },
    },
    {
      title: "Status Data",
      dataIndex: "status",
      render: (item, record) => {
        return checkStatus(item);
      },
    },
  ];
};

export default columns;
