import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { Modal } from "antd";
import Form from "./form";
import { IValues } from "./form/form_proto";

dayjs.extend(relativeTime);

interface IProps {
  setVisible: any;
  visible: boolean;
  onSubmit: (values: IValues) => Promise<any>;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible } = props;
  const onSubmit = async (values: IValues) => {
    await props.onSubmit(values);
    setVisible(false);
  };
  return (
    <Modal
      title="Form Modal - Tambah Merek"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 80 }}
    >
      <Form onSubmit={onSubmit} />
    </Modal>
  );
};
export default Index;
