import React from "react";
import { Table } from "antd";
import useLogic from "./_logic";
import columns from "./columns";

interface IProps {
  taxPositionId: any;
  refresh: any;
}

const Index = (props: IProps) => {
  const { datas, loading } = useLogic(props);
  return (
    <Table
      columns={columns()}
      loading={loading}
      dataSource={datas}
      rowKey={(record) => record.id}
      size="small"
      showSorterTooltip={false}
      pagination={{
        hideOnSinglePage: false,
        showSizeChanger: true,
        pageSize: 6,
        showTotal: (total, range) => {
          return (
            <div style={{ position: "absolute", left: 0 }}>
              Daftar mulai {range[0]}-{range[1]} dari {total} Item
            </div>
          );
        },
      }}
    />
  );
};
export default Index;
