import React from "react";
import { useSelector } from "react-redux";
import { convertToSelect } from "app/utils/functions";
import { selector as selectorVehicleVariants } from "app/redux/vehicle_variants";

export default (props) => {
  const dataVehicleVariants = useSelector(selectorVehicleVariants).records;
  const dataVehicleOptions = convertToSelect({
    datas: dataVehicleVariants,
    labelName: "name",
    valueName: "id",
  });
  return {
    dataVehicleOptions,
  };
};
