import React from "react";
import _ from "lodash";
import { Button } from "antd";
import DropdownMenu from "./dropdown_popup";
import { AiOutlineDown } from "react-icons/ai";
import ModalRenew from "app/pages/private/billings/_components/modal/renew";
import ModalSetStatus from "app/pages/private/billings/_components/modal/set_payed";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

const Index: any = (props: any) => {
  const { push } = useHistory();
  const [renew, setRenew] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const { parentBilling, fetchSetData } = props;
  const billing_id = parentBilling.id;
  let pay_date = parentBilling?.billing_pay_active?.pay_date;
  if (pay_date) pay_date = dayjs(pay_date);
  const evidence_file = parentBilling?.billing_pay_active?.evidence_url;
  const actions = {
    renewAction: () => {
      setRenew(true);
    },
    setStatusAction: () => {
      setStatus(true);
    },
    editAction: () => {
      push(`/billings/edit/${billing_id}`);
    },
  };
  return (
    <React.Fragment>
      <DropdownMenu actions={actions}>
        <Button icon={<AiOutlineDown />} className="pl15 pr15 mr25">
          Aksi Parent
        </Button>
      </DropdownMenu>
      <ModalRenew
        visible={renew}
        setVisible={setRenew}
        initData={{ tmpBillingLookup: parentBilling, billing_id }}
        onSubmit={fetchSetData}
      />
      <ModalSetStatus
        visible={status}
        setVisible={setStatus}
        initData={{
          tmpBillingLookup: parentBilling,
          billing_id,
          billing_pay: { pay_date, evidence_file },
        }}
        onSubmit={fetchSetData}
      />
    </React.Fragment>
  );
};
export default Index;
