import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selector as selectorBillingPay } from "app/redux/billing_pays";
import {
  get as getBillingPay,
  clearFilter as clearFilterBillingPays,
  setFilter as setFilterBillingPays,
  setPagination as setPaginationBillingPays,
  setSort as setSortBillingPays,
} from "app/req/billing_pays";
import Req from "app/utils/http_client_query_builder";
import { useParams } from "react-router";

dayjs.extend(relativeTime);

const Index = (props) => {
  const pageParams = useParams<any>();
  // Change Module
  const [parentBilling, setParentBilling] = React.useState({});
  const datas = useSelector(selectorBillingPay).records;
  const pagination = useSelector(selectorBillingPay).pagination;
  const loading = useSelector(selectorBillingPay).loading;

  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: any) => {
    const idParent = pageParams?.id;
    await setFilterBillingPays(params?.filter);
    await setPaginationBillingPays({
      current_page: params?.page,
      per_page: params?.limit,
    });
    await setSortBillingPays(params?.sort);

    await Promise.all([
      getBillingPay(idParent),
      Req.get({ endpoint: `billings/${idParent}` }),
    ]).then((res) => {
      const resData = res[1]?.data ?? {};
      setParentBilling(resData);
    });
  };

  const [visibleDetail, setVisibleDetail] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [initData, setInitData] = React.useState(null);
  const detailAction = (record) => {
    setVisibleDetail(true);
    setInitData(record);
  };
  const editAction = (record) => {
    setVisibleEdit(true);
    setInitData(record);
  };
  const columnActions = {
    detailAction,
    editAction,
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = [];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    // End Filter
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort, filter });
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    const limit = 15;
    if (mounted) fetchSetData({ page: 1, limit });
    return () => (mounted = false);
    // End Safety
  }, [props.searchText]);
  React.useEffect(() => {
    let mounted = true;
    const limit = 15;
    if (mounted) {
      fetchSetData({ page: 1, limit });
    }
    return () => (mounted = false);
  }, []);

  return {
    // Function
    setVisibleDetail,
    onChangeTable,
    // State/Const
    datas,
    loading,
    paginationProps,
    parentBilling,
    columnActions,
    visibleDetail,
    initData,
    fetchSetData,
    setVisibleEdit,
    visibleEdit,
  };
};
export default Index;
