import { message } from "antd";
import React from "react";
import { useHistory } from "react-router";

export default (props) => {
  const { push } = useHistory();
  const handleSign = () => {
    push("/login");
  };
  return {
    handleSign,
  };
};
