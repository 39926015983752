import React from "react";
import Layout from "app/_components/layout/private";
import { useParams } from "react-router-dom";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useHistory } from "react-router-dom";
import ModalAssignRole from "./_components/assign_role";
import ModalEditPass from "./_components/change_pass";
import ModalEdit from "app/pages/private/users/dashboard/_components/modal_users/edit_users";
import useLogic from "./_logic";
import TopComp from "./_components/top";
import BottomComp from "./_components/bottom";
import { Spin } from "antd";

dayjs.extend(relativeTime);

const Index: React.FC<any> = (props) => {
  const { id } = useParams<any>();
  const { push } = useHistory();
  const {
    // Function
    initial,
    editAction,
    assignRoleAction,
    changePassAction,
    // State
    isLoading,
    visibleEdit,
    visibleAssign,
    visiblePass,
    setVisibleEdit,
    setVisibleAssign,
    setVisiblePass,
    // Non Rendering
    isCurrent,
    dataCurrentUser,
    dataUsersPermissions,
    dataPermissions,
    detailProps,
  } = useLogic({ id, push });
  React.useEffect(() => {
    initial();
  }, []);
  const { roles, ...rest } = detailProps;
  const topCompProps = {
    ...rest,
    setVisibleAssign,
    setVisibleEdit,
    setVisiblePass,
  };
  const bottomCompProps = {
    dataPermissions,
    dataUsersPermissions,
  };
  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={isLoading}>
          <div className="content_wrapper">
            <TopComp {...topCompProps} />
            <BottomComp {...bottomCompProps} />
          </div>
        </Spin>
      </Layout>
      <ModalEdit
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        onSubmit={editAction}
        initData={dataCurrentUser as any}
      />
      <ModalAssignRole
        onSubmit={assignRoleAction}
        setVisible={setVisibleAssign}
        visible={visibleAssign}
        initData={roles as any}
      />
      <ModalEditPass
        onSubmit={changePassAction}
        setVisible={setVisiblePass}
        visible={visiblePass}
        isCurrent={isCurrent}
      />
    </React.Fragment>
  );
};
export default Index;
