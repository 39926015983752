import React from "react";
import { get, setFilter } from "app/req/documents";
import { useParams } from "react-router";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { selector as selectorDocument } from "app/redux/documents";

type TRangePicker = [Dayjs, Dayjs];

export const getDatas = async (firstDate = null, lastDate = null) => {
  const betweenExpiredDate = `${firstDate},${lastDate}`;
  let params = null;
  if (firstDate != null && lastDate != null) {
    params = {
      filter: {
        between_expired_date: betweenExpiredDate,
      },
    };
  }
  await setFilter(params.filter);
  await get(params);
};

export default (props) => {
  const filter = useSelector(selectorDocument).filter;
  const between_expired_date = filter?.between_expired_date;
  let between_expired_date_arr: any = [null, null];
  if (between_expired_date) {
    const tmpBetween = between_expired_date.split(",");
    between_expired_date_arr = [dayjs(tmpBetween[0]), dayjs(tmpBetween[1])];
  }
  const onClear = async () => {
    const filter = {
      between_expired_date: "",
    };
    await setFilter(filter);
    await get();
  };
  const onChangeFilter = (value: TRangePicker) => {
    let tmpValue: any = `${value[0]},${value[1]}`;
    tmpValue = {
      between_expired_date: tmpValue,
    };
    setFilter(tmpValue);
    let firstDate = _.get(value, "[0]") || null;
    let lastDate = _.get(value, "[1]") || null;
    if (firstDate) firstDate = value[0].format("YYYY-MM-DD");
    if (lastDate) lastDate = value[1].format("YYYY-MM-DD");
    getDatas(firstDate, lastDate);
  };
  const onChangeDate = (firstDate = null, lastDate = null) => {
    getDatas(firstDate, lastDate);
  };
  return {
    between_expired_date_arr,
    onChangeDate,
    onClear,
    onChangeFilter,
  };
};
