import * as layout from "./form_layout";
import { Button, Col, Form, Row, Checkbox, Tooltip } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { FiLock, FiMail } from "react-icons/fi";
import { IoIosLogIn } from "react-icons/io";
import AntFields from "app/_components/ant_design/ant_custom";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import { FaGooglePlusSquare } from "react-icons/fa";
import useLogic from "./_logic";
import s from "./index.module.scss";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const history = useHistory();
  const { formLayout } = layout;
  const { handleGooglePlus } = useLogic(props);
  const { handleSubmit, isSubmitting, values, setFieldValue } = props;
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Field name="email">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Email",
                  noReq: true,
                  labelAlign: "left",
                }}
                prefix={<FiMail />}
                type="AntInput"
                placeholder="Insert Email"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="password">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Password",
                  noReq: true,
                  labelAlign: "left",
                }}
                prefix={<FiLock />}
                type="AntInputPassword"
                placeholder="Insert Password"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mb20">
          <Row align="middle" justify="space-between">
            <Col span={12}>
              <Row>
                <Checkbox
                  disabled={isSubmitting}
                  checked={values.remember}
                  onChange={(tmp) =>
                    setFieldValue("remember", tmp.target.checked)
                  }
                >
                  Ingatkan Saya
                </Checkbox>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Link to="/forgot_password">Lupa Password ?</Link>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row align="middle">
            <Col span={24}>
              <Row justify="end" align="middle">
                <Button
                  onClick={handleSubmit as any}
                  loading={isSubmitting}
                  block
                  type="primary"
                  htmlType="submit"
                  icon={<IoIosLogIn />}
                >
                  Masuk
                </Button>
              </Row>
            </Col>
            {/* <Col span={6}>
              <Row
                align="middle"
                justify="space-between"
                className={s.iconGooglePlus}
              >
                <p className="ml15 text-muted">Atau</p>
                <Tooltip title="Masuk Dengan Google+">
                  <FaGooglePlusSquare onClick={handleGooglePlus} />
                </Tooltip>
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
