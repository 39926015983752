import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import useLogic from "./_logic";
import Top from "./top";
import TableExpiredDate from "./expired_date";
import TableMovement from "./movement";
import TableDocument from "./document";
import TableInsurance from "./insurance";
import TableKir from "./kir";
import TableRentVehicle from "./rent_vehicle";
import { Button, Row, Tabs } from "antd";
import { AiOutlineEdit, AiOutlineFilePdf } from "react-icons/ai";

const { TabPane } = Tabs;

dayjs.extend(relativeTime);

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const { downloadPdf, loadingPrint, redirectToEdit } = useLogic(props);
  return (
    <React.Fragment>
      <Row justify="space-between">
        <p className="text-bold mb15">Detail Kendaraan</p>
        <div>
          <Button
            className="p-hor mr5"
            icon={<AiOutlineEdit />}
            onClick={redirectToEdit as any}
          >
            Ubah Data
          </Button>
          <Button
            type="primary"
            className="p-hor"
            icon={<AiOutlineFilePdf />}
            onClick={downloadPdf as any}
            loading={loadingPrint}
          >
            Cetak PDF
          </Button>
        </div>
      </Row>
      <Top />
      <Tabs defaultActiveKey="expired_date">
        <TabPane tab="Histori Perpanjangan STNK" key="expired_date">
          <TableExpiredDate />
        </TabPane>
        <TabPane tab="Histori Rental" key="rent_vehicle">
          <TableRentVehicle />
        </TabPane>
        <TabPane tab="Histori Asuransi" key="insurance">
          <TableInsurance />
        </TabPane>
        <TabPane tab="Histori Perpanjangan KIR" key="kir">
          <TableKir />
        </TabPane>
        <TabPane tab="Perpindahan Lokasi" key="movement">
          <TableMovement />
        </TabPane>
        <TabPane tab="Dokumen Kendaraan" key="document">
          <TableDocument />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};
export default Index;
