import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import Req from "app/utils/http_client_query_builder";
import {
  get as getVendors,
  del as delVendors,
  patch as patchVendors,
} from "app/req/vendors";
import { selector as selectorVendors } from "app/redux/vendors";
import { useHistory, useParams } from "react-router";

dayjs.extend(relativeTime);

const Index = (props) => {
  // Change Module
  const { id } = useParams<any>();
  const { push } = useHistory();
  let dataVendors = useSelector(selectorVendors).records;
  _.find(dataVendors, { id });
  let datas: Array<any> = _.get(dataVendors, "[0].vendor_banks") || [];
  datas = _.orderBy(datas, ["id"], ["desc"]);
  const pagination = useSelector(selectorVendors).pagination;

  const [loading, setLoading] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [initData, setInitData] = React.useState<any>(null);
  const total = datas.length || 1;
  const pageSize = 4;

  const fetchSetData = async (params?: any) => {
    setLoading(true);
    params = {
      ...params,
      custom: { id },
    };
    await getVendors(params);
    setLoading(false);
  };
  const addAction = async (values) => {
    const res = await Req.post({
      endpoint: `vendor_banks/${id}`,
      data: values,
    });
    if (res.status == "success") {
      message.success(`Success Add Data`);
      fetchSetData();
    }
  };
  const delAction = async (record) => {
    setInitData(null);
    const res = await Req.del({ endpoint: `vendor_banks/${record.id}` });
    if (res.status == "success") {
      message.success(`Success Delete Data`);
      fetchSetData();
    }
  };
  const editAction = async (values) => {
    const res = await Req.patch({
      endpoint: `vendor_banks/${values.id}`,
      data: values,
    });
    if (res.status == "success") {
      message.success(`Success Edit Data`);
      fetchSetData();
    }
  };

  const editShowModal = (record) => {
    setInitData(record);
    setVisibleEdit(true);
  };
  const columnActions = {
    delAction,
    editAction: editShowModal,
  };
  const paginationProps: PaginationProps = {
    defaultCurrent: 1,
    pageSize,
    total,
  };

  return {
    // Function
    setVisibleEdit,
    editAction,
    setVisibleAdd,
    addAction,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    visibleEdit,
    visibleAdd,
    initData,
  };
};
export default Index;
