import { IRoutes } from "./types";
import Login from "app/pages/public/login";
import ForgotPassword from "app/pages/public/forgot_password";
import UpdatePassword from "app/pages/public/update_password";
import Locked from "app/pages/public/locked";

const routes: IRoutes[] = [
  { path: "/login", component: Login },
  { path: "/forgot_password", component: ForgotPassword },
  { path: "/update_password", component: UpdatePassword },
  { path: "/locked", component: Locked },
];
export default routes;
