import { IValues as IValuesDocumentLookup } from "app/pages/private/documents/dashboard/_components/modal/form/form_proto";
export interface IValues {
  id?: string;
  tmpDocumentLookup?: IValuesDocumentLookup;
  document_id?: string;
  effective_date?: string;
  expired_date?: string;
  pdf_file?: any;
}

const proto: IValues = {};
export default proto;
