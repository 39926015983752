import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Dropdown, Menu, Popconfirm } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import s from "./index.module.scss";
import _ from "lodash";
import {
  FaDownload,
  FaEdit,
  FaEye,
  FaRegEdit,
  FaRegFilePdf,
} from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";

dayjs.extend(relativeTime);

interface IProps {
  key?: any;
  children?: React.ReactElement;
  actions: {
    renewAction: any;
    setStatusAction: any;
    editAction: any;
  };
}

const Index: React.FC<IProps> = (props: IProps) => {
  const [visible, setVisible] = React.useState(false);
  const { editAction, renewAction, setStatusAction } = props.actions;
  const handleMenuClick = ({ key }) => {
    if (key == "3") {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="0"
        className={s.contextMenu}
        onClick={() => {
          setVisible(false);
          editAction();
        }}
      >
        <FaEdit /> Ubah Parent
      </Menu.Item>
      <Menu.Item
        key="1"
        className={s.contextMenu}
        onClick={() => {
          setVisible(false);
          renewAction();
        }}
      >
        <FaRegFilePdf /> Perbarui Tagihan
      </Menu.Item>
      <Menu.Item
        key="4"
        className={s.contextMenu}
        onClick={() => {
          setVisible(false);
          setStatusAction();
        }}
      >
        <RiSecurePaymentLine /> Set Sudah Bayar
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={(e) => setVisible(e)}
    >
      {props.children}
    </Dropdown>
  );
};
export default Index;
