import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { message, Modal } from "antd";
import Form from "./form";
import { IValues } from "./form/form_proto";
import Req from "app/utils/http_client_query_builder";
import { get as getVehicles } from "app/req/vehicles";
import { get as getDocumemtVehicles } from "app/req/document_vehicles";

dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible, initData } = props;

  const onSubmit = async (values: IValues) => {
    const vehicleId = values?.header_meta?.vehicle_id;
    delete values.id;
    delete values.header_meta;
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    const resPost = await Req.post({
      endpoint: `document_vehicles/${vehicleId}`,
      data: valuesFormData,
    });
    if (resPost.status == "success") {
      await Promise.all([getDocumemtVehicles(vehicleId)]);
      message.success("Success Add Document Vehicle");
    }
    setVisible(false);
  };
  return (
    <Modal
      title="Form Modal - Tambah Dokumen Kendaraan"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 80 }}
    >
      <Form onSubmit={onSubmit} initData={initData} />
    </Modal>
  );
};
export default Index;
