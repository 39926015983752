import React from "react";
import { Row, Col, Typography, Space, Divider, Tag, Tooltip } from "antd";
import { camelCaseSpaces } from "app/utils/functions";
import styles from "./index.module.scss";
import { AiOutlineCar } from "react-icons/ai";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
const { Text } = Typography;

type TProps = {
  id: string;
  name: string;
  plateNumber: string;
  expiredDate: string;
  location: string;
  owner: string;
};

const Index: React.FC<TProps> = (props: TProps) => {
  const { expiredDate, name, plateNumber, location, owner } = props;
  let remainingDays = dayjs(expiredDate).fromNow();
  return (
    <>
      <Row justify="space-between">
        <Col xs={16} xl={16}>
          <Row>
            <AiOutlineCar className={styles.iconVacancy} />
            <div>
              <div>
                <Tooltip title={`${name} - (${plateNumber})`}>
                  <Text ellipsis className={styles.textElipsis}>
                    <Link
                      to={`/vehicles/detail/${props.id}`}
                      className="text-xs text-bold"
                    >
                      {name}
                    </Link>
                  </Text>
                </Tooltip>
              </div>
              <div>
                <Tooltip title={`${location}`}>
                  <Text ellipsis className={styles.textElipsis}>
                    {location}
                  </Text>
                </Tooltip>
              </div>
            </div>
          </Row>
        </Col>
        <Col xs={8} xl={8}>
          <Row justify="end">
            <p className="text-xs text-muted">{remainingDays}</p>
          </Row>
          <Row justify="end" className="mt3">
            <p className="text-xs text-muted">
              {owner ? "Menyewa" : "Dimiliki"}
            </p>
          </Row>
        </Col>
      </Row>
      <Divider className="mb10" />
    </>
  );
};

export default React.memo(Index);
