import React from "react";
import { useSelector } from "react-redux";
import { selector as selectorVehicles } from "app/redux/vehicles";
import { selector as selectorDocuments } from "app/redux/documents";
import { selector as selectorBillings } from "app/redux/billings";
import { selector as selectorCountMasters } from "app/redux/count_masters";

export default () => {
  const {
    total_billings,
    total_documents,
    total_vehicles,
    total_vendors,
  } = useSelector(selectorCountMasters);
  return {
    totalBillings: total_billings,
    totalDocuments: total_documents,
    totalVehicles: total_vehicles,
    totalVendors: total_vendors,
  };
};
