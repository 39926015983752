import React from "react";
import { useHistory } from "react-router-dom";
export default () => {
  const { push } = useHistory();
  const handleHome = () => {
    push(`/`);
  };
  return {
    handleHome,
  };
};
