import { Table, message, Button, Row } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import _ from "lodash";
import useLogic from "./_logic";
import { FaPlusCircle, FaSave } from "react-icons/fa";
import ModalAdd from "./modal/add";
import ModalEdit from "./modal/edit";

dayjs.extend(relativeTime);

interface IProps {
  setVisibleAdd: any;
  visibleAdd: boolean;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    // Function
    setVisibleEdit,
    editAction,
    // setVisibleAdd,
    addAction,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    // visibleAdd,
    visibleEdit,
    initData,
  } = useLogic(props);
  const { setVisibleAdd, visibleAdd } = props;
  return (
    <React.Fragment>
      <Table
        columns={columns(columnActions)}
        dataSource={datas}
        rowKey={(record) => record.id}
        size="middle"
        showSorterTooltip={false}
        loading={loading}
        pagination={{
          ...paginationProps,
          hideOnSinglePage: false,
          showSizeChanger: false,
          showTotal: (total, range) => {
            return (
              <div style={{ position: "absolute", left: 0 }}>
                Daftar mulai {range[0]}-{range[1]} dari {total} Item
              </div>
            );
          },
        }}
        scroll={{ x: "100%" }}
      />
      <ModalAdd
        onSubmit={addAction}
        setVisible={setVisibleAdd}
        visible={visibleAdd}
      />
      <ModalEdit
        onSubmit={editAction}
        setVisible={setVisibleEdit}
        visible={visibleEdit}
        initData={initData}
      />
    </React.Fragment>
  );
};
export default Index;
