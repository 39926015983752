import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selector as selectorVehicles } from "app/redux/vehicles";
import { selector as selectorInsurances } from "app/redux/insurances";
import { get as getInsurances } from "app/req/insurances";
import { removeEmpty } from "app/utils/functions";
import { useParams } from "react-router";

dayjs.extend(relativeTime);

const Index = (props) => {
  const pageParams = useParams<any>();
  const idParent = pageParams?.id;

  const [initData, setInitData] = React.useState<any>({
    vehicle_id: idParent,
  });
  const [visibleForm, setVisibleForm] = React.useState(false);
  // Change Module
  const datas = useSelector(selectorInsurances).records;
  const pagination = useSelector(selectorInsurances).pagination;
  const dataHeader = useSelector(selectorVehicles).detail;

  const [loading, setLoading] = React.useState(true);
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: any) => {
    setLoading(true);
    params = {
      ...params,
    };
    await Promise.all([getInsurances(idParent, params)]);
    setLoading(false);
  };
  const addAction = () => {
    setInitData({
      vehicle_id: idParent,
    });
    setVisibleForm(true);
  };
  const editAction = (record) => {
    setInitData({
      ...record,
      file: record.file_url,
      isEdit: true,
      vehicle_id: idParent,
    });
    setVisibleForm(true);
  };
  const columnActions = {
    editAction,
  };
  const showFormModal = async (record) => {
    const tmpInit = {
      ...record,
      vehicle_id: idParent,
    };
    setInitData(tmpInit);
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = [];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    // End Filter
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort, filter });
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    const limit = 15;
    if (mounted) fetchSetData({ page: 1, limit });
    return () => (mounted = false);
    // End Safety
  }, [props.searchText]);

  return {
    columnActions,
    onChangeTable,
    showFormModal,
    dataHeader,
    datas,
    loading,
    paginationProps,
    initData,
    setVisibleForm,
    visibleForm,
    addAction,
  };
};
export default Index;
