import { IValues as IValuesDocumentLookup } from "app/pages/private/billings/_components/form/form_proto";
export interface IValues {
  id?: string;
  billing_id?: string;
  tmpBillingLookup?: IValuesDocumentLookup;
  document_id?: string;
  number?: string;
  price?: string;
  periode_from?: any;
  periode_to?: any;
  invoice_date?: any;
  expired_date?: any;
  invoice_file?: any;
  efaktur_file?: any;
  payer?: {
    name?: string;
    telp?: string;
    address?: string;
  };
}

const proto: IValues = {};
export default proto;
