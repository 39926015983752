import * as Yup from "yup";

export const validAdd = Yup.object().shape({
  name: Yup.string().nullable().required("Is Required !"),
  password: Yup.string().nullable().required("Is Required !"),
  telp_postfix: Yup.number()
    .min(99, "Min 3 digit")
    .max(9999999999999, "Max 13 digit")
    .required("Is Required !")
    .typeError("Only Number Allowed !"),
  email: Yup.string().nullable().email().strict(true).required("Is Required !"),
});
export const validEdit = Yup.object().shape({
  name: Yup.string().nullable().required("Is Required !"),
  is_notifiable: Yup.string().nullable().required("Is Required !"),
  telp_postfix: Yup.number()
    .min(99, "Min 3 digit")
    .max(9999999999999, "Max 13 digit")
    .required("Is Required !")
    .typeError("Only Number Allowed !"),
  // email: Yup.string().nullable().email().strict(true).required("Is Required !"),
});
