import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";

export default (props) => {
  const getFileThumnail = () => {
    let fileThumbnail: any = _.get(props, "values.file");
    if (fileThumbnail) {
      if (typeof fileThumbnail == "object") {
        fileThumbnail = fileThumbnail?.name;
      } else if (fileThumbnail != "" || fileThumbnail != "empty") {
        fileThumbnail = fileThumbnail;
      } else {
        fileThumbnail = fileThumbnail ? fileThumbnail : "--";
      }
    }
    return {
      fileThumbnail,
    };
  };
  return {
    fileThumbnail: getFileThumnail().fileThumbnail,
  };
};
