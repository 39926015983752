import { combineReducers } from "@reduxjs/toolkit";
import counterReducer, { TypeReducer as TypeCounter } from "app/redux/counter";
import authReducer, { TypeReducer as TypeAuth } from "app/redux/auth";
import permissionsReducer, {
  TypeReducer as TypePermission,
} from "app/redux/permissions";
import rolesReducer, { TypeReducer as TypeRole } from "app/redux/roles";
import usersReducer, { TypeReducer as TypeUser } from "app/redux/users";
import brandsReducer, { TypeReducer as TypeBrand } from "app/redux/brands";
import vehicleModelsReducer, {
  TypeReducer as TypeVehicleModel,
} from "app/redux/vehicle_models";
import vehicleVariantReducer, {
  TypeReducer as TypeVehicleVariant,
} from "app/redux/vehicle_variants";
import vehiclesReducer, {
  TypeReducer as TypeVehicle,
} from "app/redux/vehicles";
import departmentsReducer, {
  TypeReducer as TypeDepartment,
} from "app/redux/departments";
import documentTypesReducer, {
  TypeReducer as TypeDocumentType,
} from "app/redux/document_types";
import documentsReducer, {
  TypeReducer as TypeDocument,
} from "app/redux/documents";
import documentFilesReducer, {
  TypeReducer as TypeDocumentFile,
} from "app/redux/document_files";
import vendorsReducer, { TypeReducer as TypeVendor } from "app/redux/vendors";
import billingsReducer, {
  TypeReducer as TypeBilling,
} from "app/redux/billings";
import billingPaysReducer, {
  TypeReducer as TypeBillingPay,
} from "app/redux/billing_pays";
import countMastersReducer, {
  TypeReducer as TypeCountMaster,
} from "app/redux/count_masters";
import documentByTypesReducer, {
  TypeReducer as TypeDocumentByType,
} from "app/redux/document_by_types";
import locationReducer, {
  TypeReducer as TypeLocation,
} from "app/redux/locations";
import taxVehicleReducer, {
  TypeReducer as TypeTaxVehicle,
} from "app/redux/tax_vehicles";
import movementVehicleReducer, {
  TypeReducer as TypeMovementVehicle,
} from "app/redux/movement_vehicles";
import documentVehicleReducer, {
  TypeReducer as TypeDocumentVehicle,
} from "app/redux/document_vehicles";
import wheelReducer, { TypeReducer as TypeWheel } from "app/redux/wheels";
import insuranceReducer, {
  TypeReducer as TypeInsurance,
} from "app/redux/insurances";
import kirReducer, { TypeReducer as TypeKir } from "app/redux/kirs";
import rentVehicleReducer, {
  TypeReducer as TypeRentVehicle,
} from "app/redux/rent_vehicles";

interface IRootState {
  counter: TypeCounter;
  auth: TypeAuth;
  permissions: TypePermission;
  roles: TypeRole;
  users: TypeUser;
  brands: TypeBrand;
  vehicleModels: TypeVehicleModel;
  vehicleVariants: TypeVehicleVariant;
  vehicles: TypeVehicle;
  departments: TypeDepartment;
  documentTypes: TypeDocumentType;
  documents: TypeDocument;
  documentFiles: TypeDocumentFile;
  vendors: TypeVendor;
  billings: TypeBilling;
  billingPays: TypeBillingPay;
  countMasters: TypeCountMaster;
  documentByTypes: TypeDocumentByType;
  locations: TypeLocation;
  taxVehicles: TypeTaxVehicle;
  movementVehicles: TypeMovementVehicle;
  documentVehicles: TypeDocumentVehicle;
  wheels: TypeWheel;
  insurances: TypeInsurance;
  kirs: TypeKir;
  rentVehicles: TypeRentVehicle;
}

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  permissions: permissionsReducer,
  roles: rolesReducer,
  users: usersReducer,
  brands: brandsReducer,
  vehicleModels: vehicleModelsReducer,
  vehicleVariants: vehicleVariantReducer,
  vehicles: vehiclesReducer,
  departments: departmentsReducer,
  documentTypes: documentTypesReducer,
  documents: documentsReducer,
  documentFiles: documentFilesReducer,
  vendors: vendorsReducer,
  billings: billingsReducer,
  billingPays: billingPaysReducer,
  countMasters: countMastersReducer,
  documentByTypes: documentByTypesReducer,
  locations: locationReducer,
  taxVehicles: taxVehicleReducer,
  movementVehicles: movementVehicleReducer,
  documentVehicles: documentVehicleReducer,
  wheels: wheelReducer,
  insurances: insuranceReducer,
  kirs: kirReducer,
  rentVehicles: rentVehicleReducer,
});

export type RootState = IRootState;

export default rootReducer;
