import {
  get as getReq,
  post as postReq,
  IParamsURL,
} from "app/utils/http_client_query_builder";
import { errMsg } from "./global_req";
import { objPermissions } from "app/utils/permissions";
import store from "app/redux/store";
import { setData } from "app/redux/permissions/reducer";
import _ from "lodash";

export const get = async () => {
  const params: IParamsURL = {};
  const res = await getReq({ endpoint: "permissions", params });
  if (_.get(res, "status") == "success") {
    const dataStr = res.data;
    let dataObj = objPermissions(dataStr);
    store.dispatch(setData({ dataObject: dataObj, dataString: dataStr }));
  } else {
    store.dispatch(setData({ dataObject: null, dataString: [] }));
  }
  return res;
};
