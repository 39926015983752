import React from "react";
import _ from "lodash";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import { Row, Col, Button, Divider } from "antd";
import Table from "./_components/table";
import ModalAdd from "./_components/modal_users/add_users";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Input } from "antd";
import useLogic from "./_logic";
import { getAuthorize } from "app/utils/permissions";
const { Search } = Input;

const Index: React.FC<any> = () => {
  const {
    addAction,
    search,
    setSearch,
    setVisibleAdd,
    visibleAdd,
  } = useLogic();

  return (
    <React.Fragment>
      <Layout>
        <div className="content_wrapper">
          <Row align="middle" justify="space-between" className="mb10">
            <div className={s.search}>
              <Search
                placeholder="Search Name"
                onSearch={(text) => setSearch(text)}
              />
            </div>
            <Button
              icon={<AiOutlinePlusCircle />}
              className="p-hor"
              onClick={() => setVisibleAdd(true)}
              type="primary"
            >
              Tambah Pengguna
            </Button>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Table searchText={search} />
            </Col>
          </Row>
        </div>
      </Layout>
      <ModalAdd
        visible={visibleAdd}
        setVisible={setVisibleAdd}
        onSubmit={addAction}
      />
    </React.Fragment>
  );
};
export default Index;
