import React from "react";
import { Button, Avatar, Image, Typography, Tag, Tooltip, Row } from "antd";
import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";
import { Link } from "react-router-dom";
import s from "./index.module.scss";

const { Text } = Typography;

dayjs.extend(relativeTime);

type TAction = {
  detailAction: (record: any) => any;
  renewAction: (record: any) => any;
  editAction: (record: any) => any;
  delAction: (record: any) => any;
};

const columns = (actions: TAction, dataFilters: any): any => {
  const { dataDocumentTypes, dataDepartments } = dataFilters;
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            Document File
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  return [
    {
      title: "Nama Dokumen",
      dataIndex: "title",
      sorter: true,
      render: (item, record) => {
        if (item) {
          return (
            <Tooltip title={item} placement="topLeft" mouseEnterDelay={0.6}>
              <Text ellipsis className={s.textElipsis}>
                <Link to={`/documents/detail/${record.id}`}>{item}</Link>
              </Text>
            </Tooltip>
          );
        } else {
          return "--";
        }
      },
    },
    {
      title: "Nomor Dokumen",
      dataIndex: "number",
      render: (item, record) => {
        const tmp = _.get(record, "document_file_active.number") || "--";
        return (
          <Tooltip title={tmp} placement="topLeft" mouseEnterDelay={0.6}>
            <Text ellipsis className={s.textElipsis}>
              {tmp}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Lembaga",
      dataIndex: "institution",
      // sorter: true,
      render: (item, record) => {
        const tmp = _.get(record, "institution") || "--";
        return (
          <Tooltip title={tmp} placement="topLeft" mouseEnterDelay={0.6}>
            <Text ellipsis className={s.textElipsis}>
              {tmp}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Tipe Dokumen",
      dataIndex: "document_type_id",
      // filters: [...dataDocumentTypes],

      render: (item, record) => {
        const tmp = _.get(record, "document_type.name") || "--";
        return (
          <Tooltip title={tmp} placement="topLeft" mouseEnterDelay={0.6}>
            <Text ellipsis className={s.textElipsis}>
              {tmp}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: "File Dokumen",
      width: 200,
      render: (item, record) => {
        const tmp = _.get(record, "document_file_active.pdf_url") || false;
        return getRenderFile(tmp);
      },
    },
    {
      title: "Tgl. Masa Berlaku",
      dataIndex: "expired_date",
      width: 200,
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "document_file_active.expired_date") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Aksi",
      render: (item, record) => {
        return (
          <Row justify="start">
            <DropdownMenu actions={actions} record={record}>
              <Button size="small" className="pl15 pr15">
                Aksi
              </Button>
            </DropdownMenu>
          </Row>
        );
      },
    },
  ];
};

export default columns;
