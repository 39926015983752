import {
  get as getReq,
  post as postReq,
  patch as patchReq,
  del as delReq,
  IParamsURLSimple,
  IParamsURL,
} from "app/utils/http_client_query_builder";
import { errMsg } from "./global_req";
import store from "app/redux/store";
import { setData } from "app/redux/users/reducer";
import { objPermissions } from "app/utils/permissions";

export const changePass = async (id, data) => {
  const res = await postReq({ endpoint: `users/change_pass/${id}`, data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
export const postRoles = async (id, data) => {
  const res = await postReq({ endpoint: `users/roles/${id}`, data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
export const getDetail = async (id: string) => {
  const res = await getReq({ endpoint: `users/${id}` });
  if (res.status == "error") {
    return null;
  }
  let records = [];
  let tmpData = res.data;
  let permissionsObj: any = objPermissions(tmpData.permissions);
  tmpData = {
    ...tmpData,
    permissionsObj,
  };
  records.push(tmpData);
  store.dispatch(setData({ records, pagination: null }));
  return res;
};
export const get = async (tmpParams?: IParamsURLSimple) => {
  let params: IParamsURL = tmpParams;
  const res = await getReq({ endpoint: "users", params });
  if (res.status == "error") {
    return null;
  }
  let datas = res.data;
  const pagination = res.pagination;
  store.dispatch(setData({ records: datas, pagination }));
  return res;
};
export const post = async (data) => {
  const res = await postReq({ endpoint: `users`, data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
export const patch = async (id, data) => {
  const res = await patchReq({ endpoint: `users/${id}`, data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
export const del = async (id) => {
  const res = await delReq({ endpoint: `users/${id}` });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  return res;
};
