import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeReducer, TypeData } from "./types";
import _ from "lodash";

const initialState: TypeReducer = {
  data: {
    records: [],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 15,
      total: 0,
    },
    filter: null,
    custom: null,
    sort: null,
    search: null,
    loading: false,
  },
};

const billingsSlice = createSlice({
  name: "billingsSlice",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.data.records = payload ?? initialState.data.records;
    },
    setPagination: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const tmpPagination = state.data.pagination;
      const { current_page, per_page, last_page, total } = payload;
      state.data.pagination.current_page =
        current_page ?? tmpPagination.current_page;
      state.data.pagination.per_page = per_page ?? tmpPagination.per_page;
      state.data.pagination.last_page = last_page ?? tmpPagination.last_page;
      state.data.pagination.total = total ?? tmpPagination.total;
    },
    setFilter: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.data.filter = payload ?? initialState.data.filter;
    },
    setSort: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.data.sort = payload ?? state.data.sort;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.data.search = payload ?? state.data.search;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.data.loading = payload ?? initialState.data.loading;
    },
    setCustom: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.data.custom = payload ?? initialState.data.custom;
    },
  },
});
export const {
  setData,
  setPagination,
  setFilter,
  setSort,
  setLoading,
  setCustom,
  setSearch
} = billingsSlice.actions;
export default billingsSlice.reducer;
