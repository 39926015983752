import React from "react";
import { Button, Avatar, Image, Typography, Tag, Tooltip } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";
import { FaEye } from "react-icons/fa";

dayjs.extend(relativeTime);

type TAction = {
  positionDetailAction: (record: any) => any;
  positionRenewAction: (record: any) => any;
  editAction: (record: any) => any;
};

const columns = (actions: TAction): any => {
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp, label) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            {label}
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  const checkStatus = (dataStatus) => {
    if (dataStatus === "warning") {
      dataStatus = <Tag color="geekblue">Nyala</Tag>;
    } else if (dataStatus === "active") {
      dataStatus = <Tag color="red">Mati</Tag>;
    } else {
      dataStatus = <Tag>Diarsipkan</Tag>;
    }
    return dataStatus;
  };
  return [
    {
      title: "Tgl. Masa Berlaku",
      dataIndex: "expired_date",
      width: 200,
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "expired_date") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Plat 5 Tahunan",
      dataIndex: "plate_expired_month",
      width: 200,
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "plate_expired_month") || false;
        if (tmp) tmp = dayjs(tmp).format("MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    // {
    //   title: "Posisi Plat Aktif",
    //   render: (item, record) => {
    //     let tmp =
    //       _.get(record, "plate_position_active.position_status.name") || false;
    //     return tmp ? tmp : "--";
    //   },
    // },
    // {
    //   title: "Tanggal Posisi ",
    //   render: (item, record) => {
    //     let tmp = _.get(record, "plate_position_active.position_date") || false;
    //     if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
    //     return tmp ? tmp : "--";
    //   },
    // },
    {
      title: "Dokumen Pendukung",
      render: (item, record) => {
        let tmp = _.get(record, "file_url") || false;
        return tmp ? getRenderFile(tmp, "File Dokumen") : "--";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 50,
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "status") || false;
        return checkStatus(tmp);
      },
    },
    {
      title: "Aksi",
      width: 100,
      align: "center",
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
