import { Table, message, Row, Col, Card, Spin, Button, Alert } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import DatePicker from "app/_components/ant_design/picker_dayjs/datepicker";
import _ from "lodash";
import useLogic from "./_logic";
import Filter from "./filter";
import ModalAdd from "app/pages/private/vehicles/dashboard/_components/document";
import { AiOutlinePlusCircle } from "react-icons/ai";

dayjs.extend(relativeTime);

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    // Function
    onChangeTable,
    setVisibleAdd,
    addShowModal,
    // State/Const
    dataHeader,
    datas,
    loading,
    paginationProps,
    initData,
    visibleAdd,
    columnActions,
  } = useLogic(props);
  return (
    <React.Fragment>
      <Spin spinning={loading} tip="Mohon Tunggu ...">
        <Row justify="space-between">
          <Alert
            type="info"
            message={
              <div>
                <p>
                  Data diurutkan berdasarkan data
                  <span className="ml5 mr5 text-bold">Terbaru</span>
                  yang di-input kan.
                </p>
              </div>
            }
            showIcon
            className="mb15"
          />
          <Button
            icon={<AiOutlinePlusCircle />}
            className="p-hor"
            onClick={() => addShowModal(true)}
            type="default"
          >
            Tambahkan Dokumen
          </Button>
        </Row>
        <Table
          columns={columns(columnActions)}
          dataSource={datas}
          rowKey={(record) => record.id}
          size="middle"
          showSorterTooltip={false}
          onChange={onChangeTable}
          pagination={{
            ...paginationProps,
            hideOnSinglePage: false,
            showSizeChanger: true,
            showTotal: (total, range) => {
              return (
                <div style={{ position: "absolute", left: 0 }}>
                  Daftar mulai {range[0]}-{range[1]} dari {total} Item
                </div>
              );
            },
          }}
          scroll={{ x: "100%" }}
        />
      </Spin>
      <ModalAdd
        setVisible={setVisibleAdd}
        visible={visibleAdd}
        initData={initData}
      />
    </React.Fragment>
  );
};
export default Index;
