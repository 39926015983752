import {
  get as getReq,
  post as postReq,
  patch as patchReq,
  del as delReq,
  IParamsURLSimple,
  IParamsURL,
} from "app/utils/http_client_query_builder";
import { errMsg } from "./global_req";
import store from "app/redux/store";
import {
  setData,
  setPagination as setPaginationRedux,
  setFilter as setFilterRedux,
  setSort as setSortRedux,
  setLoading,
} from "app/redux/billing_pays/reducer";

type TPagination = {
  current_page?: any;
  per_page?: any;
};

export const clearFilter = async () => {
  await store.dispatch(setFilterRedux(null));
};
export const setPagination = async (tmpParams?: TPagination) => {
  let oldParams = store.getState().billingPays.data.pagination;
  await store.dispatch(
    setPaginationRedux({
      ...oldParams,
      ...tmpParams,
    })
  );
};
export const setFilter = async (tmpParams?: any) => {
  let oldParams = store.getState().billingPays.data.filter;
  await store.dispatch(
    setFilterRedux({
      ...oldParams,
      ...tmpParams,
    })
  );
};
export const setSort = async (tmpParams?: string) => {
  let oldParams = store.getState().billingPays.data.sort;
  await store.dispatch(setSortRedux(tmpParams ?? oldParams));
};
export const get = async (billingId?: any, tmpParams?: IParamsURLSimple) => {
  const pagination = await store.getState().billingPays.data?.pagination;
  const { current_page, per_page } = pagination;
  const filter = await store.getState().billingPays.data?.filter;
  const sort = await store.getState().billingPays.data?.sort;
  let params: IParamsURL = {
    ...tmpParams,
    page: current_page,
    limit: per_page,
    filter,
    sort,
  };
  await store.dispatch(setLoading(true));
  const res = await getReq({ endpoint: `billing_pays/${billingId}`, params });
  if (res.status == "error") {
    return null;
  }
  let resDatas = res.data;
  const resPagination = res.pagination;
  await store.dispatch(setData(resDatas));
  await store.dispatch(setPaginationRedux(resPagination));
  await store.dispatch(setLoading(false));
  return true;
};

export const post = async (billingId, data) => {
  const res = await postReq({ endpoint: `billing_pays/${billingId}`, data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  await get(billingId);
  return true;
};
export const patch = async (id, data) => {
  const res = await patchReq({ endpoint: `billing_pays/${id}`, data });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  const parentId = res.data?.data?.billing?.id;
  await get(parentId);
  return true;
};
export const del = async (id) => {
  const res = await delReq({ endpoint: `billing_pays/${id}` });
  if (res.status == "error") {
    errMsg(res);
    return null;
  }
  const parentId = res.data?.data?.billing?.id;
  await get(parentId);
  return true;
};
