import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { Modal } from "antd";
import Form from "./form";
import { IValues } from "./form/form_proto";

dayjs.extend(relativeTime);

interface IProps {
  setVisible: any;
  visible: boolean;
  onSubmit: (values: IValues) => Promise<any>;
  initData?: IValues;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible } = props;
  const [initData, setInitData] = React.useState<any>([]);
  const onSubmit = async (values: IValues) => {
    await props.onSubmit(values);
    setVisible(false);
  };
  React.useEffect(() => {
    setInitData(props.initData);
  }, [props.initData]);
  return (
    <Modal
      title="Form Modal - Sisipkan Akses Grup"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
    >
      <Form onSubmit={onSubmit} initData={{ roles: initData }} />
    </Modal>
  );
};
export default Index;
