import * as Yup from "yup";

const validIsCurrent = Yup.object().shape({
  new_password: Yup.string().required("Field Is Required !"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Field Is Required !"),
});
const validOther = Yup.object().shape({
  old_password: Yup.string().required("Field Is Required !"),
  new_password: Yup.string().required("Field Is Required !"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Field Is Required !"),
});
export { validIsCurrent, validOther };
