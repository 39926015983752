export interface IValues {
  id?: string;
  isEdit?: boolean;
  vehicle_id?: string;
  contract_number?: string;
  price?: string;
  start?: any;
  end?: any;
}

const proto: IValues = {
  isEdit: false,
};
export default proto;
