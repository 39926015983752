import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import _ from "lodash";
import useLogic from "./_logic";

dayjs.extend(relativeTime);

interface IProps {
  searchText: string;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    // Function
    onChangeTable,
    setVisibleEdit,
    editAction,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    visibleEdit,
    initData,
  } = useLogic(props);
  return (
    <React.Fragment>
      <Table
        columns={columns(columnActions)}
        dataSource={datas}
        rowKey={(record) => record.id}
        size="middle"
        showSorterTooltip={false}
        onChange={onChangeTable}
        loading={loading}
        pagination={{
          ...paginationProps,
          hideOnSinglePage: false,
          showSizeChanger: true,
          showTotal: (total, range) => {
            return (
              <div style={{ position: "absolute", left: 0 }}>
                Daftar mulai {range[0]}-{range[1]} dari {total} Item
              </div>
            );
          },
        }}
        scroll={{ x: "100%" }}
      />
    </React.Fragment>
  );
};
export default Index;
