import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selector as selectorDocumentFiles } from "app/redux/document_files";
import {
  post as postDocumentFiles,
  get as getDocumentFiles,
  setFilter as setFilterDocumentPays,
  clearFilter as clearFilterDocumentPays,
  setSort as setSortDocumentPays,
  setPagination as setPaginationDocumentPays,
} from "app/req/document_files";
import { removeEmpty } from "app/utils/functions";
import { useParams } from "react-router";
import Req from "app/utils/http_client_query_builder";

dayjs.extend(relativeTime);

const Index = (props) => {
  const pageParams = useParams<any>();
  // Change Module
  const [parentBilling, setParentBilling] = React.useState<any>({});
  const datas = useSelector(selectorDocumentFiles).records;
  const pagination = useSelector(selectorDocumentFiles).pagination;
  const loading = useSelector(selectorDocumentFiles).loading;
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: any) => {
    const idParent = pageParams?.id;
    await setFilterDocumentPays(params?.filter);
    await setPaginationDocumentPays({
      current_page: params?.page,
      per_page: params?.limit,
    });
    await setSortDocumentPays(params?.sort);

    await Promise.all([
      getDocumentFiles(idParent),
      Req.get({ endpoint: `documents/${idParent}` }),
    ]).then((res) => {
      const resData = res[1]?.data ?? {};
      setParentBilling(resData);
    });
  };

  const [visibleDetail, setVisibleDetail] = React.useState(false);
  const [visibleRenew, setVisibleRenew] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [initData, setInitData] = React.useState(null);
  const renewAction = async (values) => {
    values = removeEmpty(values);
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    const res = await postDocumentFiles(values.document_id, valuesFormData);
    if (res) {
      message.success(`Success Edit Data`);
      fetchSetData();
    }
  };
  const detailAction = (record) => {
    setVisibleDetail(true);
    setInitData(record);
  };
  const editShow = (record) => {
    setVisibleEdit(true);
    setInitData(record);
  };
  const columnActions = {
    editAction: editShow,
    detailAction,
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = [];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    // End Filter
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort, filter });
  };
  const initFunction = () => {
    clearFilterDocumentPays();
  };
  React.useEffect(() => {
    const limit = 15;
    initFunction();
    fetchSetData({ page: 1, limit });
  }, []);

  return {
    // Function
    setVisibleDetail,
    onChangeTable,
    // State/Const
    datas,
    loading,
    paginationProps,
    parentBilling,
    columnActions,
    visibleDetail,
    initData,
    fetchSetData,
    visibleRenew,
    setVisibleRenew,
    renewAction,
    visibleEdit,
    setVisibleEdit,
  };
};
export default Index;
