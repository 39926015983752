import { Typography, message, Row, Col, Card, Spin } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";

const { Text } = Typography;

dayjs.extend(relativeTime);

interface IProps {
  initData: any;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { initData } = props;
  const { created_at, updated_at, billing_pay_active } = initData;
  const checkCreatedAt = created_at != "--" && created_at != "";
  const createdAt = checkCreatedAt
    ? `${dayjs(created_at).format("DD MMMM YYYY HH:mm")} WIB`
    : "--";
  const checkUpdatedAt = updated_at != "--" && updated_at != "";
  const updatedAt = checkUpdatedAt
    ? `${dayjs(created_at).format("DD MMMM YYYY HH:mm")} WIB`
    : "--";
  const periodeFrom = billing_pay_active?.periode_from
    ? `${dayjs(billing_pay_active.periode_from).format("DD MMMM YYYY")}`
    : "--";
  const periodeTo = billing_pay_active?.periode_to
    ? `${dayjs(billing_pay_active?.periode_to).format("DD MMMM YYYY")}`
    : "--";
  let rekening: any = "--";
  if (initData?.vendor_bank?.number) {
    const tmpRek = `${initData?.vendor_bank?.number} (${initData?.vendor_bank?.name}) - ${initData?.vendor_bank?.pic_name}`;
    rekening = <Text copyable={{ text: tmpRek }}>{tmpRek}</Text>;
  }
  let telpVendor: any = "--";
  if (initData?.vendor?.telp) {
    telpVendor = <Text copyable>{initData?.vendor?.telp}</Text>;
  }
  let telpPic: any = "--";
  if (initData?.pic_telp) {
    telpPic = <Text copyable>{initData?.pic_telp}</Text>;
  }
  let namePic: any = "--";
  if (initData?.pic_name) {
    namePic = <span>{initData?.pic_name}</span>;
  }
  let nameVendor: any = "--";
  if (initData?.vendor?.name) {
    nameVendor = <span>{initData?.vendor?.name}</span>;
  }
  let periode: any = "--";
  if (billing_pay_active?.periode_from && billing_pay_active.periode_to) {
    periode = <span>{periodeFrom} - {periodeTo}</span>;
  }
  return (
    <Card className="mb25">
      <Row>
        <Col xs={24} xl={12}>
          <div>
            <span className="text-bold">Nama Tagihan : </span>
            <span>{initData?.title}</span>
          </div>
          <div>
            <span className="text-bold">Periode : </span>
            <span>{periode}</span>
          </div>
          <div>
            <span className="text-bold">Vendor : </span>
            <span>{nameVendor}</span>
          </div>
          <div>
            <span className="text-bold">Rekening : </span>
            <span>{rekening}</span>
          </div>
          <div>
            <span className="text-bold">Dibuat oleh : </span>
            <span className="mr10">{initData?.creator?.name ?? "--"}</span>
            <span>{initData?.creator?.email}</span>
          </div>
          <div>
            <span className="text-bold">Dibuat pada : </span>
            <span>{createdAt}</span>
          </div>
        </Col>
        <Col xs={24} xl={12}>
          <div>
            <span className="text-bold">Nama PIC : </span>
            <span>{namePic}</span>
          </div>
          <div>
            <span className="text-bold">Telp. PIC : </span>
            <span>{telpPic}</span>
          </div>
          <div>
            <span className="text-bold">Telp. Vendor: </span>
            {telpVendor}
          </div>
          <div>
            <span className="text-bold">Terakhir diperbarui oleh : </span>
            <span className="mr10">{initData?.updater?.name ?? "--"}</span>
            <span>{initData?.updater?.email}</span>
          </div>
          <div>
            <span className="text-bold">Terakhir diperbarui pada : </span>
            <span>{updatedAt}</span>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
export default Index;
