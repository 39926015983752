import * as Yup from "yup";

export const valid = Yup.object().shape({
  periode_from: Yup.string().nullable().required("Is Required !"),
  periode_to: Yup.string().nullable().required("Is Required !"),
  invoice_date: Yup.string().nullable().required("Is Required !"),
  expired_date: Yup.string().nullable().required("Is Required !"),
  price: Yup.string().nullable().required("Is Required !"),
  number: Yup.string().nullable().required("Is Required !"),
  invoice_file: Yup.mixed().required("Is Required !"),
  // efaktur_file: Yup.mixed().required("Is Required !"),
});
