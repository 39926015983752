import { Table, message, Row, Col, Card, Spin, Button, Alert } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import _ from "lodash";
import useLogic from "./_logic";
import FormModal from "./modal";
import { AiOutlinePlusCircle } from "react-icons/ai";

dayjs.extend(relativeTime);

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    columnActions,
    onChangeTable,
    datas,
    loading,
    paginationProps,
    initData,
    setVisibleForm,
    visibleForm,
    addAction,
  } = useLogic(props);
  return (
    <React.Fragment>
      <Spin spinning={loading} tip="Mohon Tunggu ...">
        <Row justify="space-between">
          <Alert
            type="info"
            message={
              <div>
                <p>
                  Data diurutkan berdasarkan data
                  <span className="ml5 mr5 text-bold">Terbaru</span>
                  yang di-input kan.
                </p>
              </div>
            }
            showIcon
            className="mb15"
          />
          <Button
            icon={<AiOutlinePlusCircle />}
            className="p-hor"
            onClick={addAction}
            type="default"
          >
            Perpanjang KIR
          </Button>
        </Row>
        <Table
          columns={columns(columnActions)}
          dataSource={datas}
          rowKey={(record) => record.id}
          size="middle"
          showSorterTooltip={false}
          onChange={onChangeTable}
          pagination={{
            ...paginationProps,
            hideOnSinglePage: false,
            showSizeChanger: true,
            showTotal: (total, range) => {
              return (
                <div style={{ position: "absolute", left: 0 }}>
                  Daftar mulai {range[0]}-{range[1]} dari {total} Item
                </div>
              );
            },
          }}
          scroll={{ x: "100%" }}
        />
        <FormModal
          setVisible={setVisibleForm}
          visible={visibleForm}
          initData={initData}
        />
      </Spin>
    </React.Fragment>
  );
};
export default Index;
