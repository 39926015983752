import React from "react";
import { Row, Col, Card, Typography, Button } from "antd";
import VehicleCard from "./vehicle_card";
import BillingCard from "./billing_card";
import DocCard from "./doc_card";

const Index: React.FC<any> = () => {
  return (
    <Row gutter={[20, 20]} className="mt10">
      <Col xs={24} xl={8}>
        <VehicleCard />
      </Col>
      <Col xs={24} xl={8}>
        <BillingCard />
      </Col>
      <Col xs={24} xl={8}>
        <DocCard />
      </Col>
    </Row>
  );
};

export default Index;
