import _ from "lodash";
import dayjs from "dayjs";
import { IValues } from "./form/form_proto";

const adapterToEditForm = (dirtyData): IValues => {
  // init ConvertToString
  let vendor_id: any = _.get(dirtyData, "vendor_id");

  const dataIValues: IValues = {
    id: _.get(dirtyData, "id"),
    vendor_id,
    name: _.get(dirtyData, "name"),
    number: _.get(dirtyData, "number"),
    pic_name: _.get(dirtyData, "pic_name"),
  };
  return dataIValues;
};
export { adapterToEditForm };
