import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, post } from "app/req/documents";
import _ from "lodash";

export default () => {
  const [search, setSearch] = React.useState("");
  getAuthorize("billings", "show");

  return {
    search,
    setSearch,
  };
};
