import React from "react";
import FormEdit from "./form_edit";
import { IValues } from "./form_edit/form_proto";
import useLogic from "./_logic";

type TProps = {
  initData: IValues;
};

const Index: React.FC<TProps> = (props: TProps) => {
  const { onSubmit, initData } = useLogic(props);
  return <FormEdit initData={initData} onSubmit={onSubmit} />;
};
export default Index;
