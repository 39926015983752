import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, patch } from "app/req/vehicles";
import req from "app/utils/http_client_query_builder";
import { useHistory, useParams } from "react-router";
import { removeEmpty, removeEmptyUndefined } from "app/utils/functions";
import { IValues } from "../_components/form/form_proto";
import _ from "lodash";
import { adapterToEditForm } from "../_components/adapter";

export default () => {
  const { push } = useHistory();
  const { id } = useParams<any>();
  const [initData, setInitData] = React.useState<IValues>({});
  const [loading, setLoading] = React.useState(true);

  // getAuthorize("vehicles", "update");
  const onSubmit = async (values) => {
    // Change to From
    let valuesFormData = new FormData();
    let valuesOri = {
      ...values,
    };
    const tmpPlate = values.plate;
    const tmpPlateTax = values.plate_tax;
    const tmpLocation = values.location;
    const tmpKir = values.kir;
    const tmpInsurance = values.insurance;
    const tmpRentVehicle = values.rent_vehicle;
    delete valuesOri.plate;
    delete valuesOri.plate_tax;
    delete valuesOri.location;
    delete valuesOri.kir;
    delete valuesOri.insurance;
    delete valuesOri.rent_vehicle;
    for (let key in values) {
      valuesFormData.append(key, valuesOri[key]);
    }
    for (let key in tmpPlate) {
      valuesFormData.append(`plate[${key}]`, tmpPlate[key]);
    }
    for (let key in tmpPlateTax) {
      valuesFormData.append(`plate_tax[${key}]`, tmpPlateTax[key]);
    }
    for (let key in tmpLocation) {
      valuesFormData.append(`location[${key}]`, tmpLocation[key]);
    }
    for (let key in tmpKir) {
      valuesFormData.append(`kir[${key}]`, tmpKir[key]);
    }
    for (let key in tmpInsurance) {
      valuesFormData.append(`insurance[${key}]`, tmpInsurance[key]);
    }
    for (let key in tmpRentVehicle) {
      valuesFormData.append(`rent_vehicle[${key}]`, tmpRentVehicle[key]);
    }
    // End Change To Form

    const res = await patch(id, valuesFormData);
    if (res) {
      message.success("Data Has Been Edited");
      push(`/vehicles/detail/${id}`);
    }
  };
  const initFunction = async () => {
    const res = await req.get({ endpoint: `vehicles/${id}` });
    if (res.status == "success") {
      let notDirtyData = adapterToEditForm(res.data);
      setInitData(notDirtyData);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    initFunction();
  }, []);

  return {
    onSubmit,

    initData,
    loading,
  };
};
