import React from "react";
import { message, notification } from "antd";
import { post } from "app/utils/http_client_query_builder";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

export default (props) => {
  const { push } = useHistory();
  const queryUrl = useLocation<any>()?.search;
  const query = queryString.parse(queryUrl);
  const handleSubmit = async (values) => {
    const { email, token } = query;
    const data = {
      password: values.password,
      token,
      email,
    };
    const res = await post({ endpoint: "update_password", data });
    if (res.status == "success") {
      notification.success({
        placement: "topRight",
        message: "Success Change Password !",
        description:
          "Password has been resetted, please login with new Password. In 5 Second will be redirect to Login",
      });
      setTimeout(() => {
        push("/login");
      }, 5000);
    } else {
      message.error(res.data);
    }
  };

  return {
    handleSubmit,
  };
};
