import React from "react";
import { message } from "antd";
import { post } from "app/utils/http_client_query_builder";
import { getEmailLocal } from "app/utils/local_storage";

export default (props) => {
  const email = getEmailLocal();
  const handleSubmit = async (values) => {
    const data = {
      password: values.password,
      email,
    };
    const res = await post({ endpoint: "login", data });
    if (res.status == "success") {
      message.success("Success Login");
      window.location.reload(false);
    } else {
      message.error(res.data);
    }
  };

  return {
    handleSubmit,
    email,
  };
};
