import React from "react";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import { Row, Col } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import RolesComp from "./roles";
import PermissionsComp from "./permissions";
import { useDispatch } from "react-redux";
import { get as getPermissions } from "app/req/permissions";
import { get as getRoles } from "app/req/roles";

dayjs.extend(relativeTime);

const Index: React.FC<any> = () => {
  const dispatch = useDispatch();
  const [roleSelected, setRoleSelected] = React.useState<any>(false);
  const initFunction = async () => {
    await getPermissions();
  };
  const onChangeRoles = (nameRole) => {
    setRoleSelected(nameRole);
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    if (mounted) initFunction();
    return () => (mounted = false);
  }, []);
  return (
    <Layout>
      <div className="content_wrapper">
        <Row gutter={[25, 15]}>
          <Col xs={24} xl={12}>
            <Row>
              <Col xs={24} xl={24}>
                <RolesComp
                  roleSelected={roleSelected}
                  onChangeRoles={onChangeRoles}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={12}>
            <PermissionsComp roleSelected={roleSelected} />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
export default Index;
