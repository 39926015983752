import { Button, Col, Form, Row, Upload, message } from "antd";
import React from "react";
import _ from "lodash";
import { AiOutlinePlusCircle, AiOutlineDelete } from "react-icons/ai";
import TextLabel from "app/_components/ant_design/private_label";

interface IProps {
  setFile: (file: any) => any;
  initDataUrl: string;
}

const dummyRequest = (options) => {
  const { onSuccess } = options;
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 3;
  if (!isLt2M) {
    message.error("Image must smaller than 3MB!");
  }
  return isJpgOrPng && isLt2M;
};

const Index = (props: IProps) => {
  const [imageUrl, setImageUrl] = React.useState<any>(props.initDataUrl);
  React.useEffect(() => {
    setImageUrl(props.initDataUrl);
  }, [props.initDataUrl]);
  const handleChange = (info) => {
    const { originFileObj } = info.file;
    if (originFileObj) {
      getBase64(originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
      });
      props.setFile(originFileObj);
    }
  };
  const handleRemove = () => {
    props.setFile("empty");
    setImageUrl(false);
  };
  const uploadButton = (
    <div>
      <AiOutlinePlusCircle size={25} />
      <div style={{ marginTop: 8 }}>Pilih Poto</div>
    </div>
  );

  return (
    <Row>
      <Col span={8}>
        <TextLabel isRequired={false} label="Photo" />
      </Col>
      <Col span={16}>
        <Row>
          <Form.Item>
            <Upload
              multiple={false}
              showUploadList={false}
              listType="picture-card"
              fileList={[]}
              onPreview={() => {}}
              onChange={handleChange}
              beforeUpload={beforeUpload}
              customRequest={dummyRequest}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Row>
      </Col>
    </Row>
  );
};
export default Index;
