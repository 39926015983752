export interface IValues {
  id?: string;
  document_type_id?: string;
  institution?: string;
  title?: string;
  document_file?: {
    number?: string;
    effective_date?: string;
    expired_date?: string;
    pdf_file?: any;
    status?: any;
  };
}

const proto: IValues = {};
export default proto;
