import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import dayjs from "dayjs";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, values, handleSubmit, setFieldValue } = props;
  const {
    isYearly,
    positionStatusOptions,
    onChangeYearly,
    filePdfThumbnail,
  } = useLogic(props);
  let prevExpiredDate = _.get(values, "header_meta.expired_date");
  if (prevExpiredDate)
    prevExpiredDate = dayjs(prevExpiredDate).format("DD MMMM YYYY");
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Row className="mb15" style={{ marginTop: "-15px" }}>
            <div>
              <span className="text-bold">Catatan : </span>
              <span className="text-red">Mohon diperhatikan !</span>
              <p className="text-muted text-xs">
                Setelah melakukan perbaruan Masa Berlaku, Masa Berlaku yang lama
                akan diarsipkan.
              </p>
            </div>
          </Row>
        </Col>
        <Col span={24} className="mb15">
          <Row>
            <Col span={10}>Periode Perpanjangan</Col>
            <Col span={14}>
              <Row>
                <Switch
                  size="small"
                  onChange={onChangeYearly}
                  checked={isYearly}
                />
                <div className={s.switchText}>
                  <p className="text-xs">Perpanjangan 5 Tahunan</p>
                  <p className="text-xs text-muted">
                    Perpanjangan Bulan Plat akan muncul
                  </p>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
        {isYearly && (
          <React.Fragment>
            <Col span={24}>
              <Field name="plate_expired_month">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Masa Berlaku Plat STNK",
                      labelAlign: "left",
                      noReq: true,
                    }}
                    type="AntMonthPicker"
                    placeholder="Ex: 2020-01"
                  />
                )}
              </Field>
            </Col>
          </React.Fragment>
        )}
        {/* <Col span={24}>
          <Row>
            <Col span={10}>Tanggal Sebelumnya</Col>
            <Col span={14}>{prevExpiredDate}</Col>
          </Row>
        </Col> */}
        {/* <Col span={24}>
          <Field name="position.position_status_id">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Posisi Status STNK",
                  labelAlign: "left",
                }}
                type="AntSelect"
                placeholder="Ex: Samsat"
                selectOptions={positionStatusOptions}
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="position.position_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tanggal Posisi STNK",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-01-01"
              />
            )}
          </Field>
        </Col> */}
        <Col span={24}>
          <Field name="expired_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tgl. Masa Berlaku STNK",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-01-01"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mb5">
          <UploadFile
            setFile={(file) => setFieldValue("file", file)}
            initDataName={filePdfThumbnail}
            label="File Dok."
            extLists={[
              "pdf",
              "jpg",
              "jpeg",
              "png",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
              "doc",
              "docx",
            ]}
            invalidMessage="You can upload only Document/Image/PPt/Xls File !"
          />
        </Col>

        <Col span={24}>
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
