import React from "react";
import MyFormik, { IProps, IState } from "app/_components/formik";
import initDataProto, { IValues } from "./form_proto";
import dayjs from "dayjs";
import CompForm from "./comp_form";
import _ from "lodash";
import { valid } from "./form_validator";

interface MyProps extends IProps {
  initData?: IValues;
}
interface MyState extends IState {
  initData: IValues;
}

class Index extends MyFormik<MyProps, MyState> {
  static getDerivedStateFromProps(nextProps: MyProps, prevState: MyState) {
    const hasUpdateInitData = nextProps.initData !== prevState.initData;
    if (hasUpdateInitData) {
      const newValue = nextProps.initData;
      if (!_.isUndefined(newValue)) {
        return {
          initData: newValue,
        };
      }
      return null;
    }
    return null;
  }
  protected getChildren() {
    return CompForm;
  }
  protected getValidator() {
    return valid;
  }
  protected getInitData() {
    const { initData } = this.props;
    let result = initDataProto;
    if (!_.isEmpty(initData)) result = initData;
    return result;
  }
}

const IndexConverted: React.FC<MyProps> = (props: MyProps) => {
  const [initDataState, setInitDataState] = React.useState<IValues>(
    props.initData
  );
  const onSubmit = async (values) => {
    let invoice_date = dayjs(values.billing_pay.invoice_date).format(
      "YYYY-MM-DD"
    );
    let expired_date = dayjs(values.billing_pay.expired_date).format(
      "YYYY-MM-DD"
    );
    let periode_from = dayjs(values.billing_pay.periode_from).format(
      "YYYY-MM-DD"
    );
    let periode_to = dayjs(values.billing_pay.periode_to).format("YYYY-MM-DD");
    let billing_pay = {
      ...values.billing_pay,
      periode_from,
      periode_to,
      invoice_date,
      expired_date,
    };
    let pic_telp = null;
    if (values.pic_telp) {
      pic_telp = `+62-${values.pic_telp}`;
      values = {
        ...values,
        pic_telp,
      };
    }
    values = {
      ...values,
      billing_pay,
    };
    await props.onSubmit(values);
  };
  const initFunction = async () => {
    await setInitDataState(props.initData);
  };
  React.useEffect(() => {
    initFunction();
  }, [props.initData]);
  return <Index onSubmit={onSubmit} initData={initDataState} />;
};

export default IndexConverted;
