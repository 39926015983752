import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get as getUsers, post as postUsers } from "app/req/users";
import _ from "lodash";

export default () => {
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [search, setSearch] = React.useState("");
  getAuthorize("users", "show");
  const addAction = async (values) => {
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    valuesFormData.forEach((value, key) => {
      // For Logging Form Data
      // console.log("key %s: value %s", key, value);
    });
    const res = await postUsers(valuesFormData);
    if (_.get(res, "status") == "success") {
      message.success("Success Add User");
      await getUsers();
    }
  };

  return {
    visibleAdd,
    setVisibleAdd,
    visibleEdit,
    setVisibleEdit,
    search,
    setSearch,
    addAction,
  };
};
