import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { message, Modal } from "antd";
import Form from "./form_edit";
import { IValues } from "./form_edit/form_proto";
import {
  post as postDocumentFiles,
  patch as patchDocumentFiles,
  get as getDocumentFiles,
} from "app/req/document_files";

dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
  onSubmit?: any;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible } = props;
  const localAdapter = (paramValue): IValues => {
    return {
      id: paramValue?.id,
      number: paramValue?.number,
      pdf_file: paramValue?.pdf_url,
      effective_date: dayjs(paramValue?.effective_date),
      expired_date: dayjs(paramValue?.expired_date),
    };
  };
  const localAction = async (values) => {
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    const res = await patchDocumentFiles(values.id, valuesFormData);
    if (res) {
      message.success(`Success Edit Data History`);
    }
  };
  const onSubmit = async (values: IValues) => {
    await localAction(values);
    setVisible(false);
    await props?.onSubmit();
  };
  let initData: any = props.initData;
  if (props?.initData) initData = localAdapter(props.initData);
  return (
    <Modal
      title="Form Modal - Edit Dokumen (History)"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 50 }}
    >
      <Form onSubmit={onSubmit} initData={initData} />
    </Modal>
  );
};
export default Index;
