import React from "react";
import Asterisk from "app/_components/other/asterisk";
const TextLabel = (props: { isRequired: boolean; label: string }) => {
  const { label, isRequired } = props;
  return (
    <React.Fragment>
      <span>{label}</span>
      {isRequired && <Asterisk />}
    </React.Fragment>
  );
};
export default TextLabel;
