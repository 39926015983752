import React from "react";
import s from "./index.module.scss";
import { Avatar, Menu, Dropdown, message, Row, Col, Divider } from "antd";
import { FiMoreVertical, FiLogOut, FiUserCheck, FiLock } from "react-icons/fi";
import { useSelector } from "react-redux";
import { post } from "app/utils/http_client_query_builder";
import { camelCaseSpaces } from "app/utils/functions";
import { API_HOST } from "app/utils/consts";
import { useHistory } from "react-router-dom";
import { selector as selectorAuth } from "app/redux/auth";
import { PUBLIC_URL } from "app/utils/consts";
import { clear } from "app/utils/local_storage";
import CompSearch from "../search_menu";
import _ from "lodash";

const Index: React.FC<any> = () => {
  const { record } = useSelector(selectorAuth);
  const { push } = useHistory();
  const menu = () => {
    const actionLogout = async () => {
      clear("email");
      await post({ endpoint: "logout" });
      window.location.href = `${PUBLIC_URL}/login`;
    };
    const actionProfile = () => {
      push(`/users/${record.id}`);
    };
    const actionLocksScreen = async () => {
      // Logic LockScreen
      await post({ endpoint: "logout" });
      window.location.href = `${PUBLIC_URL}/locked`;
    };
    return (
      <Menu>
        <Menu.Item onClick={actionProfile}>
          <FiUserCheck />
          <span>Profile Saya</span>
        </Menu.Item>
        <Menu.Item onClick={actionLocksScreen}>
          <FiLock />
          <span>Kunci Layar</span>
        </Menu.Item>
        <Menu.Item onClick={actionLogout}>
          <FiLogOut />
          <span>Keluar</span>
        </Menu.Item>
      </Menu>
    );
  };

  let name = _.get(record, "name") || "--";
  let role = _.get(record, "roles[0]") || "--";
  // name = camelCaseSpaces(name);
  role = camelCaseSpaces(role);
  let avatar = _.get(record, "image_url");
  if (avatar) {
    avatar = `${API_HOST}/storage${avatar}`;
  } else {
    avatar = "https://robohash.org/5.png?set=set2";
  }
  const TmpCompDropdown = (
    <Dropdown
      trigger={["click"]}
      overlay={menu}
      placement="bottomRight"
      overlayClassName={s.dropdown}
    >
      <button className={s.right_menu}>
        <div className={s.content}>
          <p className={s.name}>{name}</p>
          <p className={s.title}>{role}</p>
        </div>
        <div className={s.avatar}>
          <Avatar src={avatar} />
        </div>
        <div className={s.icon_more}>
          <FiMoreVertical />
        </div>
      </button>
    </Dropdown>
  );
  return (
    <Row justify="end">
      <Col xs={0} xl={24}>
        <Row justify="space-between">
          <CompSearch />
          {TmpCompDropdown}
        </Row>
      </Col>
      <Col xs={24} xl={0}>
        <Row justify="end">{TmpCompDropdown}</Row>
      </Col>
    </Row>
  );
};
export default Index;
