import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Dropdown, Menu, Popconfirm } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import s from "./index.module.scss";
import _ from "lodash";
import { FaRegEdit } from "react-icons/fa";
import { RiEyeLine } from "react-icons/ri";

dayjs.extend(relativeTime);

interface IProps {
  key?: any;
  children?: React.ReactElement;
  record: any;
  actions: {
    detailAction: (record: any) => any;
    editAction: (record: any) => any;
    delAction: (record: any) => any;
  };
}

const Index: React.FC<IProps> = (props: IProps) => {
  const [visible, setVisible] = React.useState(false);
  const { delAction, detailAction, editAction } = props.actions;
  const { record } = props;
  const handleMenuClick = ({ key }) => {
    if (key !== "3") setVisible(false);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        className={s.contextMenu}
        onClick={() => {
          setVisible(false);
          detailAction(record);
        }}
      >
        <RiEyeLine /> Detail
      </Menu.Item>
      <Menu.Item
        key="2"
        className={s.contextMenu}
        onClick={() => {
          setVisible(false);
          editAction(record);
        }}
      >
        <FaRegEdit /> Ubah
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <Popconfirm
          title="Apakah anda yakin ?"
          onConfirm={() => {
            setVisible(false);
            delAction(record);
          }}
          okText="Yes"
          cancelText="No"
        >
          <div className={s.contextMenu}>
            <AiOutlineDelete />
            Hapus
          </div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={(e) => setVisible(e)}
    >
      {props.children}
    </Dropdown>
  );
};
export default Index;
