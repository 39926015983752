import * as layout from "./form_layout";
import s from "./index.module.scss";
import { Button, Col, Form, Row, Switch, Tooltip } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadPhoto from "./upload_photo";
import useLogic from "./_logic";
import { AiOutlineSave } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { EditOutlined } from "@ant-design/icons";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const genderOptions = [
  { value: "male", label: "Laki-Laki" },
  { value: "female", label: "Perempuan" },
];

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { setFieldValue, values, isValid } = props;
  const {
    getDefaultValueIsNotifiable,
    onChangeNotifiable,
    handleSubmit,
    isEdit,
    isSubmitting,
    avatar,
    onChangeEditEmail,
    locations,
    loadingFilter,
  } = useLogic(props);
  const { editEmail } = values;
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Row>
            <Col span={8}>
              <TextLabel isRequired label="Email" />
            </Col>
            {!isEdit ? (
              <Col span={16}>
                <Field name="email">
                  {(fieldProps) => (
                    <AntFields
                      {...fieldProps}
                      disabled={!editEmail}
                      type="AntInput"
                      placeholder="Ex: example_user"
                    />
                  )}
                </Field>
              </Col>
            ) : (
              <React.Fragment>
                <Col span={10}>
                  <Field name="email">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        disabled={!editEmail}
                        type="AntInput"
                        placeholder="Ex: example_user"
                      />
                    )}
                  </Field>
                </Col>
                <Col span={6}>
                  <div className="ml15" style={{ marginTop: "-3px" }}>
                    <Switch
                      disabled={isSubmitting}
                      size="small"
                      onChange={onChangeEditEmail}
                      checked={props.values.editEmail}
                    />
                    <p className="text-xs text-muted">Edit Email</p>
                  </div>
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Col>
        {!isEdit && (
          <Col span={24}>
            <Field name="password">
              {(fieldProps) => (
                <AntFields
                  {...fieldProps}
                  formItem={{
                    label: "Password",
                    labelAlign: "left",
                  }}
                  type="AntInputPassword"
                  placeholder="Ex: Ex4mpl3_Passw0rd"
                />
              )}
            </Field>
          </Col>
        )}
        <Col span={24}>
          <Field name="name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Lengkap",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: Example Name"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Row>
            <Col xs={24} lg={8}>
              <TextLabel isRequired label="Telepon" />
            </Col>
            <Col xs={24} lg={16}>
              <Field name="telp_postfix">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    type="AntInput"
                    placeholder="82216829353"
                    addonBefore="+62"
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Field name="location_id">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Lokasi proyek",
                  labelAlign: "left",
                  loading:loadingFilter,
                  noReq:true
                }}
                type="AntSelect"
                selectOptions={locations}
                allowClear={true}
                dropdownMatchSelectWidth={600}
                placeholder="Ex: Proyek A"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mb15">
          <Row>
            <Col span={8}>
              <TextLabel isRequired label="Status Notif." />
            </Col>
            <Col span={16}>
              <Row>
                <Switch
                  disabled={isSubmitting}
                  size="small"
                  onChange={onChangeNotifiable}
                  checked={getDefaultValueIsNotifiable()}
                />
                <div className={s.switchText}>
                  <p className="text-xs">User Ini Adalah Penerima Notif.</p>
                  <p className="text-xs text-muted">
                    Jika anda mengaktifkan ini, segala notifikasi akan
                    dikirimkan ke email ini.
                  </p>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <UploadPhoto
            setFile={(file) => setFieldValue("photo", file)}
            initDataUrl={avatar}
          />
        </Col>
        <Col span={24} className="mt5">
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Simpan
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
