import React from "react";
import { Row, Button, Divider, Col } from "antd";
import _ from "lodash";
import { FiTrello, FiCheckSquare } from "react-icons/fi";
import { camelCaseSpaces } from "app/utils/functions";
import { useHistory } from "react-router-dom";
import useLogic from "./_logic";
import { getAuthorize } from "app/utils/permissions";

type TProps = {
  dataPermissions: any;
  dataUsersPermissions: Array<any>;
};

const Index: React.FC<TProps> = (props: TProps) => {
  const { dataPermissions, dataUsersPermissions } = props;
  const tmpPermission = _.get(dataPermissions, "dataObject") || [];
  const { push } = useHistory();
  const {
    loadingSendEmail,
    convertMenusToId,
    convertPermissionsToId,
    onClickSendEmailTest,
  } = useLogic(props);
  let isEditRoles = false;
  const isRoleUpdate = getAuthorize("roles", "update", true, true);
  if(isRoleUpdate) isEditRoles = true;
  return (
    <div className="ml15 mr15">
      <Row justify="space-between" align="middle" className="mb10">
        <p className="text-bold">Daftar Akses yang diberikan</p>
        <div>
          {/* <Button
            className="p-hor mr15"
            onClick={onClickSendEmailTest}
            loading={loadingSendEmail}
          >
            Send Email Notif. (Test)
          </Button> */}
          {isEditRoles &&
          <Button
            icon={<FiTrello />}
            className="p-hor"
            onClick={() => push("/roles")}
          >
            Ubah Akses Grup
          </Button>
}
        </div>
      </Row>
      <Row gutter={[50, 25]}>
        {tmpPermission.map((item, i) => {
          const tmpSubject = _.get(item, "subject") || "--";
          const tmpPermissions = _.get(item, "permissions") || [];
          let indexInit = _.findIndex(dataUsersPermissions, {
            subject: tmpSubject,
          });
          let tmpSubjectCamelCases = camelCaseSpaces(tmpSubject);
          tmpSubjectCamelCases = convertMenusToId(tmpSubjectCamelCases);
          return (
            <Col span={8} key={i}>
              <Row justify="space-between">
                <p className="text-bold">{tmpSubjectCamelCases}</p>
              </Row>
              <Divider />
              {tmpPermissions.map((permItem, i2) => {
                let isChecked = false;
                let checkPermission: any = false;
                if (indexInit >= 0) {
                  checkPermission = _.get(
                    dataUsersPermissions,
                    `[${indexInit}].permissions`
                  );
                }
                if (_.includes(checkPermission, permItem)) {
                  isChecked = true;
                }
                if (!isChecked) return false;
                let tmpPermItem = camelCaseSpaces(permItem);
                tmpPermItem = convertPermissionsToId(tmpPermItem);
                return (
                  <Row justify="space-between" key={i2}>
                    <span>{tmpPermItem}</span>
                    <FiCheckSquare />
                  </Row>
                );
              })}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
export default Index;
