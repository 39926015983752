import React, { FC } from "react";
import Socialite from "./_components/socialite";
import CompForm from "./_components/form";
import s from "./index.module.scss";
import { Row, Col } from "antd";
import { postLogin } from "app/req/auth";
import {
  setEmailLocal,
  setEmailRemember,
  clearAll,
} from "app/utils/local_storage";

const Index: FC<any> = (props: any) => {
  const handleSubmit = async (values) => {
    if (values.remember) {
      setEmailRemember(values.email);
    } else {
      clearAll();
    }
    const loginRes = await postLogin(values);
    if (loginRes) {
      setEmailLocal(loginRes?.data?.user?.email);
      window.location.reload(false);
    }
  };
  return (
    <Row className={s.loginWrapper}>
      <img
        src={process.env.PUBLIC_URL + "/images/bg.jpg"}
        className={s.bgImg}
      />
      <Col xs={24} xl={24}>
        <Row justify="center">
          <div className={s.formWrapper}>
            <img
              className={s.logoLogin}
              src={process.env.PUBLIC_URL + "/images/logo_hki.png"}
            />
            <div className={s.body}>
              <CompForm onSubmit={handleSubmit} />
            </div>
          </div>
        </Row>
      </Col>
      {/* <Col xs={24} xl={0}>
        <Row justify="center">
          <div className={s.wrapperNotSupport}>
            <p className="text-lg text-red text-bold">Upss !</p>
            <p className="text-sm text-muted">Not Supported In Your Device</p>
          </div>
        </Row>
      </Col> */}
    </Row>
  );
};
export default Index;
