import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selector as selectorTaxVehicles } from "app/redux/tax_vehicles";
import { selector as selectorVehicles } from "app/redux/vehicles";
import {
  get as getTaxVehicles,
  setFilter as setFilterTaxVehicles,
  clearFilter as clearFilterTaxVehicles,
  setSort as setSortTaxVehicles,
  setPagination as setPaginationTaxVehicles,
} from "app/req/tax_vehicles";
import { removeEmpty } from "app/utils/functions";
import { useParams } from "react-router";
import { selector } from "app/redux/vehicles";

dayjs.extend(relativeTime);

const Index = (props) => {
  const pageParams = useParams<any>();
  // Change Module
  const dataParent = useSelector(selector).detail;
  const parentId = pageParams?.id;
  let vehicleIdTmp = dataParent?.id;
  let header_meta = {
    vehicle_id: vehicleIdTmp,
  };
  const datas = useSelector(selectorTaxVehicles).records;
  const pagination = useSelector(selectorTaxVehicles).pagination;
  const loading = useSelector(selectorTaxVehicles).loading;
  const dataHeader = useSelector(selectorVehicles).detail;

  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: any) => {
    await setFilterTaxVehicles(params?.filter);
    await setPaginationTaxVehicles({
      current_page: params?.page,
      per_page: params?.limit,
    });
    await setSortTaxVehicles(params?.sort);

    await Promise.all([getTaxVehicles(parentId, params)]);
  };

  const [visibleRenew, setVisibleRenew] = React.useState(false);
  const [visibleEditRenew, setVisibleEditRenew] = React.useState(false);
  const [visiblePlatePosition, setVisiblePlatePosition] = React.useState(false);
  const [initDataPlatePosition, setInitDataPlatePosition] = React.useState(
    null
  );
  const [initData, setInitData] = React.useState(null);

  const columnActions = {
    positionDetailAction: (record) => {
      console.log(record);
    },
    positionRenewAction: (record) => {
      setVisiblePlatePosition(true);
      setInitDataPlatePosition({
        tax_vehicle_id: record.id,
      });
    },
    editAction: (record) => {
      setVisibleEditRenew(true);
      let tmpValue: any = {
        header_meta,
        isEdit: true,
        id: record?.id,
        file: record?.file_url,
        plate_expired_month: record?.plate_expired_month,
        expired_date: record?.expired_date,
        position: {
          position_status_id:
            record?.plate_position_active?.position_status?.id,
          position_date: record?.plate_position_active?.position_date,
        },
      };
      if (record?.plate_expired_month) {
        tmpValue = {
          ...tmpValue,
          isYearly: true,
        };
      }
      setInitData(tmpValue);
    },
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = [];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    // End Filter
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort, filter });
  };
  React.useEffect(() => {
    const limit = 15;
    fetchSetData({ page: 1, limit });
  }, []);

  return {
    // Function
    onChangeTable,
    // State/Const
    dataHeader,
    datas,
    loading,
    paginationProps,
    columnActions,
    initData,
    header_meta,
    visibleRenew,
    setVisibleRenew,
    visibleEditRenew,
    setVisibleEditRenew,
    visiblePlatePosition,
    setVisiblePlatePosition,
    initDataPlatePosition,
    setInitDataPlatePosition,
  };
};
export default Index;
