import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import {
  AiOutlineSave,
  AiOutlineFilter,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, isValid, handleReset } = props;
  const {
    documentTypes,
    loadingFilter,
  } = useLogic(props);
  const handleSubmit = (valuesSubmit) => {
    props.handleSubmit(valuesSubmit);
  };
  return (
    <Form {...formLayout}>
      <Row align="middle" gutter={15}>
        <Col span={8}>
          <Field name="document_type_id">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tipe Dokumen",
                  labelAlign: "left",
                  loading: loadingFilter,
                  noReq: true,
                  noFeedback: true,
                }}
                type="AntSelect"
                selectOptions={documentTypes}
                dropdownMatchSelectWidth={600}
                placeholder="Ex: Sertfitikat"
              />
            )}
          </Field>
        </Col>
        <Col span={8}></Col>
        <Col span={8}></Col>
        <Col span={8}></Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Row justify="end" align="top">
            <Button
              icon={<AiOutlineCloseCircle />}
              onClick={handleReset as any}
              loading={isSubmitting}
              htmlType="submit"
              style={{ marginTop: "-25px", marginRight: "5px" }}
            >
              Clear
            </Button>
            <Button
              icon={<AiOutlineFilter />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              htmlType="submit"
              type="primary"
              style={{ marginTop: "-25px" }}
              className="bg-violet"
            >
              Terapkan Filter
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
