import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import dayjs from "dayjs";
import { AiOutlinePlusCircle, AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, values, handleSubmit, setFieldValue } = props;
  const {
    isYearly,
    positionStatusOptions,
    onChangeYearly,
    filePdfThumbnail,
  } = useLogic(props);
  let prevExpiredDate = _.get(values, "header_meta.expired_date");
  if (prevExpiredDate)
    prevExpiredDate = dayjs(prevExpiredDate).format("DD MMMM YYYY");
  return (
    <Form {...formLayout}>
      <Row>
        <Col span={16}>
          <Row>
            <Col span={24}>
              <Field name="position_status_id">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Posisi Status STNK",
                      labelAlign: "left",
                    }}
                    type="AntSelect"
                    placeholder="Ex: Samsat"
                    selectOptions={positionStatusOptions}
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="position_date">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Tanggal Posisi STNK",
                      labelAlign: "left",
                    }}
                    type="AntDatePicker"
                    placeholder="Ex: 2020-01-01"
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="end" align="top">
            <Button
              className="p-hor"
              icon={<AiOutlinePlusCircle />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Perbarui Posisi Plat
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
