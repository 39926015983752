import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { Col, Divider, message, Modal, Row } from "antd";
import Form from "./_components/form";
import { IValues } from "./_components/form/form_proto";
import Req from "app/utils/http_client_query_builder";
import MyTable from "./_components/table";

dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible } = props;
  const [refresh, setRefresh] = React.useState(false);
  let expired_date = _.get(props, "initData.expired_date") || null;
  if (expired_date) expired_date = dayjs(expired_date);

  const tmpInitData: IValues = {
    tax_vehicle_id: props.initData?.tax_vehicle_id,
  };

  const onSubmit = async (values: IValues) => {
    const { tax_vehicle_id } = values;
    const resPost = await Req.post({
      endpoint: `plate_positions/${tax_vehicle_id}`,
      data: values,
    });
    if (resPost.status == "success") {
      message.success("Success Add Position");
    }
    setRefresh(!refresh);
  };
  return (
    <Modal
      title="Modal Detail - Posisi Plat STNK"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      width={800}
      style={{ top: 30 }}
    >
      <Form onSubmit={onSubmit} initData={tmpInitData} />
      <Divider />
      <MyTable taxPositionId={tmpInitData.tax_vehicle_id} refresh={refresh} />
    </Modal>
  );
};
export default Index;
