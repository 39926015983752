import React from "react";
import { Layout, Row, Col } from "antd";
import s from "./index.module.scss";
import NavLogo from "./components/sider/logo";
import NavMain from "./components/sider/main";
import NavFooter from "./components/sider/footer";
import LayoutHeader from "./components/top";
import LayoutBreadcrumb from "./components/top/breadcrumb";
import LayoutFooter from "./components/bottom";

const { Content, Sider } = Layout;

const MobileComp: React.FC<any> = () => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <img
        src={process.env.PUBLIC_URL + "/images/bg.jpg"}
        className={s.bgImg}
      />
      <div className={s.wrapperNotSupport}>
        <p className="text-lg text-red text-bold">Upss !</p>
        <p className="text-sm text-muted">Not Supported In Your Device</p>
      </div>
    </Row>
  );
};
const MenuSiderDesktop = (
  <Sider className={s.nav_sider}>
    <NavLogo />
    <NavMain />
    {/* <NavFooter /> */}
  </Sider>
);
const DesktopComp: React.FC<any> = (props: any) => {
  return (
    <Row>
      <Col span={24}>
        <Layout className={s.layout}>
          <Row>
            <Col span={0} xl={24}>
              {MenuSiderDesktop}
            </Col>
          </Row>
          <Layout className={s.content}>
            <LayoutHeader />
            <Content className={s.content_container}>
              <LayoutBreadcrumb />
              {props.children}
            </Content>
            <LayoutFooter />
          </Layout>
        </Layout>
      </Col>
    </Row>
  );
};

const Index: React.FC<any> = (props) => {
  return (
    // <Row>
    //   <Col span={24} xl={0}>
    //     <MobileComp />
    //   </Col>
    //   <Col span={24} xl={24}>
    //   </Col>
    // </Row>
    <DesktopComp>{props.children}</DesktopComp>
  );
};
export default Index;
