import { IRoutes } from "./types";
import Dashboard from "app/pages/private/dashboard";
import Users from "app/pages/private/users/dashboard";
import UsersDetail from "app/pages/private/users/detail";
import Roles from "app/pages/private/roles";
import Brands from "app/pages/private/brands";
import VehicleModels from "app/pages/private/vehicle_models";
import VehicleVariants from "app/pages/private/vehicle_variants";
import Vehicles from "app/pages/private/vehicles/dashboard";
import VehiclesAdd from "app/pages/private/vehicles/add";
import VehiclesEdit from "app/pages/private/vehicles/edit";
import VehiclesDetail from "app/pages/private/vehicles/detail";
import Departments from "app/pages/private/departments";
import DocumentTypes from "app/pages/private/document_types";
import Documents from "app/pages/private/documents/dashboard";
import DocumentDetail from "app/pages/private/documents/detail";
import Billings from "app/pages/private/billings/dashboard";
import BillingAdd from "app/pages/private/billings/add";
import BillingDetail from "app/pages/private/billings/detail";
import BillingEdit from "app/pages/private/billings/edit";
import Vendors from "app/pages/private/vendors/dashboard";
import VendorEdit from "app/pages/private/vendors/edit";
import Locations from "app/pages/private/locations";

const routes: IRoutes[] = [
  { path: "/", component: Dashboard, exact: true },
  { path: "/users", component: Users, exact: true },
  { path: "/users/:id", component: UsersDetail },
  { path: "/roles", component: Roles },
  { path: "/brands", component: Brands },
  { path: "/vehicle_models", component: VehicleModels },
  { path: "/vehicle_variants", component: VehicleVariants },
  { path: "/vehicles", component: Vehicles, exact: true },
  { path: "/vehicles/add", component: VehiclesAdd },
  { path: "/vehicles/edit/:id", component: VehiclesEdit },
  { path: "/vehicles/detail/:id", component: VehiclesDetail },
  // { path: "/departments", component: Departments },
  { path: "/document_types", component: DocumentTypes },
  { path: "/documents", component: Documents, exact: true },
  { path: "/documents/detail/:id", component: DocumentDetail },
  { path: "/billings", component: Billings, exact: true },
  { path: "/billings/add", component: BillingAdd },
  { path: "/billings/edit/:id", component: BillingEdit },
  { path: "/billings/detail/:id", component: BillingDetail },
  { path: "/vendors", component: Vendors, exact: true },
  { path: "/vendors/edit/:id", component: VendorEdit },
  { path: "/locations", component: Locations },
];
export default routes;
