import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadPhoto from "./upload_photo";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import { FaSave } from "react-icons/fa";
import { formatterCurrency, parserCurrency } from "app/utils/functions";
import {positionBpkbLists} from "app/pages/private/vehicles/_components/const";

const { RangePicker } = Datepicker;

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, handleSubmit, values } = props;
  const { show_owner } = values;
  const {
    onSearchLocations,
    onSearchBrands,
    onSearchPics,
    onSearchVehicleModels,
    onChangeStatus,
    onChangeOwner,
    onSearchVendors,
    onSearchInsuranceVendors,
    getDefaultValueSatus,
    getDefaultValueOwner,
    onSearchWheels,

    locationLists,
    locationLoading,
    brandLists,
    brandLoading,
    picLists,
    picLoading,
    vehicleModelLists,
    vehicleModelLoading,
    vendorsLists,
    vendorsLoading,
    image_url,
    isEdit,
    fuelLists,
    fuelLoading,
    wheelLists,
    wheelLoading,
    insuranceVendorLists,
    insuranceVendorLoading,
    onChangePeriode,
    locationDisabled
  } = useLogic(props);
  return (
    <Form {...formLayout}>
      <Row gutter={30}>
        <Col span={12}>
          <Row align="middle">
            <div className={s.dividerTitle}>
              <p>Informasi Kendaraan</p>
            </div>
            <Col span={24}>
              <Field name="vehicle_model_id">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Model",
                      labelAlign: "left",
                      loading: vehicleModelLoading,
                    }}
                    type="AntSelect"
                    placeholder="Ketik Untuk Cari Item"
                    selectOptions={vehicleModelLists}
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="brand_id">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Merek",
                      labelAlign: "left",
                      loading: brandLoading,
                    }}
                    type="AntSelect"
                    placeholder="Ketik Untuk Cari Item"
                    selectOptions={brandLists}
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="plate.type">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Tipe",
                      labelAlign: "left",
                    }}
                    type="AntInput"
                    placeholder="Ex: M121-GGWP"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="plate.production_year">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Tahun Produksi",
                      labelAlign: "left",
                    }}
                    type="AntYearPicker"
                    placeholder="Ex: 2020"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="plate.fuel">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Bahan Bakar",
                      labelAlign: "left",
                      loading: fuelLoading,
                    }}
                    type="AntSelect"
                    placeholder="Ketik Untuk Cari Item"
                    selectOptions={fuelLists}
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="color">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Warna",
                      labelAlign: "left",
                      noReq: true,
                    }}
                    type="AntInput"
                    placeholder="Ex: Red"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="description">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Deskripsi",
                      labelAlign: "left",
                      noReq: true,
                    }}
                    type="AntTextArea"
                    rows={5}
                    placeholder="Ex: Ini adalah mobil model terbaru"
                  />
                )}
              </Field>
            </Col>
            <div className={s.dividerTitle}>
              <p>Informasi Nomor Kendaraan</p>
            </div>
            <Col span={24}>
              <Field name="plate.position_bpkb">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Posisi BPKB",
                      labelAlign: "left",
                      noReq: true
                    }}
                    type="AntSelect"
                    selectOptions={positionBpkbLists}
                    placeholder="Ex: HKI"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="plate.plate_number">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Nomor Plat",
                      labelAlign: "left",
                    }}
                    type="AntInput"
                    placeholder="Ex: B 2525 LK"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="plate.machine_number">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Nomor Mesin",
                      labelAlign: "left",
                      noReq: true,
                    }}
                    type="AntInput"
                    placeholder="Ex: KKMS-KF284829-2AA"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="plate.register_number">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Nomor Rangka",
                      labelAlign: "left",
                      noReq: true,
                    }}
                    type="AntInput"
                    placeholder="Ex: 5992849201"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="plate.bpkb_number">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Nomor BPKB",
                      labelAlign: "left",
                      noReq: true,
                    }}
                    type="AntInput"
                    placeholder="Ex: 5992849201"
                  />
                )}
              </Field>
            </Col>
            <div className={s.dividerTitle}>
              <p>Informasi Media</p>
            </div>
            <Col span={24} className="mt5">
              <UploadPhoto
                setFile={(file) => setFieldValue("photo", file)}
                initDataUrl={image_url}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row align="middle">
            <div className={s.dividerTitle}>
              <p>Informasi Kepemilikan</p>
            </div>
            <Col span={24}>
              <Field name="plate.name">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Nama (STNK)",
                      labelAlign: "left",
                    }}
                    type="AntInput"
                    placeholder="Ex: Wahyudi S."
                  />
                )}
              </Field>
            </Col>
            {!isEdit && (
              <React.Fragment>
                <Col span={24}>
                  <Field name="location.location_id">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Posisi Lokasi",
                          labelAlign: "left",
                          loading: locationLoading,
                        }}
                        disabled={locationDisabled}
                        type="AntSelect"
                        placeholder="Ketik Untuk Cari Item"
                        selectOptions={locationLists}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24}>
                  <Field name="location.driver_name">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Pemakai",
                          labelAlign: "left",
                          noReq: true,
                        }}
                        type="AntInput"
                        placeholder="Ex: Ncep Somantri"
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24}>
                  <Field name="location.pic_name">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "PIC Name",
                          labelAlign: "left",
                          noReq: true,
                        }}
                        type="AntInput"
                        placeholder="Ex: Bambang M."
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col xs={24} lg={6}>
                      <span>Telp. PIC</span>
                    </Col>
                    <Col xs={24} lg={18}>
                      <Field name="location.pic_telp">
                        {(fieldProps) => (
                          <AntFields
                            {...fieldProps}
                            type="AntInput"
                            placeholder="Ex: 82216829353"
                            addonBefore="+62"
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </Col>
              </React.Fragment>
            )}
            <Col span={24} className="mb15">
              <Row>
                <Col span={6}>
                  <span>Status</span>
                </Col>
                <Col span={18}>
                  <Row>
                    <Switch
                      size="small"
                      onChange={onChangeOwner}
                      checked={getDefaultValueOwner()}
                    />
                    <div className={s.switchText}>
                      <p className="text-xs">Kendaaraan Ini Adalah Sewa</p>
                      <p className="text-xs text-muted">
                        Jika anda mengaktifkan ini, kendaraan ini akan masuk
                        kedalam status sewa
                      </p>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
            {show_owner && (
              <React.Fragment>
                <Col span={24}>
                  <Field name="owner_id">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Vendor Sewa",
                          labelAlign: "left",
                          loading: vendorsLoading,
                        }}
                        type="AntSelect"
                        placeholder="Ketik Untuk Cari Item"
                        selectOptions={vendorsLists}
                      />
                    )}
                  </Field>
                </Col>

                <Col span={24}>
                  <Field name="rent_vehicle.contract_number">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Nomor Kontrak",
                          labelAlign: "left",
                          noReq: true,
                        }}
                        type="AntInput"
                        placeholder="Ex: CONTRACT-002"
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24}>
                  <Field name="rent_vehicle.price">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Harga Sewa",
                          labelAlign: "left",
                          noReq: true,
                        }}
                        type="AntInputNumber"
                        parser={parserCurrency}
                        formatter={formatterCurrency}
                        placeholder="Ex: 2.500.000.000"
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24} className="mb25">
                  <Row>
                    <Col span={6}>
                      <TextLabel isRequired={false} label="Periode" />
                    </Col>
                    <Col span={18}>
                      <RangePicker
                        clearIcon={false}
                        value={[
                          values?.rent_vehicle?.start,
                          values?.rent_vehicle?.end,
                        ]}
                        onChange={onChangePeriode}
                        style={{ width: "100%" }}
                        disabled={isSubmitting}
                      />
                    </Col>
                  </Row>
                </Col>
              </React.Fragment>
            )}

            <div className={s.dividerTitle}>
              <p>Informasi Pajak kendaraan</p>
            </div>
            <Col span={24}>
              <Field name="plate_tax.expired_date">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Tgl. Masa Berlaku",
                      labelAlign: "left",
                    }}
                    type="AntDatePicker"
                    placeholder="Ex: 2020-01-23"
                  />
                )}
              </Field>
            </Col>
            {/* Posisi Status Field ! */}
            <Col span={24}>
              <Field name="plate_tax.plate_expired_month">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Masa Berlaku Plat",
                      labelAlign: "left",
                    }}
                    type="AntMonthPicker"
                    placeholder="Ex: 2020-01"
                  />
                )}
              </Field>
            </Col>
            {!isEdit && (
              <React.Fragment>
                <div className={s.dividerTitle}>
                  <p>Informasi KIR</p>
                </div>
                <Col span={24}>
                  <Field name="kir.number">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Nomor KIR",
                          labelAlign: "left",
                          noReq: true,
                        }}
                        type="AntInput"
                        placeholder="Ex: 55929423294"
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24}>
                  <Field name="kir.expired_date">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Masa Berlaku KIR",
                          labelAlign: "left",
                          noReq: true,
                        }}
                        type="AntDatePicker"
                        placeholder="Ex: 2020-01-01"
                      />
                    )}
                  </Field>
                </Col>
                <div className={s.dividerTitle}>
                  <p>Informasi Asuransi</p>
                </div>
                <Col span={24}>
                  <Field name="insurance.vendor_id">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Vendor Asuransi",
                          labelAlign: "left",
                          loading: insuranceVendorLoading,
                          noReq: true,
                        }}
                        type="AntSelect"
                        placeholder="Ketik Untuk Cari Item"
                        selectOptions={insuranceVendorLists}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24}>
                  <Field name="insurance.number">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Nomor Polis",
                          labelAlign: "left",
                          noReq: true,
                        }}
                        type="AntInput"
                        placeholder="Ex: 55929423294"
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24}>
                  <Field name="insurance.expired_date">
                    {(fieldProps) => (
                      <AntFields
                        {...fieldProps}
                        formItem={{
                          label: "Tgl. Masa Berlaku",
                          labelAlign: "left",
                          noReq: true,
                        }}
                        type="AntDatePicker"
                        placeholder="Ex: 2020-01-01"
                      />
                    )}
                  </Field>
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Col>
      </Row>
      <Row justify="end" align="middle">
        <Button
          onClick={handleSubmit as any}
          loading={isSubmitting}
          type="primary"
          htmlType="submit"
          className="p-hor"
        >
          <FaSave />
          Simpan
        </Button>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
