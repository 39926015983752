import React from "react";
import { Row, Col, Card, Typography, Button, Empty, Spin, Tabs } from "antd";
import ItemChild from "./item_child";
import useLogic from "./_logic";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const Index: React.FC<any> = (props) => {
  const { datas, loading, month, onChangeTab, betweenExpired } = useLogic();
  const { push } = useHistory();
  const link = `/vehicles?between_expired_date=${betweenExpired}`;
  const isEmpty = _.isEmpty(datas);
  const TabContent = (
    <Row>
      <Col span={24}>
        {isEmpty && <Empty />}
        {datas.map((item, idx) => {
          return (
            <ItemChild
              id={item?.id}
              key={item?.id}
              expiredDate={item?.plate?.plate_tax_active?.expired_date}
              name={item?.plate?.type}
              plateNumber={item?.plate?.plate_number}
              location={item?.location_active?.location?.name}
              owner={item?.owner?.name}
            />
          );
        })}
        {!isEmpty && (
          <Button
            size="small"
            block
            danger
            type="link"
            onClick={() => push(link)}
          >
            Lihat Semua
          </Button>
        )}
      </Col>
    </Row>
  );
  return (
    <Card>
      <Row justify="space-between" align="top">
        <Col>
          <div>
            <p className="text-bold">Kendaraan yang akan masuk Masa Pajak</p>
            <p className="text-muted text-xs">{month} Pekan yang akan datang</p>
          </div>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Tabs defaultActiveKey="1" onChange={onChangeTab}>
          <Tabs.TabPane tab="1 Pekan" key={1}>
            {TabContent}
          </Tabs.TabPane>
          <Tabs.TabPane tab="2 Pekan" key={2}>
            {TabContent}
          </Tabs.TabPane>
          <Tabs.TabPane tab="3 Pekan" key={3}>
            {TabContent}
          </Tabs.TabPane>
          <Tabs.TabPane tab="4 Pekan" key={4}>
            {TabContent}
          </Tabs.TabPane>
          <Tabs.TabPane tab="5 Pekan" key={5}>
            {TabContent}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Yang Terlewat" key={6}>
            {TabContent}
          </Tabs.TabPane>
        </Tabs>
      </Spin>
    </Card>
  );
};

export default Index;
