import {
  Table,
  message,
  Row,
  Col,
  Card,
  Spin,
  Tag,
  Typography,
  Image,
  Empty,
} from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { useSelector } from "react-redux";
import { selector } from "app/redux/vehicles";
import s from "./index.module.scss";
import { camelCaseSpaces, convertToRupiah } from "app/utils/functions";
import { API_HOST } from "app/utils/consts";
import { kindList } from "app/pages/private/vehicle_models/_components/const";
import { fuelLists } from "app/pages/private/vehicles/_components/const";
const { Link } = Typography;

dayjs.extend(relativeTime);

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const data = useSelector(selector).detail;
  const plate = _.get(data, "plate");
  const checkData = (
    fieldName,
    config?: {
      isDate?: boolean;
      isMonth?: boolean;
      withHour?: boolean;
      withRelative?: boolean;
    }
  ) => {
    let tmp: any = _.get(data, fieldName) || false;
    const isDate = config?.isDate;
    const isMonth = config?.isMonth;
    const withHour = config?.withHour;
    const withRelative = config?.withRelative;
    if (!tmp) {
      return (tmp = "--");
    }
    if (isDate || isMonth) {
      let myFormat = "DD MMMM YYYY";
      if (isMonth) myFormat = "MMMM YYYY";
      if (withHour) {
        myFormat = `${myFormat}  HH:mm`;
      }
      let tmpFormatted: any = dayjs(tmp).format(myFormat);
      tmpFormatted = `${tmpFormatted}`;
      if (withHour) tmpFormatted = `${tmpFormatted} WIB`;
      if (withRelative) {
        let relativeTime: any = dayjs(tmp).fromNow();
        const relativeTimeConverted = (
          <span className="ml10 text-muted text-xs">({relativeTime})</span>
        );
        tmpFormatted = (
          <span>
            {tmpFormatted} {relativeTimeConverted}
          </span>
        );
      }
      tmp = tmpFormatted;
    }
    return tmp;
  };
  const getImageUrl = (dataImage) => {
    let hasil = process.env.PUBLIC_URL + "/images/placeholder.png";
    if (dataImage && dataImage != "--")
      hasil = `${API_HOST}/storage${dataImage}`;
    return hasil;
  };
  const checkStatus = (dataStatus) => {
    if (dataStatus === "warning") {
      dataStatus = <Tag color="geekblue">Nyala</Tag>;
    } else if (dataStatus === "active") {
      dataStatus = <Tag color="red">Mati</Tag>;
    } else {
      dataStatus = <Tag>Diarsipkan</Tag>;
    }
    return dataStatus;
  };
  const checkPaid = (dataPaid) => {
    if (dataPaid == "--") {
      dataPaid = <Tag color="red">Belum Bayar</Tag>;
    } else {
      dataPaid = <Tag color="green">Lunas</Tag>;
    }
    return dataPaid;
  };
  const checkUrl = (dataUrl, folderName = "documents") => {
    if (dataUrl != "--") {
      let tmpUrl: any = `${API_HOST}/storage${dataUrl}`;
      return (
        <Link href={tmpUrl} target="_blank" copyable>
          {tmpUrl}
        </Link>
      );
    } else {
      return `--`;
    }
  };
  const getStatusOwner = () => {
    if (data?.owner != null) {
      return <Tag color="red">Sewa</Tag>;
    } else {
      return <Tag color="default">Dimiliki</Tag>;
    }
  };
  const date = {
    isDate: true,
    withRelative: true,
  };
  const dateTime = {
    ...date,
    withHour: true,
  };

  let kind: any = _.find(kindList, { value: checkData("vehicle_model.kind") })
    ?.label;
  if (kind) kind = camelCaseSpaces(kind);
  let fuel: any = _.find(fuelLists, { value: checkData("plate.fuel") })?.label;
  if (fuel) fuel = camelCaseSpaces(fuel);
  const convertData: any = {
    // VehicleContent
    name: checkData("plate.type"),
    kind: checkData("vehicle_model.vehicle_variant.name"),
    model: checkData("vehicle_model.name"),
    color: checkData("color"),
    // price: convertToRupiah(checkData("price")),
    statusOwner: getStatusOwner(),
    imageUrl: getImageUrl(checkData("image_url")),
    createdAt: checkData("created_at", { isDate: true, withHour: true }),
    updatedAt: checkData("updated_at", { isDate: true, withHour: true }),
    // PlateContent
    plateName: checkData("plate.name"),
    fuel: checkData("plate.fuel"),
    type: checkData("plate.type"),
    productionYear: checkData("plate.production_year"),
    plateNumber: checkData("plate.plate_number"),
    stnkNumber: checkData("plate.stnk_number"),
    bpkbNumber: checkData("plate.bpkb_number"),
    machineNumber: checkData("plate.machine_number"),
    registerNumber: checkData("plate.register_number"),
    positionBpkb: checkData("plate.position_bpkb"),
    // Informasi Masa Berlaku
    statusNotif: checkStatus(checkData("plate.plate_tax_active.status")),
    expiredDate: checkData("plate.plate_tax_active.expired_date", date),
    expiredMonth: checkData("plate.plate_tax_active.plate_expired_month", {
      isMonth: true,
      withRelative: false,
    }),
    positionDate: checkData(
      "plate.plate_tax_active.plate_position_active.position_date",
      {
        isDate: true,
        withRelative: false,
      }
    ),
    positionStatus: checkData(
      "plate.plate_tax_active.plate_position_active.position_status.name"
    ),
    // Lokasi
    location: checkData("location_active.location.name"),
    prevLocation: checkData("location_active.prev_location.name"),
    locationPicName: checkData("location_active.pic_name"),
    locationPicTelp: checkData("location_active.pic_telp"),
    locationDate: checkData("location_active.movement_date", {
      isDate: true,
      withRelative: true,
    }),
    // Owner
    ownerName: checkData("owner.name"),
    ownerTelp: checkData("owner.telp"),
    ownerAddress: checkData("owner.address"),
    // Creator and Upater
    createdBy: checkData("creator.name"),
    updatedBy: checkData("updater.name"),
  };
  const vehicleContent = [
    { label: "Jenis", value: convertData.kind },
    { label: "Model", value: convertData.model },
    { label: "Warna", value: convertData.color },
    // { label: "Harga", value: convertData.price },
    { label: "Kepemilikan", value: convertData.statusOwner },
  ];

  const plateContent = [
    { label: "Nama Pemilik", value: convertData.plateName },
    { label: "Bahan Bakar", value: convertData.fuel },
    { label: "Tipe Kendaraan", value: convertData.type },
    { label: "Tahun Produksi", value: convertData.productionYear },
    { label: "Nomor Plat", value: convertData.plateNumber },
    // { label: "Nomor STNK", value: convertData.stnkNumber },
    { label: "Nomor BPKB", value: convertData.bpkbNumber },
    { label: "Nomor Mesin", value: convertData.machineNumber },
    { label: "Nomor Rangka", value: convertData.registerNumber },
    { label: "Posisi BPKB", value: convertData.positionBpkb },
  ];
  const expiredContent = [
    {
      label: "Status Notifikasi",
      value: convertData.statusNotif,
    },
    { label: "Tgl. Masa Berlaku STNK", value: convertData.expiredDate },
    { label: "Masa Berlaku STNK", value: convertData.expiredMonth },
    // { label: "Tanggal Posisi STNK", value: convertData.positionDate },
    // { label: "Status Posisi STNK", value: convertData.positionStatus },
  ];
  const isLocation = _.get(data, "location_active") || false;
  const locationContent = [
    {
      label: "Nama Lokasi",
      value: convertData.location,
    },
    {
      label: "Nama PIC",
      value: convertData.locationPicName,
    },
    {
      label: "Telp. PIC",
      value: convertData.locationPicTelp,
    },
    {
      label: "Tanggal Perpindahan",
      value: convertData.locationDate,
    },
  ];
  const isOwner = _.get(data, "owner") || false;
  const ownerContent = [
    {
      label: "Nama Vendor",
      value: convertData.ownerName,
    },
    {
      label: "Telp. Vendor",
      value: convertData.ownerTelp,
    },
    {
      label: "Alamat Vendor",
      value: convertData.ownerAddress,
    },
  ];
  const renderItem = (item, key) => {
    return (
      <div className="mb5" key={key}>
        <span className="text-bold mr5">{item?.label} : </span>
        <span>{item?.value}</span>
      </div>
    );
  };
  return (
    <Card className="mb25">
      <Spin spinning={_.isEmpty(data) ? true : false}>
        <Row gutter={25}>
          <Col xs={24} xl={12}>
            <Row gutter={25}>
              <Col xs={24} xl={12}>
                <Image src={convertData.imageUrl} className={s.imageBox} />
              </Col>
              <Col xs={24} xl={12}>
                <div className={s.dividerTitle}>
                  <p>{convertData.name}</p>
                </div>
                {vehicleContent.map((item, idx) => {
                  return renderItem(item, idx);
                })}
                <div className="mb5">
                  <span className="text-bold">Dibuat pada </span>
                  <p>{convertData.createdAt}</p>
                </div>
                <div className="mb5">
                  <span className="text-bold">Terakhir diperbarui pada </span>
                  <p>{convertData.updatedAt}</p>
                </div>
                <div className="mb5">
                  <span className="text-bold">Dibuat oleh </span>
                  <span>{convertData.createdBy}</span>
                </div>
                <div className="mb5">
                  <span className="text-bold">Diperbarui oleh </span>
                  <p>{convertData.updatedBy}</p>
                </div>
              </Col>
            </Row>
            <div className={`${s.dividerTitle} mt20`}>
              <p>Informasi Plat</p>
            </div>
            {plateContent.map((item, idx) => {
              return renderItem(item, idx);
            })}
          </Col>
          <Col xs={24} xl={12}>
            <div className={s.dividerTitle}>
              <p>Informasi Masa Berlaku STNK</p>
            </div>
            {expiredContent.map((item, idx) => {
              return renderItem(item, idx);
            })}
            <div className={`${s.dividerTitle} mt20`}>
              <p>Informasi Lokasi</p>
            </div>
            {isLocation ? (
              locationContent.map((item, idx) => {
                return renderItem(item, idx);
              })
            ) : (
              <Empty />
            )}
            <div className={`${s.dividerTitle} mt20`}>
              <p>Informasi Vendor / Pemilik</p>
            </div>
            {isOwner ? (
              ownerContent.map((item, idx) => {
                return renderItem(item, idx);
              })
            ) : (
              <Empty />
            )}
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};
export default Index;
