import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, post } from "app/req/billings";
import _ from "lodash";
import { useHistory } from "react-router";

export default () => {
  getAuthorize("billings", "create");
  const { push } = useHistory();
  const onSubmit = async (values) => {
    let valuesFormData = new FormData();
    let dataOri = {
      ...values,
    };
    delete dataOri.billing_pay;
    delete dataOri.payer;

    const tmpBillingPay = values.billing_pay;
    const tmpPayer = values.payer;
    for (let key in dataOri) {
      valuesFormData.append(key, dataOri[key]);
    }
    for (let key in tmpBillingPay) {
      valuesFormData.append(`billing_pay[${key}]`, tmpBillingPay[key]);
    }
    for (let key in tmpPayer) {
      valuesFormData.append(`payer[${key}]`, tmpPayer[key]);
    }
    // End Change To Form

    const res = await post(valuesFormData);
    if (res) {
      message.success("Data Has Been Added");
      push("/billings");
    }
  };

  return {
    onSubmit,
  };
};
