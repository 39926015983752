import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";

export default (props) => {
  const [departmentLists, setDepartmentLists] = React.useState<any>([]);
  const [departmentLoading, setDepartmentLoading] = React.useState<any>(true);
  const [documentTypeLists, setDocumentTypeLists] = React.useState<any>([]);
  const [documentTypeLoading, setDocumentTypeLoading] = React.useState<any>(
    true
  );
  const { values } = props;
  const getFileThumnail = () => {
    let filePdfTmp: any = _.get(values, "document_file.pdf_file");
    if (filePdfTmp) {
      if (typeof filePdfTmp == "object") {
        filePdfTmp = filePdfTmp?.name;
      } else if (filePdfTmp != "" || filePdfTmp != "empty") {
        filePdfTmp = filePdfTmp;
      } else {
        filePdfTmp = filePdfTmp ? filePdfTmp : "--";
      }
    }

    return {
      filePdfThumbnail: filePdfTmp,
    };
  };
  const getParams = ({ search, id }) => {
    let myParams: any = {
      filter: { name: search },
      limit: 9999999999,
    };
    if (id) {
      myParams = {
        ...myParams,
        custom: { id },
      };
    }
    return myParams;
  };
  const getConvertLists = (datas) => {
    return convertToSelect({
      datas: datas,
      labelName: "name",
      valueName: "id",
    });
  };
  // Departments
  const getDepartments = async ({ search, id }) => {
    setDepartmentLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `departments`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setDepartmentLists(tmpLists);
    }
    setDepartmentLoading(false);
  };
  const onSearchDepartments = async (search) => {
    await getDepartments({ search, id: null });
  };
  // DocumentTypes
  const getDocumentTypes = async ({ search, id }) => {
    setDocumentTypeLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `document_types`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setDocumentTypeLists(tmpLists);
    }
    setDocumentTypeLoading(false);
  };
  const onSearchDocumentTypes = async (search) => {
    await getDocumentTypes({ search, id: null });
  };

  const onChangeStatus = (isChecked) => {
    let status = isChecked ? "active" : "warning";
    props.setFieldValue("document_file.status", status);
  };
  const getDefaultValueSatus = () => {
    let status = _.get(values, "document_file.status");
    if (status == "active") {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  const initFunction = async () => {
    let promiseDep = getDepartments({ search: "", id: null });
    let promiseDocType = getDocumentTypes({ search: "", id: null });
    await Promise.all([promiseDep, promiseDocType]);
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    departmentLists,
    documentTypeLists,
    departmentLoading,
    documentTypeLoading,

    onSearchDepartments,
    onSearchDocumentTypes,
    getFileThumnail,
    onChangeStatus,
    getDefaultValueSatus,
  };
};
