export interface IValues {
  user_id?: any;
  old_password?: string;
  new_password?: string;
  confirm_password?: string;
}

const proto: IValues = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};
export default proto;
