import React from "react";
import { useSelector } from "react-redux";
import { selector as selectorAuth } from "app/redux/auth";
import { selector as selectorUsers } from "app/redux/users";
import { selector as selectorPermissions } from "app/redux/permissions";
import { getProfile } from "app/req/auth";
import {
  getDetail as getDetailUser,
  patch as patchUsers,
  postRoles as postUserRoles,
  changePass as postChangePass,
} from "app/req/users";
import { get as getPermissions } from "app/req/permissions";
import { get as getRoles } from "app/req/roles";
import _ from "lodash";
import { message } from "antd";
import { API_HOST } from "app/utils/consts";
import { camelCaseSpaces } from "app/utils/functions";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

type TParam = { id: any; push: any };
type TDetailProps = {
  name: string;
  image_url: any;
  email: any;
  telp: any;
  gender: any;
  role: any;
  roles: any;
  lastLoginFromNow: any;
  lastLoginTooltip: any;
  tooltipRoles: any;
};

export default (parameter: TParam) => {
  const { id, push } = parameter;
  const dataAuth = useSelector(selectorAuth);
  const dataUsers = useSelector(selectorUsers);
  const dataCurrentUser = _.get(dataUsers, "records[0]");
  const dataPermissions = useSelector(selectorPermissions);
  const dataUsersPermissions = _.get(dataCurrentUser, "permissionsObj");
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [visibleAssign, setVisibleAssign] = React.useState(false);
  const [visiblePass, setVisiblePass] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  let isCurrent = false;
  let detailProps: TDetailProps;
  let tooltipRoles = () => "--";
  const idDataAuth = _.get(dataAuth, "record.id");
  const idDataUsers = _.get(dataCurrentUser, "id");
  if (idDataAuth == idDataUsers) isCurrent = true;
  // Function
  const initial = async () => {
    const promiseUsers = await getDetailUser(id);
    const promisePermissions = await getPermissions();
    const promiseRoles = await getRoles();
    await Promise.all([promiseUsers, promisePermissions, promiseRoles]);
    setIsLoading(false);
  };
  const editAction = async (values) => {
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    let res = await patchUsers(id, valuesFormData);
    if (_.get(res, "status") == "success") {
      message.success(`Success Edit User`);
      if (res.data.id == idDataAuth) getProfile();
      getDetailUser(id);
    }
  };
  const assignRoleAction = async (values) => {
    const resUserRoles = await postUserRoles(id, values);
    if (_.get(resUserRoles, "status")) {
      await getDetailUser(id);
      message.success("Success Edit Roles User");
    }
  };
  const changePassAction = async (values) => {
    const resUserRoles = await postChangePass(id, values);
    if (_.get(resUserRoles, "status") == "success") {
      await getDetailUser(id);
      message.success("Success Edit Password");
    }
  };
  const initDetailProps = () => {
    const name = _.get(dataCurrentUser, "name") || "--";
    let image_url = _.get(dataCurrentUser, "image_url") || false;
    const randomValue = Math.floor(Math.random() * 101);
    if (image_url) {
      image_url = `${API_HOST}/storage${image_url}?dummy=${randomValue}`;
    } else {
      image_url = "https://robohash.org/5.png?set=set2";
    }
    const email = _.get(dataCurrentUser, "email") || "--";
    const telp = _.get(dataCurrentUser, "telp") || "--";
    let gender = _.get(dataCurrentUser, "gender") || "--";
    gender = camelCaseSpaces(gender);
    let role = _.get(dataCurrentUser, "roles[0]");
    if (role) {
      if (_.isString(role)) {
        role = camelCaseSpaces(role);
      } else role = "--";
    } else role = "--";
    const roles = _.get(dataCurrentUser, "roles") || [];
    tooltipRoles = () =>
      roles.map((item) => <span className="mr5 ml5">{item}</span>);
    const lastLoginAt = _.get(dataCurrentUser, "last_login_at") || false;
    let lastLoginFromNow = "--";
    let lastLoginTooltip = "--";
    if (lastLoginAt) {
      lastLoginFromNow = dayjs(lastLoginAt).fromNow();
      lastLoginTooltip = `${dayjs(lastLoginAt).format("DD MMMM YYYY HH:mm")} WIB`;
    }
    const tmpDetailProps: TDetailProps = {
      name,
      image_url,
      email,
      telp,
      gender,
      role,
      lastLoginFromNow,
      lastLoginTooltip,
      roles,
      tooltipRoles,
    };
    detailProps = { ...tmpDetailProps };
  };
  initDetailProps();

  return {
    // Function
    initial,
    editAction,
    assignRoleAction,
    changePassAction,
    // State
    isLoading,
    visibleEdit,
    visibleAssign,
    visiblePass,
    setVisibleEdit,
    setVisibleAssign,
    setVisiblePass,
    setIsLoading,
    // Data Redux
    dataAuth,
    dataCurrentUser,
    dataPermissions,
    // Data Non Rendering
    isCurrent,
    dataUsersPermissions,
    detailProps,
  };
};
