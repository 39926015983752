import React from "react";
import { Menu, Dropdown, Button } from "antd";
import dayjs from "dayjs";
import { CgShortcut } from "react-icons/cg";

type TProps = {
  onChangeDate: any;
};

const Index: React.FC<TProps> = (props: TProps) => {
  let firstDate: any = dayjs().format("YYYY-MM-DD");
  let lastDate: any = [];
  lastDate.push(dayjs().add(5, "week").format("YYYY-MM-DD"));
  lastDate.push(dayjs().add(4, "week").format("YYYY-MM-DD"));
  lastDate.push(dayjs().add(3, "week").format("YYYY-MM-DD"));
  lastDate.push(dayjs().add(2, "week").format("YYYY-MM-DD"));
  lastDate.push(dayjs().add(1, "week").format("YYYY-MM-DD"));
  const onClickMenu = (menu = 0) => {
    const toDateNow = dayjs().format("YYYY-MM-DD");
    if (menu == 5) {
      firstDate = dayjs("1000-01-01", "YYYY-MM-DD").format("YYYY-MM-DD");
      props.onChangeDate(firstDate, toDateNow);
    } else {
      props.onChangeDate(firstDate, lastDate[menu]);
    }
  };
  const menu = (
    <Menu>
      <Menu.Item onClick={() => onClickMenu(0)}>
        <span>5 Pekan Kedepan</span>
      </Menu.Item>
      <Menu.Item onClick={() => onClickMenu(1)}>
        <span>4 Pekan Kedepan</span>
      </Menu.Item>
      <Menu.Item onClick={() => onClickMenu(2)}>
        <span>3 Pekan Kedepan</span>
      </Menu.Item>
      <Menu.Item onClick={() => onClickMenu(3)}>
        <span>2 Pekan Kedepan</span>
      </Menu.Item>
      <Menu.Item onClick={() => onClickMenu(4)}>
        <span>1 Pekan Kedepan</span>
      </Menu.Item>
      <Menu.Item onClick={() => onClickMenu(5)}>
        <span>Yang Terlewat</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}>
      <Button icon={<CgShortcut />}>Pilih Cepat</Button>
    </Dropdown>
  );
};
export default Index;
