import _ from "lodash";
import { message } from "antd";
import { camelCaseSpaces } from "app/utils/functions";
export const errMsg = (res) => {
  const isStrMsg = typeof res.data == "string";
  if (isStrMsg) {
    message.error(res.data);
  } else {
    const tmpMsg = _.get(res, "data[0].message") || "unknown error";
    message.error(tmpMsg);
  }
};
