import dayjs from "dayjs";
export interface IValues {
  brand_id?: any;
  pic_id?: any;
  wheel_id?: any;
  vehicle_model_id?: any;
  owner_id?: any;
  name?: string;
  color?: string;
  price?: any;
  description?: string;
  show_owner?: boolean;
  plate?: {
    name?: string;
    location_code?: string;
    plate_number?: string;
    stnk_number?: string;
    machine_number?: string;
    register_number?: string;
    bpkb_number?: string;
    position_status?: any;
    position_bpkb?: any;
    type?: string;
    production_year?: any;
    fuel?: string;
  };
  plate_tax?: {
    expired_date?: any;
    plate_expired_month?: any;
    status?: string;
  };
  rent_vehicle?: {
    contract_number?: any;
    price?: any;
    start?: any;
    end?: any;
  };
  location?: {
    location_id?: any;
    pic_name?: any;
    pic_telp?: any;
  };
  kir?: {
    number?: any;
    expired_date?: any;
  };
  insurance?: {
    vendor_id?: any;
    number?: any;
    expired_date?: any;
  };
  photo?: any;
}

const proto: IValues = {
  show_owner: false
};
export default proto;
