import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, patch, getDetail } from "app/req/vehicles";
import req from "app/utils/http_client_query_builder";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import { TDetailVehicle } from "../_components/type";

export default () => {
  const { push } = useHistory();
  const { id } = useParams<any>();
  const [loading, setLoading] = React.useState(true);

  const initFunction = async () => {
    setLoading(true);
    await getDetail(id);
    setLoading(false);
  };
  React.useEffect(() => {
    initFunction();
  }, []);

  return {
    loading,
  };
};
