import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { message, Modal } from "antd";
import Form from "./form_set_payed";
import { IValues } from "./form_set_payed/form_proto";
import { removeEmpty } from "app/utils/functions";
import { post as postBillingPays } from "app/req/billing_pays";
import {
  get as getBillings,
  del as delBillings,
  patch as patchBillings,
} from "app/req/billings";
import { get as getBillingPay } from "app/req/billing_pays";
import { useParams } from "react-router-dom";

dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
  onSubmit: any;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const pageParams = useParams<any>();
  const { setVisible, visible } = props;
  const setStatusAction = async (values) => {
    values = removeEmpty(values);
    const valuesBillingPay = {
      ...values.billing_pay,
      status: "active",
    };
    let valuesFormData = new FormData();
    for (let key in valuesBillingPay)
      valuesFormData.append(`billing_pay[${key}]`, valuesBillingPay[key]);
    const res = await patchBillings(values.billing_id, valuesFormData);
    if (res) {
      message.success(`Success Set Status Pay To Payed`);
    }
  };

  const onSubmit = async (values: IValues) => {
    await setStatusAction(values);
    await props.onSubmit();
    await setVisible(false);
  };
  return (
    <Modal
      title="Form Modal - Set Sudah Dibayar"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 25 }}
    >
      <Form onSubmit={onSubmit} initData={props.initData} />
    </Modal>
  );
};
export default Index;
