export interface IValues {
  id?: string;
  number?: any;
  effective_date?: any;
  expired_date?: any;
  pdf_file?: any;
}

const proto: IValues = {};
export default proto;
