import React from "react";
import MyFormik, { IProps, IState } from "app/_components/formik";
import initDataProto, { IValues } from "./form_proto";
import dayjs from "dayjs";
import CompForm from "./comp_form";
import _ from "lodash";
import { valid } from "./form_validator";
import { removeEmpty } from "app/utils/functions";

interface MyProps extends IProps {
  initData?: IValues;
}
interface MyState extends IState {
  initData: IValues;
}

class Index extends MyFormik<MyProps, MyState> {
  static getDerivedStateFromProps(nextProps: MyProps, prevState: MyState) {
    const hasUpdateInitData = nextProps.initData !== prevState.initData;
    if (hasUpdateInitData) {
      const newValue = nextProps.initData;
      if (!_.isUndefined(newValue)) {
        return {
          initData: newValue,
        };
      }
      return null;
    }
    return null;
  }
  protected getChildren() {
    return CompForm;
  }
  protected getValidator() {
    return valid;
  }
  protected getInitData() {
    const { initData } = this.props;
    let result = initDataProto;
    if (!_.isEmpty(initData)) result = initData;
    return result;
  }
}

const IndexConverted: React.FC<MyProps> = (props: MyProps) => {
  const [initDataState, setInitDataState] = React.useState<IValues>(
    props.initData
  );
  const onSubmit = async (values) => {
    let tmpValues: any = {
      id: values?.id,
      header_meta: values?.header_meta,
      isEdit: values?.isEdit,
    };
    let expired_date = dayjs(values.expired_date).format("YYYY-MM-DD");
    let plate_expired_month = null;
    if (values?.plate_expired_month) {
      plate_expired_month = dayjs(values.plate_expired_month).format("YYYY-MM");
    }
    // let position_expired_date = dayjs(values.position.position_date).format(
    //   "YYYY-MM-DD"
    // );
    // let position = {
    //   position_date: position_expired_date,
    //   position_status_id: values?.position.position_status_id,
    // };
    tmpValues = {
      ...tmpValues,
      expired_date,
      // position,
      file: values.file,
    };
    if (plate_expired_month) {
      tmpValues = {
        ...tmpValues,
        plate_expired_month,
      };
    }
    await props.onSubmit(tmpValues);
  };
  const initFunction = async () => {
    await setInitDataState(props.initData);
  };
  React.useEffect(() => {
    initFunction();
  }, [props.initData]);
  return <Index onSubmit={onSubmit} initData={initDataState} />;
};

export default IndexConverted;
