import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";
import _ from "lodash";
import React from "react";

export default (props) => {
  const [vendors, setVendors] = React.useState<any>([]);
  const [statusPays] = React.useState<any>([
    { label: "Belum bayar", value: "not_payed" },
    { label: "Sudah bayar", value: "payed" },
  ]);
  const [loadingFilter, setloadingFilter] = React.useState<any>(true);
  // Utils AutoCopmlete
  const getParams = () => {
    let myParams: any = {
      limit: 99999,
    };
    return myParams;
  };
  const getConvertLists = (datas) => {
    return convertToSelect({
      datas: datas,
      labelName: "name",
      valueName: "id",
    });
  };
  // Get Request
  const getVendors = async () => {
    let endpoint = `vendors`;
    const res = await req.get({ endpoint, params: getParams() });
    if (res.status == "success") {
      await setVendors(getConvertLists(res.data));
    }
  };

  const initFunction = async () => {
    await setloadingFilter(true);
    await Promise.all([getVendors()]);
    await setloadingFilter(false);
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    vendors,
    statusPays,
    loadingFilter,
  };
};
