import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, post } from "app/req/vendors";
import _ from "lodash";

export default () => {
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [search, setSearch] = React.useState("");
  getAuthorize("vendors", "show");
  const addAction = async (values) => {
    let vendorsNoBank = {
      ...values,
    };
    delete vendorsNoBank.vendor_bank;
    let vendorBank = {
      ...values.vendor_bank,
    };

    let valuesFormData = new FormData();
    for (let key in vendorsNoBank)
      valuesFormData.append(key, vendorsNoBank[key]);
    for (let key in vendorBank)
      valuesFormData.append(`vendor_bank[${key}]`, vendorBank[key]);
    const res = await post(valuesFormData);
    if (res) {
      message.success("Success Add Data");
      await get();
    }
  };

  return {
    visibleAdd,
    setVisibleAdd,
    visibleEdit,
    setVisibleEdit,
    search,
    setSearch,
    addAction,
  };
};
