import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Alert, Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, handleSubmit } = props;
  const { getFileThumnail, isPayed } = useLogic(props);
  const { evidencePdfThumbnail } = getFileThumnail();
  const MessageAlert = (
    <span>
      Data yang anda pilih
      <span className="text-bold ml5 mr5">Sudah</span>
      melakukan pembayaran, silakan lanjutkan jika ingin mengubah datanya.
    </span>
  );
  return (
    <Form {...formLayout}>
      <Row align="middle">
        {isPayed && (
          <Alert
            message={MessageAlert}
            type="warning"
            showIcon
            className="mb25"
          />
        )}
        <Col span={24}>
          <Field name="tmpBillingLookup.title">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Tagihan",
                  labelAlign: "left",
                }}
                disabled
                type="AntInput"
                placeholder="Ex: 2020-02-02"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="billing_pay.pay_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tgl. Bayar",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-02-02"
              />
            )}
          </Field>
        </Col>

        <Col span={24} className="mb5">
          <Col span={24} className="mb5">
            <UploadFile
              setFile={(file) =>
                setFieldValue("billing_pay.evidence_file", file)
              }
              initDataName={evidencePdfThumbnail}
              label="Bukti File"
              extLists={["pdf", "jpg", "png", "jpeg"]}
              invalidMessage="You can upload only Document / Image (pdf,jpg,png,jpeg) File !"
            />
          </Col>
        </Col>
        <Col span={24}>
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
