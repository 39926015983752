import React from "react";
import { query } from "js-query-builder";
import { getHeader } from "app/utils/http_client_query_builder";
import axios from "axios";
import { API_URL } from "app/utils/consts";
import { useParams, useHistory } from "react-router-dom";

export default (props) => {
  const { id } = useParams<any>();
  const { push } = useHistory();
  const [loadingPrint, setLoadingPrint] = React.useState(false);
  const downloadPdf = async () => {
    setLoadingPrint(true);
    const FileDownload = require("js-file-download");
    await axios({
      url: `${API_URL}/vehicles/export_pdf/${id}`,
      method: "GET",
      responseType: "blob", // Important
      headers: getHeader(),
      withCredentials: true,
    })
      .then((response) => {
        FileDownload(response.data, `vehicleDetail-${id}.pdf`);
      })
      .finally(() => setLoadingPrint(false));
  };
  const redirectToEdit = () => {
    push(`/vehicles/edit/${id}`);
  };
  return {
    downloadPdf,
    loadingPrint,
    redirectToEdit,
  };
};
