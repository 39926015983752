import React from "react";
import Layout from "app/_components/layout/private";
import Component from "./_components";
import { Col, Row } from "antd";

const Index: React.FC<any> = () => {
  return (
    <Layout>
      <div className="content_wrapper">
        <Component />
      </div>
    </Layout>
  );
};
export default Index;
