import { API_HOST } from "app/utils/consts";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";
import _ from "lodash";
import React from "react";

const getParams = () => {
  let myParams: any = {
    limit: 99999,
  };
  return myParams;
};

const getConvertLists = (datas) => {
  return convertToSelect({
    datas: datas,
    labelName: "name",
    valueName: "id",
  });
};

const optionLocations = async (setterToState) => {
  let endpoint = `locations`;
  const res = await req.get({ endpoint, params: getParams() });
  if (res.status == "success") {
    await setterToState(getConvertLists(res.data));
  }
};

const Index = (props) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [loadingFilter, setloadingFilter] = React.useState<any>(true);
  const [locations, setLocations] = React.useState<any>([]);

  const { isSubmitting, setFieldValue, values, isValid } = props;
  const handleSubmit = (valuesSubmit) => {
    let tmpTelp = values.telp_postfix;
    if (isValid) {
      setFieldValue("telp", `+62-${tmpTelp}`);
    }
    props.handleSubmit(valuesSubmit);
  };
  let avatar = _.get(values, "image_url");
  if (avatar) {
    avatar = `${API_HOST}/storage${avatar}`;
  }
  const onChangeNotifiable = (isChecked) => {
    props.setFieldValue("is_notifiable", "1");
    if (!isChecked) {
      props.setFieldValue("is_notifiable", "0");
    }
  };
  const getDefaultValueIsNotifiable = () => {
    let checked = _.get(values, "is_notifiable");
    if (checked == "1") {
      checked = true;
    } else {
      checked = false;
    }
    return checked;
  };
  const onChangeEditEmail = (e) => {
    setFieldValue("editEmail", e);
  };

  const initFunction = async () => {
    await setloadingFilter(true);
    if (values.email !== "") {
      await setFieldValue("editEmail", false);
      await setIsEdit(true);
    }
    let tmpTelp: any = _.get(values, "telp");
    if (tmpTelp) {
      tmpTelp = tmpTelp.split("-");
      await setFieldValue("telp_postfix", tmpTelp[1]);
    }
    // For Notif
    let checked = _.get(values, "is_notifiable");
    if (checked) {
      await props.setFieldValue("is_notifiable", "1");
    } else {
      await props.setFieldValue("is_notifiable", "0");
    }
    await Promise.all([optionLocations(setLocations)]);
    await setloadingFilter(false);
  };

  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    getDefaultValueIsNotifiable,
    onChangeNotifiable,
    handleSubmit,
    isEdit,
    isSubmitting,
    avatar,
    onChangeEditEmail,
    locations,
    loadingFilter,
  };
};
export default Index;
