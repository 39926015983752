import React from "react";
import { get, setFilter } from "app/req/billing_pays";
import { useParams } from "react-router";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";

type TRangePicker = [Dayjs, Dayjs];
export default (props) => {
  const { id } = useParams<any>();
  const [date, setDate] = React.useState<TRangePicker>([null, null]);
  const getDatas = async (firstDate = null, lastDate = null) => {
    const betweenExpiredDate = `${firstDate},${lastDate}`;
    let params = null;
    if (firstDate != null && lastDate != null) {
      params = {
        filter: {
          between_expired_date: betweenExpiredDate,
        },
      };
    }
    await setFilter(params.filter);
    await get(id);
  };
  const onClear = async () => {
    const filter = {
      between_expired_date: "",
    };
    setDate([null, null]);
    await setFilter(filter);
    await get(id);
  };
  const onChangeFilter = (value: TRangePicker) => {
    setDate(value);
    let firstDate = _.get(value, "[0]") || null;
    let lastDate = _.get(value, "[1]") || null;
    if (firstDate) firstDate = value[0].format("YYYY-MM-DD");
    if (lastDate) lastDate = value[1].format("YYYY-MM-DD");
    getDatas(firstDate, lastDate);
  };
  const onChangeDate = (firstDate = null, lastDate = null) => {
    getDatas(firstDate, lastDate);
  };
  return {
    date,
    onChangeDate,
    onChangeFilter,
    onClear,
  };
};
