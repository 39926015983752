import React from "react";
import { Breadcrumb } from "antd";
import { useHistory, Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { camelCaseSpaces } from "app/utils/functions";
import s from "./index.module.scss";

const Index: React.FC<any> = () => {
  const { location } = useHistory();
  const { pathname } = location;
  let parentPath: any = "";
  if (pathname != "/") {
    parentPath = pathname.split("/");
    parentPath = parentPath[1];
  } else {
    parentPath = "dashboard";
  }
  parentPath = camelCaseSpaces(parentPath);
  return (
    <div className={s.breadcrumb}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">
            <FaHome className={s.icon} />
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{parentPath}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};
export default Index;
