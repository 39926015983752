import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import dayjs from "dayjs";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, handleSubmit } = props;
  const {
    onSearchLocations,
    locationsLoading,
    locationsLists,
    prevLocation,
    filePdfThumbnail,
  } = useLogic(props);
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Row className="mb15" style={{ marginTop: "-15px" }}>
            <div>
              <span className="text-bold">Catatan : </span>
              <span className="text-red">Mohon diperhatikan !</span>
              <p className="text-muted text-xs">
                Setelah melakukan perpindahan posisi lokasi lama akan
                diarsipkan.
              </p>
            </div>
          </Row>
        </Col>
        <Col span={24} className="mb15">
          <Row>
            <Col span={10}>Lokasi Saat Ini</Col>
            <Col span={14}>{prevLocation}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Field name="location_id">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Lokasi",
                  labelAlign: "left",
                  loading: locationsLoading,
                }}
                type="AntSelect"
                placeholder="Ketik Untuk Mencari Item"
                selectOptions={locationsLists}
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="movement_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tanggal Perpindahan",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-01-01"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="driver_name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Pemakai",
                  labelAlign: "left",
                  noReq: true,
                }}
                type="AntInput"
                placeholder="Ex: Jhon"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="pic_name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama PIC",
                  labelAlign: "left",
                  noReq: true,
                }}
                type="AntInput"
                placeholder="Ex: Jajangs"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Row>
            <Col xs={24} lg={10}>
              <span>Telp. PIC</span>
            </Col>
            <Col xs={24} lg={14}>
              <Field name="pic_telp">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    type="AntInput"
                    placeholder="Ex: 82216829353"
                    addonBefore="+62"
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="mb5">
          <UploadFile
            setFile={(file) => setFieldValue("file", file)}
            initDataName={filePdfThumbnail}
            label="File Dok."
            extLists={[
              "pdf",
              "jpg",
              "jpeg",
              "png",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
              "doc",
              "docx",
            ]}
            invalidMessage="You can upload only Document/Image/PPt/Xls File !"
          />
        </Col>

        <Col span={24}>
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
