export interface IValues {
  id?: string;
  header_meta?: {
    vehicle_id: string;
    name_vehicle: string;
    type_vehicle: string;
  };
  name?: any;
  description?: any;
  file?: any;
}

const proto: IValues = {};
export default proto;
