import { Table, message, Row, Col, Card, Spin, Button } from "antd";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DatePicker from "app/_components/ant_design/picker_dayjs/datepicker";
import _ from "lodash";
import useLogic from "./_logic";
import {
  AiFillCloseCircle,
  AiOutlineClockCircle,
  AiOutlineCloseCircle,
  AiOutlineFilter,
} from "react-icons/ai";
import Dropdown from "./dropdown";

dayjs.extend(relativeTime);

const { RangePicker } = DatePicker;

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const { date, onChangeFilter, onChangeDate, onClear } = useLogic(props);
  return (
    <div>
      <span>Rentang Jatuh Tempo</span>
      <RangePicker
        ranges={{
          "Bulan Sekarang": [dayjs().startOf("month"), dayjs().endOf("month")],
        }}
        value={date}
        onChange={onChangeFilter}
        className="mr10 ml10"
      />
      <Button
        icon={<AiOutlineCloseCircle />}
        onClick={onClear}
        className="ml10"
      >
        Clear
      </Button>
    </div>
  );
};
export default Index;
