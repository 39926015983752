import * as Yup from "yup";

export const valid = Yup.object().shape({
  // document_type_id: Yup.string().required("Is Required !"),
  // institution: Yup.string().required("Is Required !"),
  // title: Yup.string().required("Is Required !"),
  document_file: Yup.object({
    number: Yup.string().required("Is Required !"),
    effective_date: Yup.string().nullable().required("Is Required !"),
    expired_date: Yup.string().nullable().required("Is Required !"),
    pdf_file: Yup.mixed().nullable().required("Is Required !"),
  }),
});
