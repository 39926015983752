import * as layout from "./form_layout";
import { Button, Col, Form, Row, Checkbox, Tooltip } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import { FiLock, FiMail } from "react-icons/fi";
import AntFields from "app/_components/ant_design/ant_custom";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import { FiLogOut } from "react-icons/fi";
import useLogic from "./_logic";
import s from "./index.module.scss";
import { IoIosLogIn } from "react-icons/io";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { handleSignOut } = useLogic(props);
  const { handleSubmit, isSubmitting, values, setFieldValue } = props;
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Field name="password">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Password",
                  noReq: true,
                  labelAlign: "left",
                }}
                prefix={<FiLock />}
                type="AntInputPassword"
                placeholder="Ex: P4ssw0rds_or_secr3t"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mt25">
          <Row align="middle">
            <Col span={18}>
              <Row justify="end" align="middle">
                <Button
                  onClick={handleSubmit as any}
                  loading={isSubmitting}
                  block
                  type="primary"
                  htmlType="submit"
                  icon={<IoIosLogIn />}
                >
                  Masuk Kembali
                </Button>
              </Row>
            </Col>
            <Col span={6}>
              <Row
                align="middle"
                justify="space-between"
                className={s.iconGooglePlus}
              >
                <p className="ml15 text-muted">Atau</p>
                <Tooltip title="Keluarkan Akun">
                  <FiLogOut onClick={handleSignOut} />
                </Tooltip>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
