import _ from "lodash";
import dayjs from "dayjs";
import { IValues } from "./form_edit/form_proto";

const adapterToEditForm = (dirtyData): IValues => {
  // init ConvertToString
  let telp = _.get(dirtyData, "telp");
  // if (telp) {
  //   const tmpTelp = telp.split("-");
  //   telp = tmpTelp[1];
  // }

  const dataIValues: IValues = {
    id: _.get(dirtyData, "id"),
    name: _.get(dirtyData, "name"),
    telp,
    address: _.get(dirtyData, "address"),
  };
  return dataIValues;
};
export { adapterToEditForm };
