import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";

export default (props) => {
  const { values } = props;
  let isPayed =
    _.get(values, "tmpBillingLookup.billing_pay_active.status") || false;
  if (isPayed == "active") {
    isPayed = true;
  } else {
    isPayed = false;
  }
  const getFileThumnail = (): {
    evidencePdfThumbnail: any;
  } => {
    let evidencePdf: any = _.get(values, "billing_pay.evidence_file");
    const checkFile = (tmpCheckFile) => {
      if (tmpCheckFile) {
        if (typeof tmpCheckFile == "object") {
          tmpCheckFile = tmpCheckFile?.name;
        } else if (tmpCheckFile != "" || tmpCheckFile != "empty") {
          tmpCheckFile = tmpCheckFile;
        } else {
          tmpCheckFile = tmpCheckFile ? tmpCheckFile : "--";
        }
      }
      return tmpCheckFile;
    };
    evidencePdf = checkFile(evidencePdf);

    return {
      evidencePdfThumbnail: evidencePdf,
    };
  };

  return {
    getFileThumnail,
    isPayed,
  };
};
