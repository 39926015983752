const convertMenuLists = [
  { en: "Users", id: "Pengguna" },
  { en: "Roles", id: "Akses Grup" },
  { en: "Brands", id: "Merek" },
  { en: "Vehicle Models", id: "Model Kendaraan" },
  { en: "Vehicles", id: "Kendaraan" },
  { en: "Departments", id: "Departemen" },
  { en: "Document Types", id: "Tipe Dokumen" },
  { en: "Documents", id: "Dokumen" },
  { en: "Billings", id: "Tagihan" },
  { en: "Vendors", id: "Vendor" },
  { en: "Locations", id: "Lokasi" },
];
const convertPermissionLists = [
  { en: "Show", id: "Lihat" },
  { en: "Create", id: "Tambah" },
  { en: "Update", id: "Ubah" },
  { en: "Delete", id: "Hapus" },
  { en: "Self", id: "Kelola Sendiri" },
];
export { convertMenuLists, convertPermissionLists };
