import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Divider, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import dayjs from "dayjs";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, handleSubmit } = props;
  const {
    onSearchVendors,
    vendorsLoading,
    vendorsLists,
    filePdfThumbnail,
  } = useLogic(props);
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Field name="vendor_id">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Perusahaan",
                  labelAlign: "left",
                  loading: vendorsLoading,
                }}
                type="AntSelect"
                placeholder="Ketik Untuk Mencari Item"
                selectOptions={vendorsLists}
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="number">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nomor Polis",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: 948295928491"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="expired_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tgl. Masa Berlaku",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-01-01"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mb5">
          <UploadFile
            setFile={(file) => setFieldValue("file", file)}
            initDataName={filePdfThumbnail}
            label="File Dok."
            extLists={[
              "pdf",
              "jpg",
              "jpeg",
              "png",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
              "doc",
              "docx",
            ]}
            invalidMessage="You can upload only Document/Image/PPt/Xls File !"
          />
        </Col>

        <Col span={24}>
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
