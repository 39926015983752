import React from "react";
import {
  convertMenuLists,
  convertPermissionLists,
} from "app/pages/private/roles/roles/_components/const";
import { useParams } from "react-router";
import Req from "app/utils/http_client_query_builder";
import { message } from "antd";

export default (props) => {
  const { id } = useParams<any>();
  const [loadingSendEmail, setLoadingSendEmail] = React.useState(false);
  const onClickSendEmailTest = async () => {
    await setLoadingSendEmail(true);
    const res = await Req.post({ endpoint: `send_email_test/${id}` });
    if (res.status == "success") {
      message.success("Has Success Sended To Email !");
    } else {
      message.error(res.data);
    }
    await setLoadingSendEmail(false);
  };
  const convertMenusToId = (en) => {
    return convertMenuLists.find((x) => x.en === en).id;
  };
  const convertPermissionsToId = (en) => {
    return convertPermissionLists.find((x) => x.en === en).id;
  };
  return {
    loadingSendEmail,
    convertMenusToId,
    convertPermissionsToId,
    onClickSendEmailTest,
  };
};
