import React from "react";
import { Row, Col, Card } from "antd";
import s from "./index.module.scss";
import { AiOutlineBank, AiOutlineCar } from "react-icons/ai";
import { AiOutlineFolder } from "react-icons/ai";
import { TiDocumentText } from "react-icons/ti";
import { Link, useHistory } from "react-router-dom";
import useLogic from "./_logic";

const Index: React.FC<any> = () => {
  const {
    totalBillings,
    totalDocuments,
    totalVehicles,
    totalVendors,
  } = useLogic();
  const { push } = useHistory();
  return (
    <Row justify="space-between" gutter={[15, 15]}>
      <Col
        xs={24}
        xl={6}
        className={s.cardWrapper}
        onClick={() => push(`/vehicles`)}
      >
        <Card>
          <Row align="middle" className={s.cardTotal}>
            <span className={`${s.iconWrapper} ${s.blue}`}>
              <AiOutlineCar />
            </span>
            <div>
              <p className="text-lg text-bold">{totalVehicles}</p>
              <p className="text-primary">Total Kendaraan</p>
            </div>
          </Row>
        </Card>
      </Col>
      <Col
        xs={24}
        xl={6}
        className={s.cardWrapper}
        onClick={() => push(`/billings`)}
      >
        <Card>
          <Row align="middle" className={s.cardTotal}>
            <span className={s.iconWrapper}>
              <TiDocumentText />
            </span>
            <div>
              <p className="text-lg text-bold">{totalBillings}</p>
              <p className="text-primary">Total Tagihan</p>
            </div>
          </Row>
        </Card>
      </Col>
      <Col
        xs={24}
        xl={6}
        className={s.cardWrapper}
        onClick={() => push(`/documents`)}
      >
        <Card>
          <Row align="middle" className={s.cardTotal}>
            <span className={`${s.iconWrapper} ${s.yellow}`}>
              <AiOutlineFolder />
            </span>
            <div>
              <p className="text-lg text-bold">{totalDocuments}</p>
              <p className="text-primary">Total Dokumen</p>
            </div>
          </Row>
        </Card>
      </Col>
      <Col
        xs={24}
        xl={6}
        className={s.cardWrapper}
        onClick={() => push(`/vendors`)}
      >
        <Card>
          <Row align="middle" className={s.cardTotal}>
            <span className={`${s.iconWrapper} ${s.blue}`}>
              <AiOutlineBank />
            </span>
            <div>
              <p className="text-lg text-bold">{totalVendors}</p>
              <p className="text-primary">Total Vendor</p>
            </div>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default Index;
