import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { message, Modal } from "antd";
import Form from "./form_edit";
import { IValues } from "./form_edit/form_proto";
import { removeEmpty } from "app/utils/functions";
import {
  post as postBillingPays,
  patch as patchBillingPays,
} from "app/req/billing_pays";
import { get as getBillingPay } from "app/req/billing_pays";
import { useParams } from "react-router-dom";

dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
  onSubmit?: any;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible } = props;
  const localAdapter = (paramValue) => {
    return {
      id: paramValue?.id,
      number: paramValue?.number,
      price: paramValue?.price,
      invoice_file: paramValue?.invoice_url,
      efaktur_file: paramValue?.efaktur_url,
      periode_from: dayjs(paramValue.periode_from),
      periode_to: dayjs(paramValue.periode_to),
      expired_date: dayjs(paramValue.expired_date),
      invoice_date: dayjs(paramValue.invoice_date),
    };
  };
  const localAction = async (values) => {
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    const res = await patchBillingPays(values.id, valuesFormData);
    if (res) {
      message.success(`Success Edit Data History`);
    }
  };
  const onSubmit = async (values: IValues) => {
    await localAction(values);
    setVisible(false);
    await props?.onSubmit();
  };
  let initData: any = props.initData;
  if (props?.initData) initData = localAdapter(props.initData);
  return (
    <Modal
      title="Form Modal - Ubah Tagihan (History)"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 30 }}
    >
      <Form onSubmit={onSubmit} initData={initData} />
    </Modal>
  );
};
export default Index;
