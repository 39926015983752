import "dayjs/locale/id";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { Spin } from "antd";
import store from "app/redux/store";
import MyRoutes from "app/router/index";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import { SyncOutlined, ReloadOutlined } from "@ant-design/icons";

dayjs.locale("id");
Spin.setDefaultIndicator(<SyncOutlined spin color="#b72018" />);

const Index: React.FC<any> = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={null}>
        <MyRoutes />
      </Suspense>
    </Provider>
  );
};
export default Index;
