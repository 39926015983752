import React from "react";
import { Row, Col, Card, Spin } from "antd";
import BarChart from "./chart_bar";
import PieChart from "./chart_pie";
import Cards from "./card_list";
import TopCard from "./top_card";
import useLogic from "./_logic";

const Index: React.FC<any> = () => {
  const { loading } = useLogic();
  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <Spin spinning={loading} tip="Mohon Tunggu ...">
          <Row>
            <Col xs={24} xl={24}>
              <TopCard />
            </Col>
          </Row>
          <Cards />
        </Spin>
      </Col>
    </Row>
  );
};
export default Index;
