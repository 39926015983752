import { Table, message, Row, Col, Card, Spin, Button } from "antd";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DatePicker from "app/_components/ant_design/picker_dayjs/datepicker";
import _ from "lodash";
import useLogic from "./_logic";
import {
  AiFillCloseCircle,
  AiOutlineClockCircle,
  AiOutlineCloseCircle,
  AiOutlineFilter,
} from "react-icons/ai";
import Dropdown from "app/_components/other/shortcut_filter/dropdown";
import s from "./index.module.scss";

dayjs.extend(relativeTime);

const { RangePicker } = DatePicker;

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    between_expired_date_arr,
    onChangeFilter,
    onChangeDate,
    onClear,
  } = useLogic(props);
  return (
    <div>
      <span>Rentang Jatuh Tempo</span>
      <RangePicker
        value={between_expired_date_arr}
        ranges={{
          "Bulan Sekarang": [dayjs().startOf("month"), dayjs().endOf("month")],
        }}
        onChange={onChangeFilter}
        className={s.rangePicker}
      />
      <Dropdown onChangeDate={onChangeDate} />
      <Button
        icon={<AiOutlineCloseCircle />}
        onClick={onClear}
        className="ml10"
      >
        Clear
      </Button>
    </div>
  );
};
export default Index;
