import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, handleSubmit } = props;
  const {
    departmentLists,
    documentTypeLists,
    departmentLoading,
    documentTypeLoading,

    onSearchDepartments,
    onSearchDocumentTypes,
    getFileThumnail,
    onChangeStatus,
    getDefaultValueSatus,
  } = useLogic(props);
  const { filePdfThumbnail } = getFileThumnail();
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Field name="document_type_id">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tipe Dokumen",
                  labelAlign: "left",
                  loading: documentTypeLoading,
                  noReq: true,
                }}
                type="AntSelect"
                placeholder="Ex: Arsip"
                selectOptions={documentTypeLists}
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="institution">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Lembaga",
                  labelAlign: "left",
                  noReq: true,
                }}
                type="AntInput"
                placeholder="Ex: PT. Amerta Indah"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="title">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Dokumen",
                  labelAlign: "left",
                  noReq: true,
                }}
                type="AntInput"
                placeholder="Ex: Template Absensi Pegawai"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="document_file.number">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "No. Dokumen",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: DOC/USR-1/PBLC/001"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="document_file.effective_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tgl. Dikeluarkan",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-02-02"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="document_file.expired_date">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Tgl. Masa Berlaku",
                  labelAlign: "left",
                }}
                type="AntDatePicker"
                placeholder="Ex: 2020-02-02"
              />
            )}
          </Field>
        </Col>
        {/* <Col span={24} className="mb15">
          <Row>
            <Col span={6}>
              <span>Matikan Notif.</span>
            </Col>
            <Col span={18}>
              <Row>
                <Switch
                  size="small"
                  onChange={onChangeStatus}
                  defaultChecked={getDefaultValueSatus()}
                />
                <div className={s.switchText}>
                  <p className="text-xs">Matikan Notifikasi</p>
                  <p className="text-xs text-muted">
                    Jika anda mengaktifkan ini, notifikasi dokumen yang expired
                    tidak akan terkirim.
                  </p>
                </div>
              </Row>
            </Col>
          </Row>
        </Col> */}
        <Col span={24} className="mb5">
          <UploadFile
            setFile={(file) => setFieldValue("document_file.pdf_file", file)}
            initDataName={filePdfThumbnail}
            label="File Dok."
            extLists={[
              "pdf",
              "jpg",
              "jpeg",
              "png",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
              "doc",
              "docx",
            ]}
            invalidMessage="You can upload only Document/Image/PPt/Xls File !"
            isReq
          />
        </Col>
        <Col span={24}>
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
