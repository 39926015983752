import React from "react";
import { message, notification } from "antd";
import { post } from "app/utils/http_client_query_builder";
import { postForgotPassword } from "app/req/auth";
import { isArray } from "lodash";

export default (props) => {
  const handleSubmit = async (values) => {
    const data = {
      ...values,
    };
    const res = await post({ endpoint: "forgot_password", data });
    if (res.status == "success") {
      notification.success({
        placement: "topRight",
        message: "Success Send Notification !",
        description:
          "Link reset password has sended to email, please check Inbox / Spam Folder email !",
      });
    } else {
      if (!isArray(res.data)) {
        message.error(res.data);
      } else {
        message.error("Data Email has not found !");
      }
    }
  };

  return {
    handleSubmit,
  };
};
