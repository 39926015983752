import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import _ from "lodash";
import useLogic from "./_logic";
import RenewModal from "./renew";
import MovementModal from "./movement";
import DocumentModal from "./document";
import RewnewKirModal from "app/pages/private/vehicles/detail/_components/kir/modal";
import RewnewInsuranceModal from "app/pages/private/vehicles/detail/_components/insurance/modal";

dayjs.extend(relativeTime);

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    // Function
    onChangeTable,
    setVisibleRenew,
    setVisibleMovement,
    setVisibleAddDocument,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    dataFilters,
    visibleRenew,
    visibleMovement,
    visibleAddDocument,
    initData,
    loadingLocal,
    visibleRenewKir,
    setVisibleRenewKir,
    visibleRenewInsurance,
    setVisibleRenewInsurance,
  } = useLogic(props);
  return (
    <React.Fragment>
      <Table
        columns={columns(columnActions, dataFilters)}
        dataSource={datas}
        rowKey={(record) => record.id}
        size="middle"
        showSorterTooltip={false}
        onChange={onChangeTable}
        loading={loading || loadingLocal}
        pagination={{
          ...paginationProps,
          hideOnSinglePage: false,
          showSizeChanger: true,
          showTotal: (total, range) => {
            return (
              <div style={{ position: "absolute", left: 0 }}>
                Daftar mulai {range[0]}-{range[1]} dari {total} Item
              </div>
            );
          },
        }}
        scroll={{ x: "100%" }}
      />
      <RenewModal
        setVisible={setVisibleRenew}
        visible={visibleRenew}
        initData={initData}
      />
      <MovementModal
        setVisible={setVisibleMovement}
        visible={visibleMovement}
        initData={initData}
      />
      <DocumentModal
        setVisible={setVisibleAddDocument}
        visible={visibleAddDocument}
        initData={initData}
      />
      <RewnewKirModal
        setVisible={setVisibleRenewKir}
        visible={visibleRenewKir}
        initData={initData}
      />
      <RewnewInsuranceModal
        setVisible={setVisibleRenewInsurance}
        visible={visibleRenewInsurance}
        initData={initData}
      />
    </React.Fragment>
  );
};
export default Index;
