import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import ModalEdit from "../_components/modal_users/edit_users";
import {
  get as getUsers,
  post as postUsers,
  del as delUsers,
  patch as patchUsers,
} from "app/req/users";
import columns from "./columns";
import { getProfile } from "app/req/auth";
import { selector as selectorAuth } from "app/redux/auth";
import { selector as selectorUsers } from "app/redux/users";

dayjs.extend(relativeTime);

interface IProps {
  searchText: string;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const [loading, setLoading] = React.useState(true);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [initData, setInitData] = React.useState<any>(null);
  const dataAuth = useSelector(selectorAuth).record;
  const dataUsers = useSelector(selectorUsers).records;
  const paginationUsers = useSelector(selectorUsers).pagination;
  const currentPage = _.get(paginationUsers, "current_page");
  const sizePage = _.get(paginationUsers, "per_page");
  const { push } = useHistory();

  const fetchSetUsers = async (params?: any) => {
    const filter = { name: props.searchText };
    params = {
      ...params,
      filter,
    };
    setLoading(true);
    await getUsers(params);
    setLoading(false);
  };
  const editAction = async (values) => {
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    const res = await patchUsers(initData.id, valuesFormData);
    if (_.get(res, "status") == "success") {
      message.success(`Success Edit User`);
      if (res.data.id == dataAuth.id) {
        getProfile();
      }
      fetchSetUsers();
    }
  };
  const editShowModal = async (record) => {
    setVisibleEdit(true);
    setInitData(record);
  };
  const delAction = async (record) => {
    setInitData(null);
    const res = await delUsers(record.id);
    if (_.get(res, "status") == "success") {
      message.success(`Success Delete User ${record.name}`);
      fetchSetUsers();
    }
  };
  const detailAction = async (record) => {
    // setInitData(record);
    push(`/users/${record.id}`);
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetUsers({ page, limit, sort });
  };

  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    const limit = sizePage;
    if (mounted) fetchSetUsers({ page: 1, limit });
    return () => (mounted = false);
    // End Safety
  }, [props.searchText]);
  React.useEffect(() => {
    let mounted = true;
    const limit = sizePage;
    if (mounted) fetchSetUsers({ page: 1, limit });
    return () => (mounted = false);
  }, []);

  return (
    <React.Fragment>
      <Table
        columns={columns({
          detailAction,
          delAction,
          editAction: editShowModal,
        })}
        dataSource={dataUsers}
        rowKey={(record) => record.id}
        size="middle"
        showSorterTooltip={false}
        onChange={onChangeTable}
        loading={loading}
        pagination={{
          defaultPageSize: 1,
          defaultCurrent: 1,
          current: currentPage,
          pageSize: sizePage,
          hideOnSinglePage: false,
          showSizeChanger: true,
          total: _.get(paginationUsers, "total") || 1,
          showTotal: (total, range) => {
            return (
              <div style={{ position: "absolute", left: 0 }}>
                Daftar mulai {range[0]}-{range[1]} dari {total} Item
              </div>
            );
          },
        }}
        scroll={{ x: "100%" }}
      />
      <ModalEdit
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        onSubmit={editAction}
        initData={initData}
      />
    </React.Fragment>
  );
};
export default Index;
