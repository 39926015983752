import { Table, message, Row, Col, Card, Spin, Button } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import ModalRenew from "app/pages/private/vehicles/dashboard/_components/renew";
import ModalPlatePosition from "./modal/plate_position";
import _ from "lodash";
import useLogic from "./_logic";
import Filter from "./filter";
import { AiOutlinePlusCircle } from "react-icons/ai";

dayjs.extend(relativeTime);

interface IProps {}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    // Function
    onChangeTable,
    // State/Const
    dataHeader,
    datas,
    loading,
    paginationProps,
    columnActions,
    initData,
    header_meta,
    visibleRenew,
    setVisibleRenew,
    visibleEditRenew,
    setVisibleEditRenew,
    visiblePlatePosition,
    setVisiblePlatePosition,
    initDataPlatePosition,
    setInitDataPlatePosition,
  } = useLogic(props);
  return (
    <React.Fragment>
      <Spin spinning={loading} tip="Mohon Tunggu ...">
        <Row className="mb25" align="middle" justify="space-between">
          <Filter />
          <Button
            icon={<AiOutlinePlusCircle />}
            className="p-hor"
            onClick={() => setVisibleRenew(true)}
            type="default"
          >
            Perpanjang STNK
          </Button>
        </Row>
        <Table
          columns={columns(columnActions)}
          dataSource={datas}
          rowKey={(record) => record.id}
          size="middle"
          showSorterTooltip={false}
          onChange={onChangeTable}
          pagination={{
            ...paginationProps,
            hideOnSinglePage: false,
            showSizeChanger: true,
            showTotal: (total, range) => {
              return (
                <div style={{ position: "absolute", left: 0 }}>
                  Daftar mulai {range[0]}-{range[1]} dari {total} Item
                </div>
              );
            },
          }}
          scroll={{ x: "100%" }}
        />
      </Spin>
      <ModalRenew
        initData={{ header_meta }}
        setVisible={setVisibleRenew}
        visible={visibleRenew}
      />
      <ModalRenew
        initData={initData}
        setVisible={setVisibleEditRenew}
        visible={visibleEditRenew}
      />
      <ModalPlatePosition
        initData={initDataPlatePosition}
        setVisible={setVisiblePlatePosition}
        visible={visiblePlatePosition}
      />
    </React.Fragment>
  );
};
export default Index;
