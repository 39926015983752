import React from "react";
import { Button, Avatar, Image, Typography, Tag } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";

const { Text } = Typography;

dayjs.extend(relativeTime);

type TAction = {
  editAction: (record: any) => any;
  delAction: (record: any) => any;
};

const columns = (actions: TAction): any => {
  return [
    {
      title: "Bank",
      dataIndex: "name",
      render: (item, record) => {
        return item ? item : "--";
      },
    },
    {
      title: "No. Rek.",
      dataIndex: "number",
      render: (item) => {
        return item ? item : "--";
      },
    },
    {
      title: "Atas Nama",
      dataIndex: "pic_name",
      render: (item) => {
        return item ? item : "--";
      },
    },
    {
      title: "Aksi",
      width: 100,
      align: "center",
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
