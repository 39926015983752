import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeReducer, TypeData } from "./types";
import _ from "lodash";

const initialState: TypeReducer = {
  data: {
    login: false,
    record: null,
    objPermissions: null,
  },
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TypeData>) => {
      const getPayload = _.get(action, "payload") || initialState.data;
      state.data = { ...getPayload };
    },
  },
});
export const { setData } = authSlice.actions;
export default authSlice.reducer;
