import React from "react";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import {
  Row,
  Avatar,
  Image,
  Rate,
  Button,
  Tooltip,
  Col,
  Typography,
} from "antd";
import _ from "lodash";
import {
  FaPhoneSquare,
  FaRegIdBadge,
  FaRegEdit,
  FaEnvelope,
} from "react-icons/fa";
import { getAuthorize } from "app/utils/permissions";

const { Text } = Typography;

export type TDetailProps = {
  name: string;
  image_url: any;
  email: any;
  telp: any;
  gender: any;
  role: any;
  lastLoginFromNow: any;
  lastLoginTooltip: any;
  tooltipRoles: any;
  setVisibleAssign?: (isVisible: boolean) => void;
  setVisibleEdit?: (isVisible: boolean) => void;
  setVisiblePass?: (isVisible: boolean) => void;
};

const Index: React.FC<TDetailProps> = (props: TDetailProps) => {
  const {
    tooltipRoles,
    lastLoginFromNow,
    lastLoginTooltip,
    role,
    gender,
    telp,
    email,
    image_url,
    name,
    setVisibleAssign,
    setVisibleEdit,
    setVisiblePass,
  } = props;
  let isEditRoles = false;
  const isRoleUpdate = getAuthorize("roles", "update", true, true);
  if (isRoleUpdate) isEditRoles = true;
  return (
    <div className={s.topWrapper}>
      <Row justify="space-between">
        <Row>
          <Col xs={0} xl={4}>
            <Avatar src={image_url} />
          </Col>
          <div className={s.content}>
            <Tooltip title={email} mouseEnterDelay={1}>
              <Text ellipsis style={{ width: "150px" }}>
                {name}
              </Text>
            </Tooltip>
            <Tooltip title={email} mouseEnterDelay={1}>
              <div className={s.item}>
                <FaEnvelope />
                <Text ellipsis style={{ width: "150px" }}>
                  {email}
                </Text>
              </div>
            </Tooltip>
            <Tooltip title={telp} mouseEnterDelay={1}>
              <div className={s.item}>
                <FaPhoneSquare />
                <Text style={{ width: "150px" }} ellipsis>
                  {telp}
                </Text>
              </div>
            </Tooltip>
          </div>
          <div className={s.content}>
            <p>--</p>
            <Tooltip title={tooltipRoles} mouseEnterDelay={1}>
              <p>Akses Grup : {role}</p>
            </Tooltip>
            <Tooltip title={lastLoginTooltip} mouseEnterDelay={1}>
              <p>
                <span>Terakhir Login Pada : </span>
                <span>{lastLoginFromNow}</span>
              </p>
            </Tooltip>
          </div>
        </Row>
        <div className={s.btnContent}>
          <Row justify="space-between">
            {isEditRoles && (
              <Button className="p-hor" onClick={() => setVisibleAssign(true)}>
                Sisipkan Akses Grup
              </Button>
            )}
            <Button className="p-hor" onClick={() => setVisiblePass(true)}>
              Ubah Password
            </Button>
          </Row>
          <Button
            type="primary"
            icon={<FaRegEdit />}
            onClick={() => setVisibleEdit(true)}
          >
            Ubah Data Pengguna
          </Button>
        </div>
      </Row>
    </div>
  );
};
export default Index;
