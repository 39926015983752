import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadPhoto from "./upload_photo";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, isValid, handleSubmit } = props;
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Field name="name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Bank",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: BCA"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="number">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "No. Rek.",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: 12388492892"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="pic_name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Atas Nama",
                  labelAlign: "left",
                  noReq: true,
                }}
                type="AntInput"
                placeholder="Ex: Jajang Nurjaman"
              />
            )}
          </Field>
        </Col>

        <Col span={24} className="mt5">
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Simpan
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
