import React from "react";
import MyFormik, { IProps, IState } from "app/_components/formik";
import initDataProto, { IValues } from "./form_proto";
import dayjs from "dayjs";
import CompForm from "./comp_form";
import _ from "lodash";
import { valid } from "./form_validator";

interface MyProps extends IProps {
  initData?: IValues;
}
interface MyState extends IState {
  initData: IValues;
}

class Index extends MyFormik<MyProps, MyState> {
  static getDerivedStateFromProps(nextProps: MyProps, prevState: MyState) {
    const hasUpdateInitData = nextProps.initData !== prevState.initData;
    if (hasUpdateInitData) {
      const newValue = nextProps.initData;
      if (!_.isUndefined(newValue)) {
        return {
          initData: newValue,
        };
      }
      return null;
    }
    return null;
  }
  protected getChildren() {
    return CompForm;
  }
  protected getValidator() {
    return valid;
  }
  protected getInitData() {
    const { initData } = this.props;
    let result = initDataProto;
    if (!_.isEmpty(initData)) result = initData;
    return result;
  }
}

const IndexConverted: React.FC<MyProps> = (props: MyProps) => {
  const onSubmit = async (values: IValues) => {
    let movement_date = dayjs(values.movement_date).format("YYYY-MM-DD");
    let pic_telp = values?.pic_telp;
    if (pic_telp) pic_telp = `+62-${pic_telp}`;
    values = {
      ...values,
      movement_date,
      pic_telp,
    };
    await props.onSubmit(values);
  };
  let slicerTelps = [];
  if (props?.initData?.pic_telp)
    slicerTelps = props?.initData?.pic_telp.split("-");
  const pic_telp = slicerTelps[1];
  const initData = {
    ...props.initData,
    pic_telp,
  };
  const initFunction = async () => {};
  React.useEffect(() => {
    initFunction();
  }, [props.initData]);
  return <Index onSubmit={onSubmit} initData={initData} />;
};

export default IndexConverted;
