import React from "react";
import { Button, Avatar, Image, Typography, Tag } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";

const { Text } = Typography;

dayjs.extend(relativeTime);

type TAction = {
  detailAction: (record: any) => any;
  editAction: (record: any) => any;
  delAction: (record: any) => any;
};

const columns = (actions: TAction): any => {
  return [
    {
      title: "Nama Lengkap",
      dataIndex: "name",
      sorter: true,
      width: 200,
      render: (item, record) => {
        let avatar = _.get(record, "image_url");
        const randomValue = Math.floor(Math.random() * 101);
        if (avatar) {
          avatar = `${API_HOST}/storage${avatar}?dummy=${randomValue}`;
        } else {
          avatar = "https://robohash.org/5.png?set=set2";
        }
        return (
          <div
            className={s.rowName}
            onClick={() => actions.detailAction(record)}
          >
            <Avatar src={avatar} />
            <Text ellipsis>{item}</Text>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      render: (item, record) => item || "--",
    },
    {
      title: "Status Notifikasi",
      dataIndex: "is_notifiable",
      sorter: true,
      render: (item, record) => {
        let compGender = (
          <Tag>
            <p>Tidak Aktif</p>
          </Tag>
        );
        if (item) {
          compGender = (
            <Tag color="geekblue">
              <p>Aktif</p>
            </Tag>
          );
        }
        return compGender;
      },
    },
    {
      title: "Hak Akses",
      dataIndex: "roles",
      render: (item, record) => {
        let tmp: any = [];
        if (item) {
          item.map((tmpItemRole) => {
            tmp.push(<Tag>{tmpItemRole.name}</Tag>);
          });
        } else tmp = "--";
        return tmp;
      },
    },
    {
      title: "Login Terakhir",
      dataIndex: "last_login_at",
      sorter: true,
      render: (item, record) => {
        const dateTime = _.get(record, "last_login_at") || false;
        let humanTime = "--";
        if (dateTime) {
          humanTime = dayjs(dateTime).fromNow();
        }
        return humanTime;
      },
    },
    {
      title: "Aksi",
      width: 100,
      align: "center",
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
