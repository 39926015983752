import React from "react";
import _ from "lodash";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import { Row, Col, Button, Divider } from "antd";
import Table from "./_components/table";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Input } from "antd";
import useLogic from "./_logic";
import Filter from "./_components/filter";
import { useHistory } from "react-router-dom";
import FilterIndicator from "./_components/filter_indicator";
import FilterAdditional from "./_components/filter_additional/form";
import { RiFileExcel2Line } from "react-icons/ri";
const { Search } = Input;

const Index: React.FC<any> = () => {
  const {
    onClickExport,
    onSearch,
    onSubmitFilterAdvance,
    onResetFilterAdvance,
    filterToggle,
    setFilterToggle,
    isAddButton,
  } = useLogic();
  const { push } = useHistory();

  return (
    <React.Fragment>
      <Layout>
        <div className="content_wrapper">
          <Row
            align="middle"
            justify="space-between"
            className="mb20"
            gutter={[15, 15]}
          >
            <Col>
              <Filter />
            </Col>
            <Col>
              <Button
                className="mr5"
                onClick={onClickExport}
                icon={<RiFileExcel2Line />}
              >
                Export Ke Excel
              </Button>
              {isAddButton && (
                <Button
                  icon={<AiOutlinePlusCircle />}
                  className="p-hor"
                  onClick={() => push(`/billings/add`)}
                  type="primary"
                >
                  Tambah Tagihan
                </Button>
              )}
            </Col>
          </Row>
          <Row
            align="middle"
            justify="space-between"
            className="mb10"
            gutter={[15, 15]}
          >
            <Col span={24}>
              <FilterIndicator
                onFilterToggle={setFilterToggle}
                filterToggle={filterToggle}
                onSearch={onSearch}
              />
            </Col>
            {filterToggle && (
              <Col span={24}>
                <Divider className="mb15" />
                <FilterAdditional
                  onSubmit={onSubmitFilterAdvance}
                  onReset={onResetFilterAdvance}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <Table searchText="" />
            </Col>
          </Row>
        </div>
      </Layout>
    </React.Fragment>
  );
};
export default Index;
