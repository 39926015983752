export interface IValues {
  name?: string;
  telp?: string;
  address?: string;
  vendor_bank?: {
    name?: string;
    number?: string;
    pic_name?: string;
  };
}

const proto: IValues = {};
export default proto;
