import React from "react";
import {
  Button,
  Avatar,
  Image,
  Typography,
  Tag,
  Tooltip,
  Popconfirm,
} from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";
import { FaEye } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";

dayjs.extend(relativeTime);

type TAction = {
  delAction: (record: any) => any;
};

const columns = (actions: TAction): any => {
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp, label) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            {label}
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  return [
    {
      title: "Nama Dokumen",
      render: (item, record) => {
        let tmp = _.get(record, "name") || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Deskripsi",
      render: (item, record) => {
        let tmp = _.get(record, "description") || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "File URL",
      render: (item, record) => {
        let tmp = _.get(record, "file_url") || false;
        return getRenderFile(tmp, "File Dokumen");
      },
    },
    {
      title: "Aksi",
      width: 125,
      render: (item, record) => {
        return (
          <Popconfirm
            title="Apakah anda yakin ?"
            onConfirm={() => {
              actions.delAction(record);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<AiOutlineDelete />}>Hapus</Button>
          </Popconfirm>
        );
      },
    },
  ];
};

export default columns;
