import React from "react";
import { Button, Drawer } from "antd";
import useLogic from "./_logic";
interface IProps {
  setVisible: any;
  visible: any;
}

const Index = (props: IProps) => {
  const { visible, onClose, loopMenus } = useLogic(props);
  return (
    <Drawer
      title="Menu Aplikasi"
      placement="left"
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      {loopMenus}
    </Drawer>
  );
};
export default Index;
