import { getEmailRemember } from "app/utils/local_storage";
export interface IValues {
  email?: string;
  password?: string;
  remember?: boolean;
}

let proto: IValues = {
  remember: false,
};
if (getEmailRemember()) {
  proto = {
    ...proto,
    remember: true,
    email: getEmailRemember(),
  };
}
export default proto;
