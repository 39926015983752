import React, { FC } from "react";
import CompForm from "./_components/form";
import s from "./index.module.scss";
import { Row, Col } from "antd";
import useLogic from "./_logic";

const Index: FC<any> = (props: any) => {
  const { handleSubmit } = useLogic(props);
  return (
    <Row className={s.loginWrapper}>
      <img
        src={process.env.PUBLIC_URL + "/images/bg.jpg"}
        className={s.bgImg}
      />
      <Col xs={24} xl={24}>
        <Row justify="center">
          <div className={s.formWrapper}>
            <img
              className={s.logoLogin}
              src={process.env.PUBLIC_URL + "/images/logo_hki.png"}
            />
            <div className={s.body}>
              <div className={s.textForgot}>
                <p className="text-lg text-bold">Update Password !</p>
                <p>
                  Silakan masukkan password baru, yang berguna untuk login
                  password selanjutnya.
                  <span className="text-bold ml5">
                    Mohon gunakan kombinasi Password yang kuat !
                  </span>
                </p>
              </div>
              <CompForm onSubmit={handleSubmit} />
            </div>
          </div>
        </Row>
      </Col>
      {/* <Col xs={24} xl={0}>
        <Row justify="center">
          <div className={s.wrapperNotSupport}>
            <p className="text-lg text-red text-bold">Upss !</p>
            <p className="text-sm text-muted">Not Supported In Your Device</p>
          </div>
        </Row>
      </Col> */}
    </Row>
  );
};
export default Index;
