import React from "react";
import { Button, Avatar, Image, Typography, Tag, Tooltip } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces, convertToRupiah } from "app/utils/functions";
import { Link } from "react-router-dom";

const { Text } = Typography;

dayjs.extend(relativeTime);

type TAction = {
  detailAction: (record: any) => any;
  renewAction: (record: any) => any;
  editAction: (record: any) => any;
  downloadAction: (record: any) => any;
  delAction: (record: any) => any;
  setStatusAction: (record: any) => any;
};

const filterStatusPay = [
  { value: "not_payed", label: "Belum Bayar", text: "Belum Bayar" },
  { value: "payed", label: "Lunas", text: "Lunas" },
  { value: "all", label: "Semuanya", text: "Semuanya" },
];

const columns = (actions: TAction, dataFilters: { dataVendors: any }): any => {
  const { dataVendors } = dataFilters;
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp, label) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            {label}
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  return [
    {
      title: "Nama Tagihan",
      dataIndex: "title",
      sorter: true,
      render: (item, record) => {
        const tmp = _.get(record, "title") || "--";
        return (
          <Tooltip title={tmp} placement="topLeft" mouseEnterDelay={0.6}>
            <Text ellipsis className={s.textElipsis}>
              <Link to={`/billings/detail/${record.id}`}>{tmp}</Link>
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor_id",
      sorter: true,
      render: (item, record) => {
        const tmp = _.get(record, "vendor.name") || "--";
        return (
          <Tooltip title={tmp} placement="topLeft" mouseEnterDelay={0.6}>
            <Text ellipsis className={s.textElipsis}>
              {tmp}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Nominal",
      render: (item, record) => {
        const tmp = _.get(record, "billing_pay_active.price") || "--";
        return convertToRupiah(tmp);
      },
    },
    // {
    //   title: "Invoice File",
    //   width: 100,
    //   render: (item, record) => {
    //     const tmp = _.get(record, "billing_pay_active.invoice_url") || false;
    //     return getRenderFile(tmp, "Invoice File");
    //   },
    // },
    // {
    //   title: "Faktur File",
    //   width: 100,
    //   render: (item, record) => {
    //     const tmp = _.get(record, "billing_pay_active.efaktur_url") || false;
    //     return getRenderFile(tmp, "Faktur File");
    //   },
    // },
    {
      title: "Jatuh Tempo",
      dataIndex: "expired_date",
      width: 180,
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "billing_pay_active.expired_date") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Status Bayar",
      dataIndex: "status_pay",
      render: (item, record) => {
        let tmp = _.get(record, "billing_pay_active.pay_date") || false;
        if (tmp) {
          return <Tag color="green">Lunas</Tag>;
        }
        return <Tag color="red">Belum Bayar</Tag>;
      },
    },
    {
      title: "Aksi",
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
