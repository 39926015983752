import React from "react";
import useLogicSideMenu from "../../sider/main/_logic";
import menus from "../../sider/main/menus";
import s from "./index.module.scss";
import { Link } from "react-router-dom";

const MyLink = ({ slug, label }) => {
  return (
    <Link to={slug}>
      <p className={s.menuItem}>{label}</p>
    </Link>
  );
};

const Index = (props) => {
  const { defaultSelectedKeys, checkIsAuthMenu } = useLogicSideMenu(props);
  const { setVisible, visible } = props;
  let loopMenus: any = [];
  loopMenus.push(<MyLink slug="/" label="Dashboard" key="/" />);
  const itemMenuComp = (parentItem: any) => {
    let tmpComp = [];
    menus.map((item, idx) => {
      let { icon, label, slug, subjectCode } = item;
      const isAllowed = checkIsAuthMenu(subjectCode);
      if (isAllowed && item.parent == parentItem) {
        tmpComp.push(<MyLink slug={slug} label={label} key={slug} />);
      }
    });
    return tmpComp;
  };
  loopMenus.push(
    <p key="p1" className="text-bold text-lg mt10">
      Transaksi
    </p>
  );
  loopMenus.push(...itemMenuComp("transaction"));
  loopMenus.push(
    <p key="p2" className="text-bold text-lg mt10">
      Master
    </p>
  );
  loopMenus.push(...itemMenuComp("master"));
  loopMenus.push(
    <p key="p3" className="text-bold text-lg mt10">
      Setting
    </p>
  );
  loopMenus.push(...itemMenuComp("setting"));

  const onClose = () => {
    setVisible(false);
  };
  return {
    visible,
    onClose,
    loopMenus,
  };
};
export default Index;
