import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";
import _ from "lodash";
import React from "react";

export default (props) => {
  const [locationLists, setlocationLists] = React.useState<any>([]);
  const [vendorLists, setvendorLists] = React.useState<any>([]);
  const [vehicleModelLists, setvehicleModelLists] = React.useState<any>([]);
  const [brandLists, setbrandLists] = React.useState<any>([]);
  const [vehicleVariantLists, setVehicleVariantLists] = React.useState<any>([]);
  const [loadingFilter, setloadingFilter] = React.useState<any>(true);
  // Utils AutoCopmlete
  const getParams = () => {
    let myParams: any = {
      limit: 99999,
    };
    return myParams;
  };
  const getConvertLists = (datas) => {
    return convertToSelect({
      datas: datas,
      labelName: "name",
      valueName: "id",
    });
  };
  // Get Request
  const getLocations = async () => {
    let endpoint = `locations`;
    const res = await req.get({ endpoint, params: getParams() });
    if (res.status == "success") {
      await setlocationLists(getConvertLists(res.data));
    }
  };
  const getVendors = async () => {
    let endpoint = `vendors`;
    const res = await req.get({ endpoint, params: getParams() });
    if (res.status == "success") {
      await setvendorLists(getConvertLists(res.data));
    }
  };
  const getVehicleModels = async (vehicle_variant_id = null) => {
    let endpoint = `vehicle_models`;
    const params = {
      filter: {
        vehicle_variant_id,
      },
      ...getParams(),
    };
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      await setvehicleModelLists(getConvertLists(res.data));
    }
  };
  const getVehicleVariants = async () => {
    let endpoint = `vehicle_variants`;
    const res = await req.get({ endpoint, params: getParams() });
    if (res.status == "success") {
      await setVehicleVariantLists(getConvertLists(res.data));
    }
  };
  const getBrands = async () => {
    let endpoint = `brands`;
    const res = await req.get({ endpoint, params: getParams() });
    if (res.status == "success") {
      await setbrandLists(getConvertLists(res.data));
    }
  };

  const onChangeVehicleVariant = (value) => {
    props.setFieldValue("vehicle_model_id", '');
    props.setFieldValue("vehicle_variant_id", value);
    getVehicleModels(value);
  };

  const initFunction = async () => {
    await setloadingFilter(true);
    await Promise.all([
      getLocations(),
      getVendors(),
      getBrands(),
      getVehicleVariants(),
    ]);
    await setloadingFilter(false);
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    locationLists,
    vendorLists,
    vehicleModelLists,
    brandLists,
    vehicleVariantLists,
    onChangeVehicleVariant,
    loadingFilter,
  };
};
