import _ from "lodash";
import dayjs from "dayjs";
import { IValues } from "./modal/form/form_proto";
import { IValues as IValuesRenew } from "./modal/form_renew/form_proto";

const adapterToEditForm = (dirtyData): IValues => {
  // init ConvertToString
  let id: any = _.get(dirtyData, "id");
  let department_id: any = _.get(dirtyData, "department.id");
  let document_type_id: any = _.get(dirtyData, "document_type.id");

  // init Dayjs
  let effective_date = _.get(dirtyData, "document_file_active.effective_date");
  effective_date = effective_date ? dayjs(effective_date) : null;
  let expired_date = _.get(dirtyData, "document_file_active.expired_date");
  expired_date = expired_date ? dayjs(expired_date) : null;

  const dataIValues: IValues = {
    id,
    document_type_id,
    institution: _.get(dirtyData, "institution"),
    title: _.get(dirtyData, "title"),
    document_file: {
      effective_date,
      expired_date,
      number: _.get(dirtyData, "document_file_active.number"),
      pdf_file: _.get(dirtyData, "document_file_active.pdf_url"),
      status: _.get(dirtyData, "document_file_active.status"),
    },
  };
  return dataIValues;
};
const adapterToRenewForm = (dirtyData): IValuesRenew => {
  const tmpDocumentLookup = adapterToEditForm(dirtyData);
  const dataIValues: IValuesRenew = {
    tmpDocumentLookup,
    document_id: _.get(tmpDocumentLookup, "id"),
  };
  return dataIValues;
};
export { adapterToEditForm, adapterToRenewForm };
