import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { message, Modal } from "antd";
import Form from "./form";
import { IValues } from "./form/form_proto";
import Req from "app/utils/http_client_query_builder";
import {
  get as getVehicles,
  getDetail as getVehicleDetail,
} from "app/req/vehicles";
import { get as getInsurances } from "app/req/insurances";

dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible } = props;
  let expired_date = _.get(props, "initData.expired_date") || null;
  if (expired_date) expired_date = dayjs(expired_date);

  const onSubmit = async (values: IValues) => {
    const vehicleId = values?.vehicle_id;
    let resPost: any = null;
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    if (values?.isEdit) {
      resPost = await Req.patch({
        endpoint: `insurances/${values.id}`,
        data: valuesFormData,
      });
    } else {
      resPost = await Req.post({
        endpoint: `insurances/${vehicleId}`,
        data: valuesFormData,
      });
    }
    if (resPost.status == "success") {
      await Promise.all([
        getVehicles(),
        getInsurances(vehicleId),
        getVehicleDetail(vehicleId),
      ]);
      message.success("Success Add Insurance Vehicle");
    }
    setVisible(false);
  };
  const initData: IValues = {
    id: props?.initData?.id,
    isEdit: props?.initData?.isEdit,
    vehicle_id: props?.initData?.vehicle_id,
    number: props?.initData?.number,
    vendor_id: props?.initData?.vendor_id,
    file: props?.initData?.file,
    expired_date,
  };
  return (
    <Modal
      title="Form Modal - Perpanjangan Asuransi"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 80 }}
    >
      <Form onSubmit={onSubmit} initData={initData} />
    </Modal>
  );
};
export default Index;
