import { API_URL } from "./consts";
import axios, { Method } from "axios";
import _ from "lodash";
import { query } from "js-query-builder";

export interface IParamsURL {
  limit?: number;
  page?: number;
  filter?: {
    [key: string]: string;
  };
  sort?: string;
  include?: Array<string>;
  fields?: {
    [key: string]: Array<string>;
  };
  custom?: {
    [key: string]: string;
  };
}
export interface IParamsURLSimple {
  limit?: number;
  page?: number;
  filter?: {
    [key: string]: string;
  };
  sort?: string;
  include?: Array<string>;
  custom?: {
    [key: string]: string;
  };
}

export interface ResultType {
  status: "success" | "error";
  data: any;
  message?: string;
  pagination?: {
    per_page: number;
    current_page: number;
    last_page: number;
    total: number;
  };
}
interface IHTTPAction {
  endpoint: string;
  params?: IParamsURL;
  data?: object;
}

interface IMyFetch {
  endpoint: string;
  method: Method;
  params?: IParamsURL;
  headers?: any;
  data?: any;
}

export const getHeader = () => {
  let myHeader: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Content-Type": "application/x-www-form-urlencoded",
  };
  return myHeader;
};

const myFetch = async (conf: IMyFetch): Promise<any> => {
  const { method, endpoint, data, params } = conf;
  const limit = _.get(params, "limit") || 15;
  const page = _.get(params, "page") || 1;
  const filter = _.get(params, "filter");
  const sort = _.get(params, "sort") || "-id";
  const include = _.get(params, "include") || false;
  const fields = _.get(params, "fields") || false;
  const custom = _.get(params, "custom") || false;
  let paramsUrl: any = false;
  if (conf.method == "GET") {
    paramsUrl = query().param("limit", limit).page(page);
    if (filter) paramsUrl.filter(filter);
    if (sort) paramsUrl.sort(sort);
    if (include) paramsUrl.include(include);
    if (fields) paramsUrl.fields(fields);
    if (custom) {
      // Loop Object
      for (let key in custom) {
        if (custom[key]) paramsUrl.param(key, custom[key]);
      }
    }
    paramsUrl = paramsUrl.build();
    paramsUrl = decodeURIComponent(paramsUrl);
  }
  let url = `${API_URL}/${endpoint}`;
  if (paramsUrl) url = `${API_URL}/${endpoint}${paramsUrl}`;
  return new Promise((resolve) => {
    const res = axios({
      method,
      url,
      data,
      headers: getHeader(),
      withCredentials: true,
    })
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        const resErr = {
          status: "error",
          data: "lost connection",
        };
        let tmp = _.get(e, "response.data") || resErr;
        return tmp;
      });
    resolve(res);
  });
};
const get = async (conf: IHTTPAction): Promise<ResultType> => {
  const { endpoint, params } = conf;
  return await myFetch({ method: "GET", endpoint, params });
};
const post = async (conf: IHTTPAction): Promise<ResultType> => {
  const { endpoint, data } = conf;
  return await myFetch({ method: "POST", endpoint, data });
};
const patch = async (conf: IHTTPAction): Promise<ResultType> => {
  let { endpoint, data } = conf;
  if (data instanceof FormData) {
    data.append("_method", "PATCH");
  } else {
    data = {
      ...data,
      _method: "PATCH",
    };
  }
  return await myFetch({
    method: "POST",
    endpoint,
    data,
  });
};
const del = async (conf: IHTTPAction): Promise<ResultType> => {
  const { endpoint, data } = conf;
  return await myFetch({
    method: "DELETE",
    endpoint,
    data,
  });
};
const http_req = { get, post, patch, del };
export default http_req;
export { get, post, patch, del };
