import { API_HOST } from "app/utils/consts";
import { get as getVendors } from "app/req/vendors";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";
import dayjs from "dayjs";

export default (props) => {
  const genderOptions = [
    { value: "male", label: "Laki-Laki" },
    { value: "female", label: "Perempuan" },
  ];

  const [vendors, setVendors] = React.useState<any>([]);
  const [vendorLists, setVendorLists] = React.useState<any>([]);
  const [vendorLoading, setVendorLoading] = React.useState<any>(true);
  const [vendorBankLists, setVendorBankLists] = React.useState<any>([]);
  const { values } = props;
  const getFileThumnail = (): {
    invoicePdfThumbnail: any;
    efakturPdfThumbnail: any;
  } => {
    let invoicePdf: any = _.get(values, "billing_pay.invoice_file");
    let efakturPdf: any = _.get(values, "billing_pay.efaktur_file");
    const checkFile = (tmpCheckFile) => {
      if (tmpCheckFile) {
        if (typeof tmpCheckFile == "object") {
          tmpCheckFile = tmpCheckFile?.name;
        } else if (tmpCheckFile != "" || tmpCheckFile != "empty") {
          tmpCheckFile = tmpCheckFile;
        } else {
          tmpCheckFile = tmpCheckFile ? tmpCheckFile : "--";
        }
      }
      return tmpCheckFile;
    };
    invoicePdf = checkFile(invoicePdf);
    efakturPdf = checkFile(efakturPdf);

    return {
      invoicePdfThumbnail: invoicePdf,
      efakturPdfThumbnail: efakturPdf,
    };
  };
  const getParams = ({ search, id }) => {
    let myParams: any = {
      filter: { name: search },
      limit: 9999999999,
    };
    if (id) {
      myParams = {
        ...myParams,
        custom: { id },
      };
    }
    return myParams;
  };
  const getConvertLists = (datas) => {
    return convertToSelect({
      datas: datas,
      labelName: "name",
      valueName: "id",
    });
  };
  // Change RangePicker
  const onChangePeriode = async (value) => {
    const periode_from = dayjs(value[0]);
    const periode_to = dayjs(value[1]);
    await props.setFieldValue("billing_pay.periode_from", periode_from);
    await props.setFieldValue("billing_pay.periode_to", periode_to);
  };

  // Vendors
  const convertVendorBanks = (idVendor, paramsListVendors) => {
    let currentVendor: any = [...paramsListVendors];
    currentVendor = _.find(currentVendor, { id: idVendor });
    let tmpVendorBankLists = _.get(currentVendor, "vendor_banks") || [];
    tmpVendorBankLists = tmpVendorBankLists.map((item) => {
      return {
        ...item,
        name: `${item.number} (${item.name})-${item.pic_name}`,
      };
    });
    tmpVendorBankLists = getConvertLists(tmpVendorBankLists);
    return tmpVendorBankLists;
  };
  const setVendorBanks = (idVendor, paramsListVendors = vendors) => {
    const tmpVendorBankLists = convertVendorBanks(idVendor, paramsListVendors);
    setVendorBankLists(tmpVendorBankLists);
  };
  const onChangeVendors = (idVendor) => {
    props.setFieldValue("vendor_id", idVendor);
    props.setFieldValue("vendor_bank_id", null);
    setVendorBanks(idVendor);
  };
  const getVendors = async ({ search, id }) => {
    setVendorLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `vendors`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      await setVendors(res.data);
      await setVendorLists(tmpLists);
      if (id != null || id != undefined) {
        await setVendorBanks(res.data[0].id, res.data);
      }
    }
    setVendorLoading(false);
  };
  const onSearchVendors = async (search) => {
    await getVendors({ search, id: null });
  };

  // Status Switch
  const onChangeStatus = (isChecked) => {
    let status = isChecked ? "active" : "warning";
    props.setFieldValue("billing_pay.status", status);
  };
  const getDefaultValueSatus = () => {
    let status = _.get(values, "billing_pay.status");
    if (status == "active") {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  const initFunction = async () => {
    let promiseTmp1 = getVendors({ search: "", id: null });
    // Check if foreignId is Exist FetchData with paramsId (Optimize)
    const vendor_id = _.get(values, "vendor_id");
    if (vendor_id) promiseTmp1 = getVendors({ search: "", id: vendor_id });
    // End Check
    await Promise.all([promiseTmp1]);
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    genderOptions,
    vendorLists,
    vendorLoading,

    onSearchVendors,
    onChangeVendors,
    getFileThumnail,
    onChangeStatus,
    getDefaultValueSatus,
    vendorBankLists,
    onChangePeriode,
  };
};
