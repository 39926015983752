import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { Modal } from "antd";
import CompDetail from "./comp_detail";

dayjs.extend(relativeTime);

interface IProps {
  setVisible: any;
  visible: boolean;
  initData: any;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible, initData } = props;
  return (
    <Modal
      title="Form Modal - Detail Dokumen"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      width={600}
      style={{ top: 35 }}
    >
      <CompDetail initData={initData} />
    </Modal>
  );
};
export default Index;
