export interface IValues {
  billing_id?: string;
  id?: string;
  vendor_id?: string;
  vendor_bank_id?: string;
  title?: string;
  periode?: string;
  pic_name?: string;
  pic_telp?: string;
  billing_pay?: {
    price?: string;
    number?: string;
    periode_from?: any;
    periode_to?: any;
    invoice_date?: any;
    expired_date?: any;
    invoice_file?: any;
    efaktur_file?: any;
    status?: any;
  };
}

const proto: IValues = {};
export default proto;
