import React from "react";
import _ from "lodash";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import { Row, Col, Button, Divider, Spin } from "antd";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Input } from "antd";
import useLogic from "./_logic";
import Form from "./_components/edit";
import CompVendorBanks from "./_components/vendor_bank";
import { FaPlusCircle } from "react-icons/fa";

const Index: React.FC<any> = () => {
  const { initData, loading, setVisibleAdd, visibleAdd } = useLogic();
  return (
    <React.Fragment>
      <Layout>
        <div className="content_wrapper">
          <Spin spinning={loading}>
            <Row gutter={24}>
              <Col span={12}>
                <div className={s.dividerTitle}>
                  <p>Form Vendor</p>
                </div>
                <Form initData={initData} />
              </Col>
              <Col span={12}>
                <div className={s.dividerTitle}>
                  <p>Daftar Rekening Vendor</p>
                  <Button onClick={() => setVisibleAdd(true)}>
                    <FaPlusCircle />
                    Tambah Rekening
                  </Button>
                </div>
                <CompVendorBanks
                  setVisibleAdd={setVisibleAdd}
                  visibleAdd={visibleAdd}
                />
              </Col>
            </Row>
          </Spin>
        </div>
      </Layout>
    </React.Fragment>
  );
};
export default Index;
