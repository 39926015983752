import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { message, Modal } from "antd";
import Form from "./form_renew";
import { IValues } from "./form_renew/form_proto";
import { removeEmpty } from "app/utils/functions";
import { post as postBillingPays } from "app/req/billing_pays";
import { get as getBillingPay } from "app/req/billing_pays";
import { useParams } from "react-router-dom";

dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
  onSubmit: any;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const pageParams = useParams<any>();
  const { setVisible, visible } = props;
  const renewAction = async (values) => {
    values = removeEmpty(values);
    const valuesOri = {
      ...values,
    };
    const valuesPayer = {
      ...values.payer,
    };
    delete valuesOri.payer;
    let valuesFormData = new FormData();
    for (let key in valuesOri) valuesFormData.append(key, valuesOri[key]);
    for (let key in valuesPayer) valuesFormData.append(key, valuesPayer[key]);
    const res = await postBillingPays(values.billing_id, valuesFormData);
    if (res) {
      message.success(`Success Renew Data`);
    }
  };
  const onSubmit = async (values: IValues) => {
    await renewAction(values);
    await props.onSubmit();
    await setVisible(false);
  };
  return (
    <Modal
      title="Form Modal - Perbarui Tagihan"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 5 }}
    >
      <Form onSubmit={onSubmit} initData={props.initData} />
    </Modal>
  );
};
export default Index;
