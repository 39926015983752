import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { message, Modal } from "antd";
import Form from "./form";
import { IValues } from "./form/form_proto";
import Req from "app/utils/http_client_query_builder";
import {
  get as getVehicles,
  getDetail as getVehicleDetail,
} from "app/req/vehicles";
import { get as getTaxVehicles } from "app/req/tax_vehicles";

dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible } = props;

  let expired_date = _.get(props, "initData.expired_date") || null;
  if (expired_date) expired_date = dayjs(expired_date);
  let plate_expired_month =
    _.get(props, "initData.plate_expired_month") || null;
  if (plate_expired_month) plate_expired_month = dayjs(plate_expired_month);
  // let position_date = _.get(props, "initData.position.position_date") || null;
  // if (position_date) position_date = dayjs(position_date);

  const tmpInitData: IValues = {
    ...props?.initData,
    expired_date,
    plate_expired_month,
    // position: {
    //   ...props?.initData?.position,
    //   position_date,
    // },
    header_meta: {
      ...props?.initData?.header_meta,
    },
  };

  const onSubmit = async (values: IValues) => {
    const vehicleId = values?.header_meta?.vehicle_id;
    delete values.header_meta;
    // const positionValues = {
    //   ...values.position,
    // };
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    // for (let key in positionValues)
    //   valuesFormData.append(`position[${key}]`, positionValues[key]);
    let resPost = null;
    if (values?.isEdit) {
      resPost = await Req.patch({
        endpoint: `tax_vehicles/${values.id}`,
        data: valuesFormData,
      });
    } else {
      resPost = await Req.post({
        endpoint: `tax_vehicles/${vehicleId}`,
        data: valuesFormData,
      });
    }
    if (resPost.status == "success") {
      await Promise.all([
        getVehicles(),
        getTaxVehicles(vehicleId),
        getVehicleDetail(vehicleId),
      ]);
      message.success("Success Action Tax Vehicles");
    }
    setVisible(false);
  };
  return (
    <Modal
      title="Form Modal - Perpanjang STNK"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 35 }}
    >
      <Form onSubmit={onSubmit} initData={tmpInitData} />
    </Modal>
  );
};
export default Index;
