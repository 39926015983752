export interface IValues {
  id?: string;
  isEdit?: boolean;
  vehicle_id?: string;
  file?: any;
  number?: string;
  expired_date?: any;
}

const proto: IValues = {
  isEdit: false,
};
export default proto;
