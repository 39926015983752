import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

type TProps = {
  get: any;
  setFilter: any;
  setSort: any;
  setPagination: any;
  selector: any;
};

export default (props: TProps) => {
  const { get, setFilter, setPagination, setSort, selector } = props;
  let datas: any = useSelector(selector);
  datas = datas.records;
  const [loading, setLoading] = React.useState(true);
  const [month, setMonth] = React.useState(1);
  let fromDate = dayjs().format("YYYY-MM-DD");
  let toDate = dayjs().add(1, "week").format("YYYY-MM-DD");
  const [betweenExpired, setBetweenExpired] = React.useState(
    `${fromDate},${toDate}`
  );

  const initFunction = async (tmpData = 1) => {
    setLoading(true);
    fromDate = dayjs().format("YYYY-MM-DD");
    toDate = dayjs().add(tmpData, "week").format("YYYY-MM-DD");
    const tmpValue = `${fromDate},${toDate}`;
    setBetweenExpired(tmpValue);
    let filterParams = {
      filter: { between_expired_date: tmpValue },
      sort: "expired_date",
      limit: 5,
      custom: {
        only_warning: "true",
      },
    };
    if (tmpData == 6) {
      fromDate = dayjs("1000-01-01", "YYYY-MM-DD").format("YYYY-MM-DD");
      toDate = dayjs().format("YYYY-MM-DD");
      const tmpValue = `${fromDate},${toDate}`;
      setBetweenExpired(tmpValue);
      filterParams = {
        ...filterParams,
        filter: { between_expired_date: tmpValue },
        sort: "-expired_date",
      };
    }
    await setSort(filterParams.sort);
    await setFilter(filterParams.filter);
    await setPagination({ current_page: 1, per_page: filterParams?.limit });
    await Promise.all([get()]);
    setLoading(false);
  };
  const onChangeTab = (key) => {
    setMonth(key);
    initFunction(key);
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    datas,
    loading,
    month,
    onChangeTab,
    betweenExpired,
  };
};
