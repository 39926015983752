import React from "react";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { get, post, setSearch,setFilter,clearFilter } from "app/req/documents";
import { API_URL } from "app/utils/consts";
import axios from "axios";
import _ from "lodash";
import { query } from "js-query-builder";
import { getHeader } from "app/utils/http_client_query_builder";
import { useSelector } from "react-redux";
import { selector as selectorDocuments } from "app/redux/documents";
import dot from "dot-object";

export default () => {
  const filter = useSelector(selectorDocuments).filter;
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [filterToggle, setFilterToggle] = React.useState(false);

  const exportExcel = async () => {
    const FileDownload = require("js-file-download");
    let paramsUrl = query();
    paramsUrl.filter({ ...filter });
    paramsUrl = paramsUrl.sort("expired_date");
    paramsUrl = paramsUrl.build();
    paramsUrl = decodeURIComponent(paramsUrl);
    await axios({
      url: `${API_URL}/documents/export${paramsUrl}`,
      method: "GET",
      responseType: "blob", // Important
      headers: getHeader(),
      withCredentials: true,
    }).then((response) => {
      FileDownload(response.data, "documentsExport.xlsx");
    });
  };

  const onClickExport = () => {
    exportExcel();
  };

  const onSearch = async (search = "") => {
    await setSearch(search);
    await get();
  };

  const onSubmitFilterAdvance = async (values) => {
    const dottedObj = dot.dot(values);
    const filter = dottedObj;
    await setFilter(filter);
    await get();
  };

  const onResetFilterAdvance = async () => {
    await clearFilter();
    await get();
  };

  getAuthorize("documents", "show");
  const addAction = async (values) => {
    const valuesNoDocFile = {
      ...values,
    };
    const valuesDocFile = values.document_file;
    delete valuesNoDocFile.document_file;
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    for (let key in valuesDocFile) {
      valuesFormData.append(`document_file[${key}]`, valuesDocFile[key]);
    }
    const res = await post(valuesFormData);
    if (res) {
      message.success("Success Add Data");
    }
  };

  let isAddButton = false;
  const addPermission = getAuthorize("documents", "create", true, true);
  if(addPermission) isAddButton = true;

  return {
    visibleAdd,
    setVisibleAdd,
    visibleEdit,
    setVisibleEdit,
    addAction,
    exportExcel,
    onClickExport,
    onSearch,
    filterToggle,
    setFilterToggle,
    onSubmitFilterAdvance,
    onResetFilterAdvance,
    isAddButton
  };
};
