import * as layout from "./form_layout";
import React from "react";
import _, { includes } from "lodash";
import {
  TPermission,
  objPermissions,
  stringPermissions,
} from "app/utils/permissions";
import { useSelector } from "react-redux";
import { selector as selectorPermissions } from "app/redux/permissions";
import useLogic from "./_logic";
import {
  convertMenuLists,
  convertPermissionLists,
} from "app/pages/private/roles/roles/_components/const";

export default (props) => {
  const convertMenusToId = (en) => {
    return convertMenuLists.find((x) => x.en === en).id;
  };
  const convertPermissionsToId = (en) => {
    return convertPermissionLists.find((x) => x.en === en).id;
  };
  const { formLayout } = layout;
  const {
    values,
    handleSubmit,
    handleReset,
    setFieldValue,
    isSubmitting,
  } = props;
  const [permissions, setPermissions] = React.useState<Array<TPermission>>([]);
  const [initPermissions, setInitPermissions] = React.useState<
    Array<TPermission>
  >([]);
  const dataPermissions = useSelector(selectorPermissions).dataObject;
  const initFunction = async () => {
    let tmpInit = [];
    const tmpPerms = dataPermissions;
    if (values.permissions) tmpInit = objPermissions(values.permissions);
    setInitPermissions(tmpInit);
    setPermissions(tmpPerms);
  };
  const handleSwitch = (subject, permission, check) => {
    const tmpJoined = _.join([subject, permission], ".");
    let currentValue = [...values.permissions];
    if (check) {
      currentValue = [...currentValue, tmpJoined];
    } else {
      let tmpIndex = _.findIndex(currentValue, (i) => i == tmpJoined);
      currentValue.splice(tmpIndex, 1);
    }
    setFieldValue("permissions", currentValue);
  };
  const handleCheckAll = (subject, check) => {
    let tmpIndex = _.findIndex(permissions, { subject });
    let permsArr: any = permissions[tmpIndex];
    permsArr = stringPermissions(permsArr);
    let currentValue = [...values.permissions];
    if (check) {
      currentValue = _.union(currentValue, permsArr);
    } else {
      currentValue = currentValue.filter(function (x) {
        return permsArr.indexOf(x) < 0;
      });
    }
    setFieldValue("permissions", currentValue);
  };
  React.useEffect(() => {
    initFunction();
  }, [values]);
  return {
    convertMenusToId,
    convertPermissionsToId,
    formLayout,
    isSubmitting,
    permissions,
    initPermissions,
    handleCheckAll,
    handleSwitch,
    handleReset,
    handleSubmit,
  };
};
