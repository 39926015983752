import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Dropdown, Menu, Popconfirm } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import s from "./index.module.scss";
import _ from "lodash";
import { FaEye, FaRegEdit, FaRegFilePdf } from "react-icons/fa";

dayjs.extend(relativeTime);

interface IProps {
  key?: any;
  children?: React.ReactElement;
  record: any;
  actions: {
    detailAction?: (record: any) => any;
    renewAction?: (record: any) => any;
    editAction?: (record: any) => any;
    delAction?: (record: any) => any;
  };
}

const Index: React.FC<IProps> = (props: IProps) => {
  const [visible, setVisible] = React.useState(false);
  const { delAction, editAction, renewAction, detailAction } = props.actions;
  const { record } = props;
  const handleMenuClick = ({ key }) => {
    if (key !== "3") setVisible(false);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="0"
        className={s.contextMenu}
        onClick={() => {
          setVisible(false);
          detailAction(record);
        }}
      >
        <FaEye /> Detail
      </Menu.Item>
      <Menu.Item
        key="2"
        className={s.contextMenu}
        onClick={() => {
          setVisible(false);
          editAction(record);
        }}
      >
        <FaRegEdit /> Ubah
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={(e) => setVisible(e)}
    >
      {props.children}
    </Dropdown>
  );
};
export default Index;
