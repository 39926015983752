import _ from "lodash";
import dayjs from "dayjs";
import { IValues } from "./form/form_proto";
import { IValues as IValuesRenew } from "app/pages/private/billings/_components/modal/form_renew/form_proto";
import { IValues as IValuesSetStatus } from "app/pages/private/billings/_components/modal/form_set_payed/form_proto";

const adapterToEditForm = (dirtyData): IValues => {
  // init ConvertToString
  let id: any = _.get(dirtyData, "id");
  let vendor_id: any = _.get(dirtyData, "vendor.id");
  let vendor_bank_id: any = _.get(dirtyData, "vendor_bank.id");

  // init Dayjs
  let invoice_date = _.get(dirtyData, "billing_pay_active.invoice_date");
  invoice_date = invoice_date ? dayjs(invoice_date) : null;
  let expired_date = _.get(dirtyData, "billing_pay_active.expired_date");
  expired_date = expired_date ? dayjs(expired_date) : null;

  let periode_from = _.get(dirtyData, "billing_pay_active.periode_from");
  periode_from = periode_from ? dayjs(periode_from) : null;
  let periode_to = _.get(dirtyData, "billing_pay_active.periode_to");
  periode_to = periode_to ? dayjs(periode_to) : null;

  let pic_telp = _.get(dirtyData, "pic_telp");
  if (pic_telp) {
    const tmpPicTelp = pic_telp.split("-");
    pic_telp = tmpPicTelp[1];
  }

  const dataIValues: IValues = {
    id,
    title: _.get(dirtyData, "title"),
    periode: _.get(dirtyData, "periode"),
    vendor_id,
    vendor_bank_id,
    billing_pay: {
      number: _.get(dirtyData, "billing_pay_active.number"),
      efaktur_file: _.get(dirtyData, "billing_pay_active.efaktur_url"),
      invoice_file: _.get(dirtyData, "billing_pay_active.invoice_url"),
      periode_from,
      periode_to,
      invoice_date,
      expired_date,
      price: _.get(dirtyData, "billing_pay_active.price"),
      status: _.get(dirtyData, "billing_pay_active.status"),
    },
    pic_name: _.get(dirtyData, "pic_name"),
    pic_telp,
  };
  return dataIValues;
};
const adapterToRenewForm = (dirtyData): IValuesRenew => {
  const tmpBillingLookup = adapterToEditForm(dirtyData);
  const dataIValues: IValuesRenew = {
    billing_id: _.get(tmpBillingLookup, "id"),
    tmpBillingLookup,
  };
  return dataIValues;
};
const adapterToSetStatusForm = (dirtyData): IValuesSetStatus => {
  let pay_date = _.get(dirtyData, "billing_pay_active.pay_date");
  pay_date = pay_date ? dayjs(pay_date) : null;

  const tmpBillingLookup = adapterToEditForm(dirtyData);
  const dataIValues: IValuesSetStatus = {
    billing_id: _.get(tmpBillingLookup, "id"),
    tmpBillingLookup,
    billing_pay: {
      evidence_file: _.get(dirtyData, "billing_pay_active.evidence_url"),
      pay_date,
    },
  };
  return dataIValues;
};
export { adapterToEditForm, adapterToRenewForm, adapterToSetStatusForm };
