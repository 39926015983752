import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import { Tag, Tooltip, Typography } from "antd";
import { FaCalendar } from "react-icons/fa";
import { AiOutlineCalendar, AiOutlineFieldTime } from "react-icons/ai";
const { Text, Link } = Typography;

dayjs.extend(relativeTime);

type TInitData = {
  document: {
    id?: any;
    institution?: any;
    title?: any;
    created_at?: any;
    updated_at?: any;
  };
  document_id?: any;
  effective_date?: any;
  expired_date?: any;
  id?: any;
  number?: any;
  pdf_url?: any;
  status?: any;
  created_at?: any;
  updated_at?: any;
  updater_id?: any;
};

export default (props) => {
  const initData = props.initData;
  const checkData = (
    fieldName,
    config?: {
      isDate?: boolean;
      withHour?: boolean;
      withRelative?: boolean;
    }
  ) => {
    let tmp = _.get(initData, fieldName) || false;
    const isDate = config?.isDate;
    const withHour = config?.withHour;
    const withRelative = config?.withRelative;
    if (!tmp) {
      return (tmp = "--");
    }
    if (isDate) {
      let myFormat = "DD MMMM YYYY";
      if (withHour) {
        myFormat = `${myFormat}  HH:mm`;
      }
      let tmpFormatted: any = dayjs(tmp).format(myFormat);
      tmpFormatted = `${tmpFormatted} WIB`;
      if (withRelative) {
        let relativeTime: any = dayjs(tmp).fromNow();
        const relativeTimeConverted = (
          <span className="ml10 text-muted text-xs">({relativeTime})</span>
        );
        tmpFormatted = (
          <span>
            {tmpFormatted} {relativeTimeConverted}
          </span>
        );
      }
      tmp = tmpFormatted;
    }
    return tmp;
  };
  const checkStatus = (dataStatus) => {
    if (dataStatus === "warning") {
      dataStatus = <Tag color="geekblue">Nyala</Tag>;
    } else if (dataStatus === "active") {
      dataStatus = <Tag color="red">Mati</Tag>;
    } else {
      dataStatus = <Tag>Diarsipkan</Tag>;
    }
    return dataStatus;
  };
  const checkUrl = (dataUrl, folderName = "documents") => {
    if (dataUrl != "--") {
      let tmpUrl: any = `${API_HOST}/storage${dataUrl}`;
      return (
        <Tooltip title={tmpUrl} mouseEnterDelay={0.75}>
          <Link
            href={tmpUrl}
            target="_blank"
            copyable
            ellipsis
            style={{ width: "250px" }}
          >
            {tmpUrl}
          </Link>
        </Tooltip>
      );
    } else {
      return `--`;
    }
  };
  const date = {
    isDate: true,
    withRelative: true,
  };
  const dateTime = {
    ...date,
    withHour: true,
  };
  const convertData: TInitData = {
    document: {
      institution: checkData("document.institution"),
      title: checkData("document.title"),
    },
    document_id: checkData("document_id"),
    effective_date: checkData("effective_date", date),
    expired_date: checkData("expired_date", date),
    number: checkData("number"),
    pdf_url: checkUrl(checkData("pdf_url")),
    status: checkStatus(checkData("status")),
    created_at: checkData("created_at", dateTime),
    updated_at: checkData("updated_at", dateTime),
  };
  const showData = [
    { label: "Nama Dokumen", value: convertData.document.title },
    { label: "Lembaga", value: convertData.document.institution },
    { label: "Tgl. Dikeluarkan", value: convertData.effective_date },
    { label: "Tgl. Masa Berlaku", value: convertData.expired_date },
    { label: "Nomor", value: convertData.number },
    { label: "Status Notif.", value: convertData.status },
    { label: "Dibuat pada", value: convertData.created_at },
    { label: "Diperbarui pada", value: convertData.updated_at },
    { label: "File URL", value: convertData.pdf_url },
  ];
  return {
    showData,
  };
};
