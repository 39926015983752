import React from "react";
import { Row, Col, Typography, Space, Divider, Tag, Tooltip } from "antd";
import { FaBriefcase, FaRegEye } from "react-icons/fa";
import styles from "./index.module.scss";
import { AiOutlineFolder } from "react-icons/ai";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
const { Text } = Typography;
type TProps = {
  id: string;
  name: string;
  type: string;
  expiredDate: string;
};

const Index: React.FC<TProps> = (props: TProps) => {
  const { expiredDate, name, type } = props;
  let remainingDays = dayjs(expiredDate).fromNow();
  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row>
            <AiOutlineFolder className={styles.iconVacancy} />
            <div>
              <div>
                <Tooltip title={name}>
                  <Text ellipsis className={styles.textElipsis}>
                    <Link
                      to={`/documents/detail/${props.id}`}
                      className="text-xs text-bold"
                    >
                      {name}
                    </Link>
                  </Text>
                </Tooltip>
              </div>
              <div>
                <Tooltip title={type}>
                  <Tag color="red" className={styles.tag}>
                    <Text ellipsis>{type}</Text>
                  </Tag>
                </Tooltip>
              </div>
            </div>
          </Row>
        </Col>
        <Col>
          <p className="text-xs text-muted">{remainingDays}</p>
        </Col>
      </Row>
      <Divider className="mb10" />
    </>
  );
};

export default React.memo(Index);
