export interface IValues {
  id?: string;
  isEdit?: boolean;
  header_meta?: {
    vehicle_id?: any;
    location_active?: any;
  };
  location_id?: string;
  driver_name?: string;
  pic_name?: string;
  pic_telp?: string;
  file?: any;
  movement_date?: any;
}

const proto: IValues = {
  isEdit: false,
};
export default proto;
