import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import columns from "./columns";
import ModalRenew from "app/pages/private/billings/_components/modal/renew";
import ModalSetStatus from "app/pages/private/billings/_components/modal/set_payed";
import _ from "lodash";
import useLogic from "./_logic";

dayjs.extend(relativeTime);

interface IProps {
  searchText: string;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const {
    // Function
    onChangeTable,
    setVisibleRenew,
    setVisibleSetStatus,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    visibleRenew,
    visibleSetStatus,
    initData,
    dataFilters,
    loadingLocal,
    fetchSetData,
    refetchAfterMutate
  } = useLogic(props);
  return (
    <React.Fragment>
      <Table
        columns={columns(columnActions, dataFilters)}
        dataSource={datas}
        rowKey={(record) => record.id}
        size="middle"
        showSorterTooltip={false}
        onChange={onChangeTable}
        loading={loading || loadingLocal}
        pagination={{
          ...paginationProps,
          hideOnSinglePage: false,
          showSizeChanger: true,
          showTotal: (total, range) => {
            return (
              <div style={{ position: "absolute", left: 0 }}>
                Daftar mulai {range[0]}-{range[1]} dari {total} Item
              </div>
            );
          },
        }}
        scroll={{ x: "100%" }}
      />
      <ModalRenew
        visible={visibleRenew}
        setVisible={setVisibleRenew}
        initData={initData}
        onSubmit={refetchAfterMutate}
      />
      <ModalSetStatus
        visible={visibleSetStatus}
        setVisible={setVisibleSetStatus}
        initData={initData}
        onSubmit={()=>{}}
      />
    </React.Fragment>
  );
};
export default Index;
