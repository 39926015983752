import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import Req from "app/utils/http_client_query_builder";
import { get as getVehicles } from "app/req/vehicles";
import { get as getDocuments } from "app/req/documents";
import { get as getBillings } from "app/req/billings";
import { get as getCountMasters } from "app/req/count_masters";
import { get as getDocumentByTypes } from "app/req/document_by_types";

dayjs.extend(relativeTime);

export default () => {
  const [loading, setLoading] = React.useState(true);

  const initFunction = async () => {
    const fromDate = dayjs().format("YYYY-MM-DD");
    const toDate = dayjs().add(3, "month").format("YYYY-MM-DD");
    const between_expired_date = `${fromDate},${toDate}`;
    const filterParams = {
      filter: { between_expired_date },
      sort: "expired_date",
      limit: 5,
      custom: {
        only_warning: "true",
      },
    };
    setLoading(true);
    await Promise.all([
      getCountMasters(),
      getDocumentByTypes(),
      // getVehicles(filterParams),
      // getDocuments(filterParams),
      // getBillings(filterParams),
    ]);
    setLoading(false);
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    loading,
  };
};
