import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";

export default (props) => {
  const { values } = props;
  const [positionStatusOptions, setPositionStatusOptions] = React.useState([]);
  const getFileThumnail = () => {
    let filePdfTmp: any = _.get(values, "file");
    if (filePdfTmp) {
      if (typeof filePdfTmp == "object") {
        filePdfTmp = filePdfTmp?.name;
      } else if (filePdfTmp != "" || filePdfTmp != "empty") {
        filePdfTmp = filePdfTmp;
      } else {
        filePdfTmp = filePdfTmp ? filePdfTmp : "--";
      }
    }

    return {
      filePdfThumbnail: filePdfTmp,
    };
  };
  const [isYearly, setIsYearly] = React.useState(props.values.isYearly);
  const onChangeYearly = (e) => {
    setIsYearly(e);
    props.setFieldValue("isYearly", e);
  };
  const initFunction = async () => {
    const resTmp = await req.get({ endpoint: "position_statuses" });
    const datas = convertToSelect({ datas: resTmp.data });
    setPositionStatusOptions(datas);
  };
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    isYearly,
    onChangeYearly,
    filePdfThumbnail: getFileThumnail().filePdfThumbnail,
    positionStatusOptions,
  };
};
