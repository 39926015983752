import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  get as getDocuments,
  del as delDocuments,
  patch as patchDocuments,
  setPagination as setPaginationDocuments,
  setFilter as setFilterDocuments,
  clearFilter as clearFilterDocuments,
  setSort as setSortDocuments,
} from "app/req/documents";
import { selector as selectorDocuments } from "app/redux/documents";
import { selector as selectorDocumentTypes } from "app/redux/document_types";
import { selector as selectorDepartments } from "app/redux/departments";
import { adapterToEditForm, adapterToRenewForm } from "./adapter";
import { convertToFilterAndSelect } from "app/utils/functions";
import { get as getDepartments } from "app/req/departments";
import { get as getDocumentTypes } from "app/req/document_types";
import { post as postDocumentFiles } from "app/req/document_files";
import { removeEmpty } from "app/utils/functions";
import { useHistory, useLocation } from "react-router";
import { getDatas as getDatasWithFilter } from "./filter/_logic";
import queryString from "query-string";

dayjs.extend(relativeTime);

type TFetchData = {
  page?: any;
  limit?: any;
  sort?: any;
  filter?: any;
};

const Index = (props) => {
  const { push } = useHistory();
  const queryUrl = useLocation<any>()?.search;
  const query: any = queryString.parse(queryUrl);
  // Change Module
  const datas = useSelector(selectorDocuments).records;
  const loading = useSelector(selectorDocuments).loading;
  const pagination = useSelector(selectorDocuments).pagination;
  const dataDocumentTypes = useSelector(selectorDocumentTypes).records;
  const dataDepartments = useSelector(selectorDepartments).records;
  const dataFilters = {
    dataDocumentTypes: convertToFilterAndSelect({
      datas: dataDocumentTypes,
      labelName: "name",
      valueName: "id",
    }),
    dataDepartments: convertToFilterAndSelect({
      datas: dataDepartments,
      labelName: "name",
      valueName: "id",
    }),
  };

  // const [loading, setLoading] = React.useState(true);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [initData, setInitData] = React.useState<any>(null);
  const [visibleRenew, setVisibleRenew] = React.useState(false);
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: TFetchData) => {
    // if (!filter?.department_id)
    //   params = { ...params, filter: { ...params.filter, department_id: [] } };
    // if (!params?.filter?.document_type_id)
    //   params = {
    //     ...params,
    //     filter: { ...params.filter, document_type_id: [] },
    //   };
    await setFilterDocuments(params.filter);
    await setPaginationDocuments({
      current_page: params?.page,
      per_page: params?.limit,
    });
    await setSortDocuments(params?.sort);
    await Promise.all([
      getDocuments(),
      // getDocumentTypes({ limit: 9999 }),
      // getDepartments({ limit: 9999 }),
    ]);
  };
  const renewAction = async (values) => {
    values = removeEmpty(values);
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    const res = await postDocumentFiles(values.document_id, valuesFormData);
    if (res) {
      message.success(`Success Edit Data`);
      await getDocuments();
    }
  };
  const editAction = async (values) => {
    values = removeEmpty(values);
    const valuesNoDocFile = {
      ...values,
    };
    const valuesDocFile = values.document_file;
    delete valuesNoDocFile.document_file;
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, valuesNoDocFile[key]);
    if (valuesDocFile) {
      for (let key in valuesDocFile) {
        valuesFormData.append(`document_file[${key}]`, valuesDocFile[key]);
      }
    }
    const res = await patchDocuments(initData.id, valuesFormData);
    if (res) {
      message.success(`Success Edit Data`);
    }
  };
  const delAction = async (record) => {
    setInitData(null);
    const res = await delDocuments(record.id);
    if (res) {
      message.success(`Success Delete Data`);
    }
  };

  // In Bellow is Standarized
  const detailAction = async (record) => {
    push(`/documents/detail/${record.id}`);
  };
  const renewShowModal = async (record) => {
    setVisibleRenew(true);
    const convertedToEdit = adapterToRenewForm(record);
    setInitData(convertedToEdit);
  };
  const editShowModal = async (record) => {
    setVisibleEdit(true);
    const convertedToEdit = adapterToEditForm(record);
    setInitData(convertedToEdit);
  };
  const columnActions = {
    detailAction,
    renewAction: renewShowModal,
    delAction,
    editAction: editShowModal,
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    // const listFilter = ["department_id", "document_type_id"];
    const listFilter = [];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    // End Filter
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort, filter });
  };
  const initFunction = async () => {
    const limit = 15;
    await clearFilterDocuments();
    if (!_.isEmpty(query)) {
      const tmpBetween = query?.between_expired_date.split(",");
      const firstDate = tmpBetween[0];
      const lastDate = tmpBetween[1];
      await setPaginationDocuments({
        per_page: 15,
      });
      await getDatasWithFilter(firstDate, lastDate);
    } else {
      await fetchSetData({ page: 1, limit });
    }
  };
  React.useEffect(() => {
    initFunction();
  }, []);

  return {
    // Function
    onChangeTable,
    setVisibleEdit,
    setVisibleRenew,
    editAction,
    renewAction,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    visibleEdit,
    visibleRenew,
    initData,
    dataFilters,
  };
};
export default Index;
