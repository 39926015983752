import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import req from "app/utils/http_client_query_builder";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { selector as selectorAuth } from "app/redux/auth";
import { getAuthorize } from "app/utils/permissions";

const Index = (props) => {
  const { values } = props;
  const isShowAll = getAuthorize("vehicles", "show", true, false);
  const locationId = useSelector(selectorAuth).record.location_id;
  const isFirstTimeOfUseEffect = React.useRef(true);

  const [locationDisabled, setLocationDisabled] = React.useState<any>(
    !isShowAll
  );
  // Select GetOptions
  const [wheelLists, setWheelLists] = React.useState<any>([]);
  const [wheelLoading, setWheelLoading] = React.useState<any>(true);
  const [locationLists, setLocationLists] = React.useState<any>([]);
  const [locationLoading, setLocationLoading] = React.useState(true);
  const [brandLists, setBrandLists] = React.useState<any>([]);
  const [brandLoading, setBrandLoading] = React.useState(true);
  const [picLists, setPicLists] = React.useState<any>([]);
  const [picLoading, setPicLoading] = React.useState(true);
  const [vehicleModelLists, setVehicleModelLists] = React.useState<any>([]);
  const [vehicleModelLoading, setVehicleModelLoading] = React.useState(true);
  const [vendorsLists, setVendorslLists] = React.useState<any>([]);
  const [vendorsLoading, setVendorsLoading] = React.useState(true);
  const [insuranceVendorLists, setInsuranceVendorLists] = React.useState<any>(
    []
  );
  const [insuranceVendorLoading, setInsuranceVendorLoading] = React.useState(
    true
  );
  const [fuelLists, setFuelLists] = React.useState<any>([]);
  const [fuelLoading, setFuelLoading] = React.useState(true);
  // End
  const isEdit = props?.initData ? true : false;

  let image_url = _.get(props, "values.photo") || null;
  if (image_url != null) {
    if (image_url != "empty") {
      image_url = `${API_HOST}/storage${image_url}`;
    } else {
      image_url = null;
    }
  } else {
    image_url = null;
  }

  // Change RangePicker
  const onChangePeriode = async (value) => {
    const periode_from = dayjs(value[0]);
    const periode_to = dayjs(value[1]);
    await props.setFieldValue("rent_vehicle.start", periode_from);
    await props.setFieldValue("rent_vehicle.end", periode_to);
  };
  // Utils AutoCopmlete
  const getParams = ({ search, id }) => {
    let myParams: any = {
      filter: { name: search },
      limit: 9999999999,
    };
    if (id) {
      myParams = {
        ...myParams,
        custom: { id },
      };
    }
    return myParams;
  };
  const getConvertLists = (datas) => {
    return convertToSelect({
      datas: datas,
      labelName: "name",
      valueName: "id",
    });
  };
  // Wheels
  const onSearchWheels = async (search) => {
    await getWheels({ search, id: null });
  };
  const getWheels = async ({ search, id }) => {
    setWheelLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `wheels`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setWheelLists(tmpLists);
    }
    setWheelLoading(false);
  };
  // Location
  const onSearchLocations = async (search) => {
    await getLocations({ search, id: null });
  };
  const getLocations = async ({ search, id }) => {
    setLocationLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `locations`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setLocationLists(tmpLists);
    }
    setLocationLoading(false);
  };
  // Brands
  const onSearchBrands = async (search) => {
    await getBrands({ search, id: null });
  };
  const getBrands = async ({ search, id }) => {
    setBrandLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `brands`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setBrandLists(tmpLists);
    }
    setBrandLoading(false);
  };
  // PIC
  const onSearchPics = async (search) => {
    await getPics({ search, id: null });
  };
  const getPics = async ({ search, id }) => {
    setPicLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `users`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setPicLists(tmpLists);
    }
    setPicLoading(false);
  };
  // VehicleModels
  const onSearchVehicleModels = async (search) => {
    await getVehicleModels({ search, id: null });
  };
  const getVehicleModels = async ({ search, id }) => {
    setVehicleModelLoading(true);
    const params = getParams({ search, id });
    let endpoint = `vehicle_models`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = [];
      const datas = res.data;
      const valueName = "id";
      let labelName = "name";
      let i = 0;
      if (!_.isEmpty(datas)) {
        for (i = 0; i < datas.length; i++) {
          let value, label;
          value = datas[i][valueName];
          label = datas[i][labelName];
          label = `${label} (${datas[i]?.vehicle_variant?.name})`;
          tmpLists.push({ value, label });
        }
      }

      setVehicleModelLists(tmpLists);
    }
    setVehicleModelLoading(false);
  };
  // Vendors
  const onSearchVendors = async (search) => {
    await getVendors({ search, id: null });
  };
  const getVendors = async ({ search, id }) => {
    setVendorsLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `vendors`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setVendorslLists(tmpLists);
    }
    setVendorsLoading(false);
  };
  // Insurance Vendors
  const onSearchInsuranceVendors = async (search) => {
    await getInsuranceVendors({ search, id: null });
  };
  const getInsuranceVendors = async ({ search, id }) => {
    setInsuranceVendorLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `vendors`;
    const res = await req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setInsuranceVendorLists(tmpLists);
    }
    setInsuranceVendorLoading(false);
  };
  // Fuels
  const getFuels = async () => {
    setFuelLoading(true);
    let endpoint = `fuels`;
    const res = await req.get({ endpoint });
    if (res.status == "success") {
      const tmpLists = convertToSelect({
        datas: res.data,
        labelName: "name",
        valueName: "name",
      });
      setFuelLists(tmpLists);
    }
    setFuelLoading(false);
  };

  const onChangeStatus = (isChecked) => {
    let status = isChecked ? "active" : "warning";
    props.setFieldValue("plate_tax.status", status);
  };
  const getDefaultValueSatus = () => {
    let status = _.get(values, "plate_tax.status");
    if (status == "active") {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  const onChangeOwner = (isChecked) => {
    props.setFieldValue("show_owner", isChecked);
    if (!isChecked) {
      props.setFieldValue("owner_id", "");
      props.setFieldValue("rent_vehicle.contract_number", "");
      props.setFieldValue("rent_vehicle.price", "");
      props.setFieldValue("rent_vehicle.start", "");
      props.setFieldValue("rent_vehicle.end", "");
    }
  };
  const getDefaultValueOwner = () => {
    return props.values.show_owner;
  };

  // Lists Promises options
  const promiseLocations = async () => {
    const ref_id = "location.location_id";
    return await checkerPromise({
      ref_id: ref_id,
      promise: getLocations,
      setLoading: setLocationLoading,
    });
  };
  const checkerPromise = async ({
    ref_id = null,
    promise = null,
    setLoading = null,
  }) => {
    let tmp_ref_id = _.get(values, ref_id);

    let tmp = null;
    if (tmp_ref_id) {
      tmp = await promise({ search: "", id: tmp_ref_id });
    } else {
      tmp = await promise({ search: "", id: null });
    }
    return tmp;
  };
  const promiseBrands = async () => {
    const ref_id = "brand_id";
    return await checkerPromise({
      ref_id: ref_id,
      promise: getBrands,
      setLoading: setBrandLoading,
    });
  };
  const promiseVehicleModels = async () => {
    const ref_id = "vehicle_model_id";
    return await checkerPromise({
      ref_id: ref_id,
      promise: getVehicleModels,
      setLoading: setVehicleModelLoading,
    });
  };
  const promisePics = async () => {
    const ref_id = "pic_id";
    return await checkerPromise({
      ref_id: ref_id,
      promise: getPics,
      setLoading: setPicLoading,
    });
  };
  const promiseVendors = async () => {
    const ref_id = "owner_id";
    return await checkerPromise({
      ref_id: ref_id,
      promise: getVendors,
      setLoading: setVendorsLoading,
    });
  };
  const promiseInsuranceVendors = async () => {
    const ref_id = "insurance.vendor_id";
    return await checkerPromise({
      ref_id: ref_id,
      promise: getInsuranceVendors,
      setLoading: setInsuranceVendorLoading,
    });
  };
  const promiseWheels = async () => {
    const ref_id = "wheel_id";
    return await checkerPromise({
      ref_id: ref_id,
      promise: getWheels,
      setLoading: setWheelLoading,
    });
  };
  const promiseFuels = async () => {
    const ref_id = null;
    return await checkerPromise({
      ref_id: ref_id,
      promise: getFuels,
      setLoading: setFuelLoading,
    });
  };

  const initFunction = async () =>{ 
    await Promise.all([
      promiseBrands(),
      promiseVehicleModels(),
      promisePics(),
      promiseVendors(),
      promiseWheels(),
      promiseFuels(),
      promiseLocations(),
      promiseInsuranceVendors()
    ]);
  }

  React.useEffect(() => {
    setLocationDisabled(!isShowAll);
  }, [isShowAll]);

  React.useEffect(() => {
    initFunction()
    const checkIsAddVehicle = !values?.location?.location_id;
    const defaultLocation = locationId;
    if (checkIsAddVehicle && defaultLocation)
      props.setFieldValue("location.location_id", locationId);
  }, []);

  return {
    onSearchLocations,
    onSearchBrands,
    onSearchPics,
    onSearchVehicleModels,
    onChangeStatus,
    onChangeOwner,
    onSearchVendors,
    onSearchInsuranceVendors,
    getDefaultValueSatus,
    getDefaultValueOwner,
    onSearchWheels,

    locationLists,
    locationLoading,
    brandLists,
    brandLoading,
    picLists,
    picLoading,
    vehicleModelLists,
    vehicleModelLoading,
    vendorsLists,
    vendorsLoading,
    image_url,
    isEdit,
    fuelLists,
    fuelLoading,
    wheelLists,
    wheelLoading,
    insuranceVendorLists,
    insuranceVendorLoading,
    onChangePeriode,
    locationDisabled,
  };
};
export default Index;
