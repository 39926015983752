import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import { Tag, Tooltip, Typography } from "antd";
import { FaCalendar, FaPhone } from "react-icons/fa";
import { AiOutlineCalendar, AiOutlineFieldTime } from "react-icons/ai";
import { convertToRupiah } from "app/utils/functions";
const { Text, Link } = Typography;

dayjs.extend(relativeTime);

type TInitData = {
  billing?: {
    id?: any;
    created_at?: any;
    pic_name?: any;
    pic_telp?: any;
    title?: any;
    updated_at?: any;
    vendor?: {
      id?: any;
      address?: any;
      image_url?: any;
      name?: any;
      telp?: any;
    };
    vendor_bank?: {
      id?: any;
      name?: any;
      number?: any;
      pic_name?: any;
    };
  };
  id?: any;
  created_at?: any;
  efaktur_url?: any;
  evidence_url?: any;
  expired_date?: any;
  periode_from?: any;
  periode_to?: any;
  invoice_date?: any;
  invoice_url?: any;
  number?: any;
  pay_date?: any;
  price?: any;
  status?: any;
  updated_at?: any;
};

export default (props) => {
  const initData = props.initData;
  const checkData = (
    fieldName,
    config?: {
      isDate?: boolean;
      withHour?: boolean;
      withRelative?: boolean;
    }
  ) => {
    let tmp = _.get(initData, fieldName) || false;
    const isDate = config?.isDate;
    const withHour = config?.withHour;
    const withRelative = config?.withRelative;
    if (!tmp) {
      return (tmp = "--");
    }
    if (isDate) {
      let myFormat = "DD MMMM YYYY";
      if (withHour) {
        myFormat = `${myFormat}  HH:mm`;
      }
      let tmpFormatted: any = dayjs(tmp).format(myFormat);
      tmpFormatted = `${tmpFormatted} WIB`;
      if (withRelative) {
        let relativeTime: any = dayjs(tmp).fromNow();
        const relativeTimeConverted = (
          <span className="ml10 text-muted text-xs">({relativeTime})</span>
        );
        tmpFormatted = (
          <span>
            {tmpFormatted} {relativeTimeConverted}
          </span>
        );
      }
      tmp = tmpFormatted;
    }
    return tmp;
  };
  const checkStatus = (dataStatus) => {
    if (dataStatus === "warning") {
      dataStatus = <Tag color="geekblue">Nyala</Tag>;
    } else if (dataStatus === "active") {
      dataStatus = <Tag color="red">Mati</Tag>;
    } else {
      dataStatus = <Tag>Diarsipkan</Tag>;
    }
    return dataStatus;
  };
  const checkPaid = (dataPaid) => {
    if (dataPaid == "--") {
      dataPaid = <Tag color="red">Belum Bayar</Tag>;
    } else {
      dataPaid = <Tag color="green">Lunas</Tag>;
    }
    return dataPaid;
  };
  const checkUrl = (dataUrl, folderName = "documents") => {
    if (dataUrl != "--") {
      let tmpUrl: any = `${API_HOST}/storage${dataUrl}`;
      return (
        <Tooltip title={tmpUrl} mouseEnterDelay={0.75}>
          <Link
            href={tmpUrl}
            target="_blank"
            copyable
            ellipsis
            style={{ width: "250px" }}
          >
            {tmpUrl}
          </Link>
        </Tooltip>
      );
    } else {
      return `--`;
    }
  };
  const onlyDate = { isDate: true, withHour: false, withRelative: false };
  const date = {
    isDate: true,
    withRelative: true,
  };
  const dateTime = {
    ...date,
    withHour: true,
  };
  const convertData: TInitData = {
    billing: {
      title: checkData("billing.title"),
      pic_name: checkData("billing.pic_name"),
      pic_telp: checkData("billing.pic_telp"),
      vendor: {
        name: checkData("billing.vendor.name"),
        telp: checkData("billing.vendor.telp"),
        address: checkData("billing.vendor.address"),
      },
      vendor_bank: {
        name: checkData("billing.vendor_bank.name"),
        number: checkData("billing.vendor_bank.number"),
        pic_name: checkData("billing.vendor_bank.pic_name"),
      },
    },
    periode_from: checkData("periode_from", onlyDate),
    periode_to: checkData("periode_to", onlyDate),
    invoice_date: checkData("invoice_date", date),
    expired_date: checkData("expired_date", date),
    number: checkData("number"),
    price: convertToRupiah(checkData("price")),
    status: checkStatus(checkData("status")),
    pay_date: checkData("pay_date", date),
    created_at: checkData("created_at", dateTime),
    updated_at: checkData("updated_at", dateTime),
    efaktur_url: checkUrl(checkData("efaktur_url")),
    invoice_url: checkUrl(checkData("invoice_url")),
    evidence_url: checkUrl(checkData("evidence_url")),
  };
  let vendorRekening: any = "--";
  if (convertData.billing.vendor_bank.number != "--") {
    const { name, number, pic_name } = convertData.billing.vendor_bank;
    vendorRekening = (
      <Text
        copyable={{ text: `${number} (${name}) - ${pic_name}` }}
        className="mr4"
      >
        {number} ({name}) - {pic_name}
      </Text>
    );
  }
  let picBilling: any = "--";
  if (convertData.billing.pic_name != "--") {
    picBilling = (
      <span>
        {convertData.billing.pic_name}
        <FaPhone className="ml15 mr2 text-muted text-xs" />
        <Text copyable>{convertData.billing.pic_telp}</Text>
      </span>
    );
  }
  let picVendor: any = "--";
  if (convertData.billing.vendor.name != "--") {
    picVendor = (
      <span>
        {convertData.billing.vendor.name}
        <FaPhone className="ml15 mr2 text-muted text-xs" />
        <Text copyable>{convertData.billing.vendor.telp}</Text>
      </span>
    );
  }
  let diffRentangTempo: any = dayjs(props.initData.periode_to, "YYYY-MM-DD");
  diffRentangTempo = diffRentangTempo.diff(props.initData.periode_from, "day");
  const showData = [
    { label: "Nama Tagihan", value: convertData.billing.title },
    { label: "Nominal", value: convertData.price },
    {
      label: "Rentang Periode",
      value: `${convertData.periode_from} - ${convertData.periode_to} (${diffRentangTempo} Hari)`,
    },
    { label: "Tanggal Invoice", value: convertData.invoice_date },
    { label: "Tanggal Jatuh Tempo", value: convertData.expired_date },
    { label: "Status Notif.", value: convertData.status },
    { label: "Status Pembayaran", value: checkPaid(convertData.pay_date) },
    { label: "Tanggal Pembayaran", value: convertData.pay_date },
    { label: "Dibuat pada", value: convertData.created_at },
    { label: "Terakhir Diubah", value: convertData.updated_at },
    { label: "E-Faktur URL", value: convertData.efaktur_url },
    { label: "Invoice URL", value: convertData.invoice_url },
    { label: "Bukti URL", value: convertData.evidence_url },
  ];
  return {
    showData,
  };
};
