import React from "react";
import {
  get as getReq,
  post as postReq,
  patch as patchReq,
  del as delReq,
  IParamsURLSimple,
  IParamsURL,
} from "app/utils/http_client_query_builder";
import { getAuthorize } from "app/utils/permissions";
import { message } from "antd";
import { removeEmpty } from "app/utils/functions";
import { IValues } from "../_components/form/form_proto";
import { adapterToEditForm } from "../_components/adapter";
import _ from "lodash";
import { useHistory, useParams } from "react-router";
import { patch } from "app/req/billings";

const initDataEmpty = {
  id: "",
  vendor_id: "",
  title: "",
  billing_pay: {
    price: "",
    number: "",
    invoice_date: "",
    expired_date: "",
    invoice_file: "",
    efaktur_file: "",
    status: "",
  },
  payer: {
    name: "",
    telp: "",
    address: "",
  },
};

export default () => {
  getAuthorize("billings", "update");
  const { push } = useHistory();
  const { id } = useParams<any>();
  const [initData, setInitData] = React.useState<IValues>(initDataEmpty);
  const [loading, setLoading] = React.useState(true);
  const initFunction = async () => {
    setLoading(true);
    const tmpRes = await getReq({
      endpoint: `billings`,
      params: { custom: { id } },
    });
    let tmpInit = _.get(tmpRes, "data[0]");
    tmpInit = adapterToEditForm(tmpInit);
    tmpInit = removeEmpty(tmpInit);
    setInitData(tmpInit);
    setLoading(false);
  };
  const onSubmit = async (values) => {
    let valuesFormData = new FormData();
    let dataOri = {
      billing_id: id,
      ...values,
    };
    delete dataOri.billing_pay;
    delete dataOri.payer;

    const tmpBillingPay = values.billing_pay;
    const tmpPayer = values.payer;
    for (let key in dataOri) {
      valuesFormData.append(key, dataOri[key]);
    }
    for (let key in tmpBillingPay) {
      valuesFormData.append(`billing_pay[${key}]`, tmpBillingPay[key]);
    }
    for (let key in tmpPayer) {
      valuesFormData.append(`payer[${key}]`, tmpPayer[key]);
    }
    // End Change To Form

    const res = await patch(id, valuesFormData);
    if (res) {
      message.success("Data Has Been Edited");
      push(`/billings/detail/${id}`);
    }
  };

  React.useEffect(() => {
    initFunction();
  }, []);

  return {
    onSubmit,
    initData,
    loading,
  };
};
