import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  get as getVehicles,
  del as delVehicles,
  patch as patchVehicles,
  setPagination as setPaginationVehicles,
  setFilter as setFilterVehicles,
  clearFilter as clearFilterVehicles,
  setSort as setSortVehicles,
} from "app/req/vehicles";
import { selector as selectorVehicles } from "app/redux/vehicles";
import { selector as selectorVehicleModels } from "app/redux/vehicle_models";
import { selector as selectorBrands } from "app/redux/brands";
import { selector as selectorWheels } from "app/redux/wheels";
import { useHistory, useLocation } from "react-router";
import { convertToFilterAndSelect } from "app/utils/functions";
import { getDatas as getDatasWithFilter } from "./filter/_logic";
import queryString from "query-string";

dayjs.extend(relativeTime);

const Index = (props) => {
  const { push } = useHistory();
  const queryUrl = useLocation<any>()?.search;
  const query: any = queryString.parse(queryUrl);
  // Change Module
  const datas = useSelector(selectorVehicles).records;
  const pagination = useSelector(selectorVehicles).pagination;
  const loading = useSelector(selectorVehicles).loading;

  const dataVehicleModels = useSelector(selectorVehicleModels).records;
  const dataBrands = useSelector(selectorBrands).records;
  const dataWheels = useSelector(selectorWheels).records;
  const dataFilters = {
    dataVehicleModels: convertToFilterAndSelect({
      datas: dataVehicleModels,
      labelName: "name",
      valueName: "id",
    }),
    dataBrands: convertToFilterAndSelect({
      datas: dataBrands,
      labelName: "name",
      valueName: "id",
    }),
    dataWheels: convertToFilterAndSelect({
      datas: dataWheels,
      labelName: "name",
      valueName: "id",
    }),
  };

  const [initData, setInitData] = React.useState<any>(null);
  const [loadingLocal, setLoadingLocal] = React.useState(true);
  const [visibleRenew, setVisibleRenew] = React.useState(false);
  const [visibleMovement, setVisibleMovement] = React.useState(false);
  const [visibleAddDocument, setVisibleAddDocument] = React.useState(false);
  const [visibleRenewKir, setVisibleRenewKir] = React.useState(false);
  const [visibleRenewInsurance, setVisibleRenewInsurance] = React.useState(
    false
  );
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: any) => {
    setLoadingLocal(true);
    const plateNumberFilter = _.get(params?.filter, "plate.plate_number");
    if (!plateNumberFilter) {
      params = {
        ...params,
        filter: { ...params.filter, ["plate.plate_number"]: [] },
      };
    }
    await setFilterVehicles(params?.filter);
    await setPaginationVehicles({
      current_page: params?.page,
      per_page: params?.limit,
    });
    await setSortVehicles(params?.sort);

    await Promise.all([
      getVehicles(),
      // getBrands({ limit: 9999 }),
      // getVehicleModels({ limit: 9999 }),
      // getWheels({ limit: 9999 }),
    ]);
    setLoadingLocal(false);
  };
  const detailAction = async (record) => {
    const idVehicle = record.id;
    push(`/vehicles/detail/${idVehicle}`);
  };
  const delAction = async (record) => {
    const res = await delVehicles(record.id);
    if (res) {
      message.success(`Success Delete Data`);
    }
  };

  // In Bellow is Standarized
  const renewShowModal = async (record) => {
    const tmpInit = {
      header_meta: {
        vehicle_id: record?.id,
        expired_date: record?.plate?.plate_tax_active?.expired_date,
      },
    };
    setInitData(tmpInit);
    setVisibleRenew(true);
  };
  const movementShowModal = async (record) => {
    const location_active = _.get(record, "location_active");
    const tmpInit = {
      header_meta: {
        vehicle_id: record?.id,
        location_active: location_active,
      },
    };
    setInitData(tmpInit);
    setVisibleMovement(true);
  };
  const addDocumentShowModal = async (record) => {
    const tmpInit = {
      header_meta: {
        vehicle_id: record?.id,
        name_vehicle: record?.name,
        type_vehicle: record?.plate?.type,
      },
    };
    setInitData(tmpInit);
    setVisibleAddDocument(true);
  };
  const editShowModal = async (record) => {
    const idVehicle = record.id;
    push(`/vehicles/edit/${idVehicle}`);
  };
  const renewKirShowModal = (record) => {
    const tmpInit = {
      vehicle_id: record?.id,
    };
    setInitData(tmpInit);
    setVisibleRenewKir(true);
  };
  const renewInsuranceShowModal = (record) => {
    const tmpInit = {
      vehicle_id: record?.id,
    };

    setInitData(tmpInit);
    setVisibleRenewInsurance(true);
  };
  const columnActions = {
    detailAction,
    renewAction: renewShowModal,
    movementAction: movementShowModal,
    delAction,
    editAction: editShowModal,
    addDocumentAction: addDocumentShowModal,
    renewKirAction: renewKirShowModal,
    renewInsuranceAction: renewInsuranceShowModal,
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = (pagination: PaginationProps, filters, sorter) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = ["plate.plate_number", "brand_id", "vehicle_model_id"];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    // End Filter

    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort, filter });
  };
  const initFunction = async () => {
    const limit = 15;
    await clearFilterVehicles();
    if (!_.isEmpty(query)) {
      const tmpBetween = query?.between_expired_date.split(",");
      const firstDate = tmpBetween[0];
      const lastDate = tmpBetween[1];
      await setLoadingLocal(true);
      await setPaginationVehicles({
        per_page: 15,
      });
      await getDatasWithFilter(firstDate, lastDate);
      await setLoadingLocal(false);
    } else {
      await fetchSetData({ page: 1, limit });
    }
  };
  React.useEffect(() => {
    initFunction();
  }, []);

  return {
    // Function
    onChangeTable,
    setVisibleRenew,
    setVisibleMovement,
    setVisibleAddDocument,
    // State/Const
    columnActions,
    datas,
    loading,
    paginationProps,
    dataFilters,
    visibleRenew,
    visibleMovement,
    visibleAddDocument,
    initData,
    loadingLocal,
    visibleRenewKir,
    setVisibleRenewKir,
    visibleRenewInsurance,
    setVisibleRenewInsurance,
  };
};
export default Index;
