import React from "react";
import { Button, Avatar, Image, Typography, Tag, Tooltip } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";
import { FaEye } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
const { Text } = Typography;

dayjs.extend(relativeTime);

type TAction = {
  detailAction: (record: any) => any;
};

const columns = (actions): any => {
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp, label) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            {label}
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  const checkStatus = (dataStatus) => {
    if (dataStatus === "active") {
      dataStatus = <Tag color="geekblue">Aktif</Tag>;
    } else {
      dataStatus = <Tag>Non Aktif</Tag>;
    }
    return dataStatus;
  };
  return [
    {
      title: "Lokasi",

      render: (item, record) => {
        let tmp: any = _.get(record, "location.name") || "--";
        let tmpDesc: any = _.get(record, "location.description") || "--";
        tmp = (
          <Tooltip mouseEnterDelay={0.7} title={tmpDesc}>
            <Text ellipsis className={s.textElipsis}>
              {tmp}
            </Text>
          </Tooltip>
        );
        return tmp;
      },
    },
    {
      title: "Nama PIC",
      render: (item, record) => {
        let tmp = _.get(record, "pic_name") || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Nama Pemakai",
      render: (item, record) => {
        let tmp = _.get(record, "driver_name") || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Telp. PIC",
      render: (item, record) => {
        let tmp = _.get(record, "pic_telp") || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Tanggal Perpindahan",
      render: (item, record) => {
        let tmp = _.get(record, "movement_date") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Dokumen",
      render: (item, record) => {
        let tmp = _.get(record, "file_url") || false;
        return tmp ? getRenderFile(tmp, "File Dokumen") : "--";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (item, record) => {
        let tmp = _.get(record, "status") || false;
        return checkStatus(tmp);
      },
    },
    {
      title: "Aksi",
      dataIndex: "status",
      width: 125,
      render: (item, record) => {
        return (
          <Button
            icon={<AiOutlineEdit />}
            onClick={() => actions.editAction(record)}
          >
            Edit
          </Button>
        );
      },
    },
  ];
};

export default columns;
