import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Switch } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadFile from "./upload_file";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import s from "./index.module.scss";
import { AiOutlinePlusCircle, AiOutlineSave } from "react-icons/ai";
import { useHistory } from "react-router";
import { formatterCurrency, parserCurrency } from "app/utils/functions";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const { RangePicker } = Datepicker;

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, handleSubmit } = props;
  const {
    genderOptions,
    vendorLists,
    vendorLoading,

    onSearchVendors,
    onChangeVendors,
    getFileThumnail,
    onChangeStatus,
    getDefaultValueSatus,
    vendorBankLists,
    onChangePeriode,
  } = useLogic(props);
  const { efakturPdfThumbnail, invoicePdfThumbnail } = getFileThumnail();
  const { push } = useHistory();
  const { billing_pay } = props.values;
  return (
    <Form {...formLayout}>
      <Row gutter={25}>
        <Col span={12}>
          <Row>
            <div className={s.dividerTitle}>
              <p>Informasi Tagihan</p>
            </div>
            <Col span={24}>
              <Field name="title">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Nama Tagihan",
                      labelAlign: "left",
                    }}
                    type="AntInput"
                    placeholder="Ex: Judul"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="billing_pay.number">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Nomor Invoice",
                      labelAlign: "left",
                    }}
                    type="AntInput"
                    placeholder="Ex: TGH002EFAKTUR-01"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Row className="mb25">
                <Col span={6}>
                  <TextLabel isRequired label="Rentang Periode" />
                </Col>
                <Col span={18}>
                  <RangePicker
                    clearIcon={false}
                    ranges={{
                      "Bulan Sekarang": [
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ],
                    }}
                    value={[billing_pay?.periode_from, billing_pay?.periode_to]}
                    onChange={onChangePeriode}
                    style={{ width: "100%" }}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Field name="billing_pay.price">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Nominal",
                      labelAlign: "left",
                    }}
                    type="AntInputNumber"
                    parser={parserCurrency}
                    formatter={formatterCurrency}
                    placeholder="Ex: 2500000"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="billing_pay.invoice_date">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Tgl. Invoice",
                      labelAlign: "left",
                    }}
                    type="AntDatePicker"
                    placeholder="Ex: 2020-02-02"
                  />
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Field name="billing_pay.expired_date">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    formItem={{
                      label: "Jatuh Tempo",
                      labelAlign: "left",
                    }}
                    type="AntDatePicker"
                    placeholder="Ex: 2020-02-02"
                  />
                )}
              </Field>
            </Col>
            {/* <Col span={24} className="mb15">
              <Row>
                <Col span={6}>
                  <span>Matikan Notif.</span>
                </Col>
                <Col span={18}>
                  <Row>
                    <Switch
                      size="small"
                      onChange={onChangeStatus}
                      defaultChecked={getDefaultValueSatus()}
                    />
                    <div className={s.switchText}>
                      <p className="text-xs">Matikan Notifikasi</p>
                      <p className="text-xs text-muted">
                        Jika anda mengaktifkan ini, notifikasi tagihan yang
                        expired tidak akan terkirim.
                      </p>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col> */}
            <Col span={24} className="mb5">
              <UploadFile
                setFile={(file) =>
                  setFieldValue("billing_pay.invoice_file", file)
                }
                initDataName={invoicePdfThumbnail}
                label="Invoice File"
                extLists={[
                  "pdf",
                  "jpg",
                  "jpeg",
                  "png",
                  "ppt",
                  "pptx",
                  "xls",
                  "xlsx",
                  "doc",
                  "docx",
                ]}
                invalidMessage="You can upload only Document/Image/PPt/Xls File !"
                isReq
              />
            </Col>
            <Col span={24} className="mb5">
              <UploadFile
                setFile={(file) =>
                  setFieldValue("billing_pay.efaktur_file", file)
                }
                initDataName={efakturPdfThumbnail}
                label="E-Faktur File"
                extLists={[
                  "pdf",
                  "jpg",
                  "jpeg",
                  "png",
                  "ppt",
                  "pptx",
                  "xls",
                  "xlsx",
                  "doc",
                  "docx",
                ]}
                invalidMessage="You can upload only Document/Image/PPt/Xls File !"
                isReq={false}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div className={s.dividerTitle}>
            <p>Informasi Vendor dan PIC</p>
          </div>
          <Col span={24}>
            <Row className="mb15" style={{ marginTop: "10px" }}>
              <div>
                <p className="text-muted text-xs">
                  Jika Vendor tidak ditemukan,
                  <span className="text-bold ml5 mr5 text-primary">
                    Silakan tambahkan vendor terlebih dahulu di menu Vendor.
                  </span>
                  Mengingat Vendor adalah data Master.
                  <Link to="/vendors">Klik Disini, Menu Vendor</Link>
                </p>
              </div>
            </Row>
            <Row>
              <Col span={6}>
                <span>Vendor</span>
              </Col>
              <Col span={18}>
                <Field name="vendor_id">
                  {(fieldProps) => (
                    <AntFields
                      {...fieldProps}
                      formItem={{
                        loading: vendorLoading,
                      }}
                      type="AntSelect"
                      placeholder="Ex: PT. Anu ABC"
                      selectOptions={vendorLists}
                      onChange={onChangeVendors}
                    />
                  )}
                </Field>
              </Col>
              {/* <Col span={6}>
                <Row justify="end">
                  <Button
                    icon={<AiOutlinePlusCircle />}
                    onClick={() => push(`/vendors`)}
                  >
                    Tambah
                  </Button>
                </Row>
              </Col> */}
            </Row>
          </Col>
          <Col span={24}>
            <Field name="vendor_bank_id">
              {(fieldProps) => (
                <AntFields
                  {...fieldProps}
                  formItem={{
                    label: "No. Rekening",
                    labelAlign: "left",
                    noReq: true,
                    loading: vendorLoading,
                  }}
                  type="AntSelect"
                  placeholder="Ex: BRI(229184924)"
                  selectOptions={vendorBankLists}
                />
              )}
            </Field>
          </Col>
          <Col span={24}>
            <Field name="pic_name">
              {(fieldProps) => (
                <AntFields
                  {...fieldProps}
                  formItem={{
                    label: "Nama PIC",
                    labelAlign: "left",
                    noReq: true,
                  }}
                  type="AntInput"
                  placeholder="Ex: Gogon S."
                />
              )}
            </Field>
          </Col>
          <Col span={24}>
            <Row>
              <Col xs={24} lg={6}>
                <span>Telepon PIC</span>
              </Col>
              <Col xs={24} lg={18}>
                <Field name="pic_telp">
                  {(fieldProps) => (
                    <AntFields
                      {...fieldProps}
                      type="AntInput"
                      placeholder="Ex: 82216829353"
                      addonBefore="+62"
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
      <Row justify="end" align="middle">
        <Button
          icon={<AiOutlineSave />}
          onClick={handleSubmit as any}
          loading={isSubmitting}
          type="primary"
          htmlType="submit"
          className="p-hor"
        >
          Save
        </Button>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
