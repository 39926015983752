import { Table, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selector as selectorMovementVehicles } from "app/redux/movement_vehicles";
import { selector as selectorVehicles } from "app/redux/vehicles";
import { get as getMovement } from "app/req/movement_vehicles";
import { removeEmpty } from "app/utils/functions";
import { useParams } from "react-router";

dayjs.extend(relativeTime);

const Index = (props) => {
  const pageParams = useParams<any>();
  // Change Module
  const datas = useSelector(selectorMovementVehicles).records;
  const pagination = useSelector(selectorMovementVehicles).pagination;
  const dataHeader = useSelector(selectorVehicles).detail;

  const [loading, setLoading] = React.useState(true);
  const currentPage = _.get(pagination, "current_page") || 1;
  const total = _.get(pagination, "total") || 1;
  const pageSize = _.get(pagination, "per_page") || 15;

  const fetchSetData = async (params?: any) => {
    const idParent = pageParams?.id;
    setLoading(true);
    params = {
      ...params,
    };
    await Promise.all([getMovement(idParent, params)]);
    setLoading(false);
  };

  const [initData, setInitData] = React.useState<any>(null);
  const [visibleRenew, setVisibleRenew] = React.useState(false);
  const [visibleMovement, setVisibleMovement] = React.useState(false);
  const renewShowModal = async (record) => {
    const tmpInit = {
      header_meta: {
        vehicle_id: dataHeader?.id,
        expired_date: dataHeader?.plate?.plate_tax_active?.expired_date,
      },
    };
    setInitData(tmpInit);
    setVisibleRenew(true);
  };
  const movementShowModal = async (record) => {
    const location_active = _.get(dataHeader, "location_active");
    const tmpInit = {
      header_meta: {
        vehicle_id: dataHeader?.id,
        location_active: location_active,
      },
    };
    setInitData(tmpInit);
    setVisibleMovement(true);
  };
  const editAction = (record) => {
    const location_active = _.get(dataHeader, "location_active");
    let movement_date = record?.movement_date;
    if (movement_date) movement_date = dayjs(movement_date);
    const tmpInit = {
      id: record.id,
      isEdit: true,
      header_meta: {
        vehicle_id: dataHeader?.id,
        location_active: location_active,
      },
      driver_name: record?.driver_name,
      location_id: record?.location?.id,
      pic_name: record?.pic_name,
      pic_telp: record?.pic_telp,
      file: record?.file_url,
      movement_date,
    };
    setInitData(tmpInit);
    setVisibleMovement(true);
  };
  const columnActions = {
    editAction,
  };
  const paginationProps = {
    current: currentPage,
    pageSize,
    total,
  };
  const onChangeTable = async (
    pagination: PaginationProps,
    filters,
    sorter
  ) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    let sort = null;
    // Filter
    let filter = null;
    const listFilter = [];
    listFilter.map((item) => {
      const valueFilter = _.get(filters, item);
      if (valueFilter) {
        filter = {
          ...filter,
          [item]: _.get(filters, item),
        };
      }
    });
    // End Filter
    const sortField = _.get(sorter, "field");
    const sortOrder = _.get(sorter, "order");
    if (sortField) sort = sortField;
    if (sortOrder == "descend") sort = `-${sortField}`;
    fetchSetData({ page, limit, sort, filter });
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    const limit = 15;
    if (mounted) fetchSetData({ page: 1, limit });
    return () => (mounted = false);
    // End Safety
  }, [props.searchText]);
  React.useEffect(() => {
    let mounted = true;
    const limit = 15;
    if (mounted) {
      fetchSetData({ page: 1, limit });
    }
    return () => (mounted = false);
  }, []);

  return {
    // Function
    onChangeTable,
    setVisibleMovement,
    movementShowModal,
    // State/Const
    dataHeader,
    datas,
    loading,
    paginationProps,
    initData,
    visibleMovement,
    columnActions,
  };
};
export default Index;
