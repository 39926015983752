import * as layout from "./form_layout";
import Datepicker from "app/_components/ant_design/picker_dayjs/datepicker";
import { Button, Col, Form, Row, Tooltip } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import AntFields from "app/_components/ant_design/ant_custom";
import TextLabel from "app/_components/ant_design/private_label";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import UploadPhoto from "./upload_photo";
import { API_HOST } from "app/utils/consts";
import useLogic from "./_logic";
import { AiFillInfoCircle, AiOutlineSave } from "react-icons/ai";
import { InfoCircleFilled } from "@ant-design/icons";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const { formLayout } = layout;
  const { isSubmitting, setFieldValue, values, isValid, handleSubmit } = props;
  return (
    <Form {...formLayout}>
      <Row align="middle">
        <Col span={24}>
          <Field name="name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Vendor",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: PT. Amerta"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <TextLabel label="No. Telepon" isRequired={false} />
            </Col>
            <Col span={16}>
              <Field name="telp">
                {(fieldProps) => (
                  <AntFields
                    {...fieldProps}
                    type="AntInput"
                    placeholder="Ex: 082216829342"
                  />
                )}
              </Field>
            </Col>
            <Col span={2}>
              <Row justify="end" align="middle">
                <Tooltip title="Masukan Titik Koma (;) untuk lebih dari satu">
                  <InfoCircleFilled
                    style={{ color: "#b72018", fontSize: "18px" }}
                    className="mt8"
                  />
                </Tooltip>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Field name="address">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Alamat",
                  labelAlign: "left",
                  noReq: true,
                }}
                type="AntTextArea"
                rows={3}
                placeholder="Ex: PT. Amerta"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="vendor_bank.name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Bank",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: BCA"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="vendor_bank.number">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nomor Rek.",
                  labelAlign: "left",
                }}
                type="AntInput"
                placeholder="Ex: 482829592312"
              />
            )}
          </Field>
        </Col>
        <Col span={24}>
          <Field name="vendor_bank.pic_name">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Atas Nama",
                  labelAlign: "left",
                  noReq: true,
                }}
                type="AntInput"
                placeholder="Ex: Jajang Nurjaman"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mt5">
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Simpan
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
