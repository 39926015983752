import React from "react";
import { useSelector } from "react-redux";
import { get, setFilter, setSort, setPagination } from "app/req/vehicles";
import { selector } from "app/redux/vehicles";
import dayjs from "dayjs";
import initGetCardList from "../_logic";

export default () => {
  const init = initGetCardList({
    get,
    setSort,
    setPagination,
    setFilter,
    selector,
  });
  return {
    ...init,
  };
};
