import React from "react";
import { Row, Col, Typography, Space, Divider, Tag, Tooltip } from "antd";
import { FaBriefcase, FaRegEye } from "react-icons/fa";
import styles from "./index.module.scss";
import { TiDocumentText } from "react-icons/ti";
import { convertToRupiah } from "app/utils/functions";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
const { Text } = Typography;

type TProps = {
  id: string;
  name: string;
  price: string;
  expiredDate: string;
};

const Index: React.FC<TProps> = (props: TProps) => {
  const { expiredDate, name, price } = props;
  let remainingDays = dayjs(expiredDate).fromNow();
  return (
    <>
      <Row justify="space-between">
        <Col span={16}>
          <Row>
            <TiDocumentText className={styles.iconVacancy} />
            <div>
              <Tooltip title={name}>
                <Text ellipsis className={styles.textElipsis}>
                  <Link
                    to={`/billings/detail/${props.id}`}
                    className="text-xs text-bold"
                  >
                    {name}
                  </Link>
                </Text>
              </Tooltip>
              <p className="text-xs text-muted">{convertToRupiah(price)}</p>
            </div>
          </Row>
        </Col>
        <Col>
          <p className="text-xs text-muted">{remainingDays}</p>
        </Col>
      </Row>
      <Divider className="mb10" />
    </>
  );
};

export default React.memo(Index);
