import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { kindList } from "../../const";
export default (props) => {
  const { values } = props;
  let image_url = _.get(values, "image_url");
  if (image_url) {
    image_url = `${API_HOST}/storage${image_url}`;
  }
  return {
    kindList,
    image_url,
  };
};
