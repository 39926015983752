import React from "react";
import req from "app/utils/http_client_query_builder";

const Index = (props) => {
  const { taxPositionId, refresh } = props;
  const [datas, setDatas] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const initFunction = async () => {
    setLoading(true);
    const resTmp = await req.get({
      endpoint: `plate_positions/${taxPositionId}`,
    });
    setDatas(resTmp.data);
    setLoading(false);
  };
  React.useEffect(() => {
    initFunction();
  }, [props?.refresh]);
  return {
    datas,
    loading,
  };
};
export default Index;
