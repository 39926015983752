import _ from "lodash";
import dayjs from "dayjs";
import { IValues } from "../_components/form/form_proto";
import { TDetailVehicle } from "./type";

const adapterToEditForm = (dirtyData: TDetailVehicle): IValues => {
  let brand_id: any = _.get(dirtyData, "brand.id");
  let pic_id: any = _.get(dirtyData, "pic.id");
  let vehicle_model_id: any = _.get(dirtyData, "vehicle_model.id");
  let owner_id: any = _.get(dirtyData, "owner.id");
  let wheel_id: any = _.get(dirtyData, "wheel.id");

  let insurance_vendor_id = _.get(dirtyData, "insurance_active.vendor_id");
  if (insurance_vendor_id) insurance_vendor_id = parseInt(insurance_vendor_id);

  // brand_id = brand_id ? brand_id.toString() : "";
  // pic_id = brand_id ? pic_id.toString() : "";
  // vehicle_model_id = vehicle_model_id ? vehicle_model_id.toString() : "";

  // init Dayjs
  let expired_date = _.get(dirtyData, "plate.plate_tax_active.expired_date");
  expired_date = expired_date ? dayjs(expired_date) : null;
  let expired_date_kir = _.get(dirtyData, "kir_active.expired_date");
  expired_date_kir = expired_date_kir ? dayjs(expired_date_kir) : null;
  let expired_date_insurance = _.get(
    dirtyData,
    "insurance_active.expired_date"
  );
  expired_date_insurance = expired_date_insurance
    ? dayjs(expired_date_insurance)
    : null;
  let production_year = _.get(dirtyData, "plate.production_year");
  production_year = production_year ? dayjs(production_year) : null;
  let plate_expired_month = _.get(
    dirtyData,
    "plate.plate_tax_active.plate_expired_month"
  );
  plate_expired_month = plate_expired_month ? dayjs(plate_expired_month) : null;
  let start_rent = _.get(dirtyData, "rent_vehicle_active.start");
  start_rent = start_rent ? dayjs(start_rent) : null;
  let end_rent = _.get(dirtyData, "rent_vehicle_active.end");
  end_rent = end_rent ? dayjs(end_rent) : null;

  let show_owner = false;
  if (owner_id) show_owner = true;

  const dataIValues: IValues = {
    brand_id,
    pic_id,
    wheel_id,
    vehicle_model_id,
    owner_id,
    show_owner,
    color: _.get(dirtyData, "color"),
    description: _.get(dirtyData, "description"),
    name: _.get(dirtyData, "name"),
    photo: _.get(dirtyData, "image_url"),
    // price: _.get(dirtyData, "price"),
    plate: {
      name: _.get(dirtyData, "plate.name"),
      location_code: _.get(dirtyData, "plate.location_code"),
      bpkb_number: _.get(dirtyData, "plate.bpkb_number"),
      machine_number: _.get(dirtyData, "plate.machine_number"),
      register_number: _.get(dirtyData, "plate.register_number"),
      stnk_number: _.get(dirtyData, "plate.stnk_number"),
      plate_number: _.get(dirtyData, "plate.plate_number"),
      production_year,
      position_status: _.get(dirtyData, "plate.position_status"),
      position_bpkb: _.get(dirtyData, "plate.position_bpkb"),
      fuel: _.get(dirtyData, "plate.fuel"),
      type: _.get(dirtyData, "plate.type"),
    },
    plate_tax: {
      expired_date,
      plate_expired_month,
      status: _.get(dirtyData, "plate.plate_tax_active.status"),
    },
    rent_vehicle: {
      contract_number: _.get(dirtyData, "rent_vehicle_active.contract_number"),
      price: _.get(dirtyData, "rent_vehicle_active.price"),
      start: start_rent,
      end: end_rent,
    },
    kir: {
      number: _.get(dirtyData, "kir_active.number"),
      expired_date: expired_date_kir,
    },
    insurance: {
      vendor_id: insurance_vendor_id,
      number: _.get(dirtyData, "insurance_active.number"),
      expired_date: expired_date_insurance,
    },
  };
  return dataIValues;
};
export { adapterToEditForm };
