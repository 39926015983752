import { Table, Input, Row, Col, Card, Select, Button } from "antd";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DatePicker from "app/_components/ant_design/picker_dayjs/datepicker";
import _ from "lodash";
import useLogic from "./_logic";
import {
  AiOutlineSearch,
  AiOutlineDown,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineVerticalAlignBottom,
} from "react-icons/ai";
import s from "./index.module.scss";

dayjs.extend(relativeTime);

const { RangePicker } = DatePicker;
const { Search } = Input;

interface IProps {
  onFilterToggle: any;
  filterToggle: any;
  onSearch: any;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { filterToggle, onFilterToggle, onSearch } = props;
  return (
    <Row justify="space-between" align="middle" className="mt5">
      <Col span={18}>
        <Row justify="start">
          <Button
            icon={filterToggle ? <AiOutlineArrowUp/> : <AiOutlineArrowDown />}
            onClick={() => onFilterToggle(!filterToggle)}
          >
            Filter Lanjutan
          </Button>
        </Row>
      </Col>
      <Col span={6}>
        <Row justify="end">
          <Search placeholder="Cari Kendaraan" onSearch={onSearch} />
        </Row>
      </Col>
    </Row>
  );
};
export default Index;
