import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selector as selectorAuth } from "app/redux/auth";
import { message } from "antd";
export type TPermission = { subject: string; permissions: any };

const objPermissions = (paramsDatas: Array<string>): Array<TPermission> => {
  let dataPerms: Array<TPermission> = [];
  paramsDatas.map((item) => {
    const tmpItems = item.split(".");
    const tmpSubject = tmpItems[0];
    const tmpPermissions = tmpItems[1];
    const tmpIndex = _.findIndex(dataPerms, { subject: tmpSubject });
    if (tmpIndex >= 0) {
      let arrPermissions = _.get(dataPerms[tmpIndex], "permissions") || [];
      arrPermissions.push(tmpPermissions);
      dataPerms[tmpIndex].permissions = arrPermissions;
    } else {
      let arrPermissions = [];
      arrPermissions.push(tmpPermissions);
      dataPerms.push({ subject: tmpSubject, permissions: arrPermissions });
    }
  });
  return dataPerms;
};
const stringPermissions = (
  paramDatas: Array<TPermission> | TPermission
): Array<string> => {
  let dataPerms: Array<string> = [];
  const convert = (item) => {
    const tmpSubject = item.subject;
    const tmpPermissions = item.permissions;
    tmpPermissions.map((tmpPerm) => {
      const tmpJoined = _.join([tmpSubject, tmpPerm], ".");
      dataPerms.push(tmpJoined);
    });
  };
  if (_.isArray(paramDatas)) {
    paramDatas.map((item) => {
      convert(item);
    });
  } else {
    convert(paramDatas);
  }
  return dataPerms;
};

const getAuthorize = (
  subject: string,
  permission: string,
  noAction?: boolean,
  noSelfIncluded:boolean = true
): boolean => {
  let allowed = false;
  const { push } = useHistory();
  const dataAuthPermissions = useSelector(selectorAuth).objPermissions;
  dataAuthPermissions.map((item) => {
    let currentSubject = _.get(item, "subject") == subject;
    if (currentSubject) {
      item.permissions.map((tmpPerms) => {
        let currentPermissions = tmpPerms == permission;
        let selfPermission = false;
        if(noSelfIncluded) selfPermission = tmpPerms == "self";
        if(currentPermissions || selfPermission){
          allowed = true;
        };
      });
    }
  });
  if (!allowed && !noAction) {
    message.error("Ups, Not Authorize !");
    push("/");
  }
  return allowed;
};

export { objPermissions, stringPermissions, getAuthorize };
