import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import { message, Modal } from "antd";
import Form from "./form";
import { IValues } from "./form/form_proto";
import Req from "app/utils/http_client_query_builder";
import {
  get as getVehicles,
  getDetail as getVehicleDetail,
} from "app/req/vehicles";
import { get as getMovements } from "app/req/movement_vehicles";
import { useHistory } from "react-router-dom";


dayjs.extend(relativeTime);

interface IProps {
  initData?: IValues;
  setVisible: any;
  visible: boolean;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { setVisible, visible } = props;
  let history = useHistory();
  let movement_date =
    _.get(props, "initData.header_meta.movement_date") || null;
  if (movement_date) movement_date = dayjs(movement_date);
  const tmpInitData: IValues = {
    ...props?.initData,
    header_meta: {
      ...props?.initData?.header_meta,
    },
  };

  const onSubmit = async (values: IValues) => {
    const vehicleId = values?.header_meta?.vehicle_id;
    const id = values?.id;
    delete values.id;
    delete values.header_meta;
    let valuesFormData = new FormData();
    for (let key in values) valuesFormData.append(key, values[key]);
    let resPost: any = null;
    if (values?.isEdit) {
      resPost = await Req.patch({
        endpoint: `location_vehicles/${id}`,
        data: valuesFormData,
      });
    } else {
      resPost = await Req.post({
        endpoint: `location_vehicles/${vehicleId}`,
        data: valuesFormData,
      });
    }
    if (resPost.status == "success") {
      await Promise.all([
        getVehicles(),
        getMovements(vehicleId),
        getVehicleDetail(vehicleId),
      ]);
      message.success("Success Edit/Move Data Movement");
      history.push(`/vehicles`)
    }
    setVisible(false);
  };
  return (
    <Modal
      title="Form Modal - Perpindahan Lokasi Kendaraan"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      style={{ top: 25 }}
    >
      <Form onSubmit={onSubmit} initData={tmpInitData} />
    </Modal>
  );
};
export default Index;
