const fuelLists = [
  { label: "Bensin", value: "gasoline" },
  { label: "Diesel", value: "diesel" },
  { label: "Gas", value: "gas" },
  { label: "Listrik", value: "electric" },
  { label: "Bensin Penerbangan", value: "aviation gasoline" },
  { label: "Kerosin Penerbangan", value: "aviation kerosine" },
  { label: "Lainya", value: "other" },
];
const positionBpkbLists = [
  { label: "HKI", value: "HKI" },
  { label: "Leasing", value: "Leasing" },
];
const positionLists = [
  { label: "Samsat", value: "Samsat" },
  { label: "Kantor Pusat", value: "Kantor Pusat" },
  { label: "Di Proyek", value: "Di Proyek" },
];
export { fuelLists, positionBpkbLists, positionLists };
