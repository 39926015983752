import React from "react";
import { Button, Avatar, Image, Typography, Tag, Tooltip } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";
import { FaEye } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";

dayjs.extend(relativeTime);

const columns = (actions): any => {
  const getFileUrl = (text) => `${API_HOST}/storage${text}`;
  const getRenderFile = (tmp, label) => {
    if (tmp) {
      const linkUrl = getFileUrl(tmp);
      return (
        <Tooltip title="Click to Downlaod">
          <a target="_blank" href={linkUrl}>
            {label}
          </a>
        </Tooltip>
      );
    } else {
      return <p>--</p>;
    }
  };
  const checkStatus = (dataStatus) => {
    if (dataStatus === "active") {
      dataStatus = <Tag color="geekblue">Aktif</Tag>;
    } else {
      dataStatus = <Tag>Non Aktif</Tag>;
    }
    return dataStatus;
  };
  return [
    {
      title: "Nomor KIR",
      render: (item, record) => {
        let tmp = _.get(record, "number") || false;
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Tgl. Masa Berlaku",
      render: (item, record) => {
        let tmp = _.get(record, "expired_date") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY");
        return tmp ? tmp : "--";
      },
    },
    {
      title: "Dokumen Pendukung",
      render: (item, record) => {
        let tmp = _.get(record, "file_url") || false;
        return tmp ? getRenderFile(tmp, "File Dokumen") : "--";
      },
    },
    {
      title: "Tanggal Pembuatan",
      render: (item, record) => {
        let tmp = _.get(record, "created_at") || false;
        if (tmp) tmp = dayjs(tmp).format("DD MMMM YYYY HH:mm");
        return tmp ? `${tmp} WIB` : "--";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 80,
      render: (item, record) => {
        let tmp = _.get(record, "status") || false;
        return checkStatus(tmp);
      },
    },
    {
      title: "Aksi",
      dataIndex: "status",
      width: 80,
      render: (item, record) => {
        return (
          <Button
            icon={<AiOutlineEdit />}
            onClick={() => actions.editAction(record)}
          >
            Edit
          </Button>
        );
      },
    },
  ];
};

export default columns;
