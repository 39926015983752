import { API_HOST } from "app/utils/consts";
import _ from "lodash";
import React from "react";
import { convertToSelect } from "app/utils/functions";
import Req from "app/utils/http_client_query_builder";
import dayjs from "dayjs";
import { Tooltip } from "antd";

export default (props) => {
  const { values } = props;
  const getFileThumnail = () => {
    let filePdfTmp: any = _.get(values, "file");
    if (filePdfTmp) {
      if (typeof filePdfTmp == "object") {
        filePdfTmp = filePdfTmp?.name;
      } else if (filePdfTmp != "" || filePdfTmp != "empty") {
        filePdfTmp = filePdfTmp;
      } else {
        filePdfTmp = filePdfTmp ? filePdfTmp : "--";
      }
    }

    return {
      filePdfThumbnail: filePdfTmp,
    };
  };
  const [kirsLoading, setKirsLoading] = React.useState(false);
  const [kirsLists, setKirsLists] = React.useState<any>([]);
  // Utils AutoCopmlete
  const getParams = ({ search, id }) => {
    let myParams: any = {
      filter: { name: search },
      limit: 5,
    };
    if (id) {
      myParams = {
        ...myParams,
        custom: { id },
      };
    }
    return myParams;
  };
  const getConvertLists = (datas) => {
    return convertToSelect({
      datas: datas,
      labelName: "name",
      valueName: "id",
    });
  };

  // Kirs
  const onSearchKirs = async (search) => {
    await getKirs({ search, id: null });
  };
  const getKirs = async ({ search, id }) => {
    setKirsLoading(true);
    let params: any = getParams({ search, id });
    let endpoint = `kirs`;
    const res = await Req.get({ endpoint, params });
    if (res.status == "success") {
      const tmpLists = getConvertLists(res.data);
      setKirsLists(tmpLists);
    }
    setKirsLoading(false);
  };

  const initFunction = async () => {};
  React.useEffect(() => {
    initFunction();
  }, []);
  return {
    onSearchKirs,
    kirsLoading,
    kirsLists,
    filePdfThumbnail: getFileThumnail().filePdfThumbnail,
  };
};
