import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import publicRoutes from "./public_routes";
import privateRoutes from "./private_routes";
import notFoundScreen from "app/pages/public/notfound";
import { PublicRoute, PrivateRoute } from "./checker";
import Loading from "app/_components/loading/auth";

import useLogic from "./_logic";
import _ from "lodash";
import { ConfigProvider } from "antd";
import idID from "antd/es/locale/id_ID";

const Index: React.FC<any> = (props: any) => {
  const { initial, loading } = useLogic();
  React.useEffect(() => {
    initial();
  }, []);
  if (loading) return <Loading />;
  return (
    <ConfigProvider locale={idID}>
      <Router>
        <Switch>
          {publicRoutes.map((item, i) => {
            return (
              <PublicRoute
                key={i}
                path={item.path}
                exact={item.exact}
                component={item.component}
              />
            );
          })}
          {privateRoutes.map((item, i) => {
            return (
              <PrivateRoute
                key={i}
                path={item.path}
                exact={item.exact}
                component={item.component}
              />
            );
          })}
          <Route component={notFoundScreen} />
        </Switch>
      </Router>
    </ConfigProvider>
  );
};
export default Index;
