import React from "react";
import s from "./index.module.scss";
import { Row, Col, message, Spin } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import Form from "./form";
import { useSelector } from "react-redux";
import { selector as selectorRoles } from "app/redux/roles";
import { patch } from "app/req/roles";
import { get as getRoles } from "app/req/roles";
import { FormikHelpers } from "formik";

dayjs.extend(relativeTime);

interface IProps {
  roleSelected: string;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const [init, setInit] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  const dataRoles = useSelector(selectorRoles);
  let { roleSelected } = props;
  const initFunction = async () => {
    setLoading(true);
    await getRoles();
    let indexRole = _.findIndex(dataRoles, { name: roleSelected });
    let tmpInit = _.get(dataRoles, `[${indexRole}].permissions`) || [];
    setInit(tmpInit);
    setLoading(false);
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    if (mounted) initFunction();
    return () => (mounted = false);
  }, [roleSelected]);
  const onSubmit = async (values, action: FormikHelpers<any>) => {
    const res = await patch(roleSelected, values);
    if (_.get(res, "status") == "success") {
      message.success("Success Updated Role");
      await getRoles();
    } else {
      action.resetForm();
    }
  };
  return (
    <Row>
      <Col span={24}>
        <Row justify="space-between">
          <p className="text-lg">Akses Untuk Grup : {roleSelected || "--"}</p>
        </Row>
        <Row gutter={[15, 5]} className={`${s.loadingWrapper} mt10`}>
          {roleSelected ? (
            <Col span={24}>
              <Spin spinning={loading} className={s.spinner}>
                <Form onSubmit={onSubmit} initData={{ permissions: init }} />
              </Spin>
            </Col>
          ) : (
            <Row justify="center" align="middle" className={s.loading}>
              <p className="text-bold text-muted">Mohon Pilih Akses Grup</p>
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
};
export default Index;
