import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeReducer, TypeData } from "./types";
import _ from "lodash";

const initialState: TypeReducer = {
  data: {
    dataObject: null,
    dataString: [],
  },
};

const permissionsSlice = createSlice({
  name: "permissionsSlice",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TypeData>) => {
      const getPayload = _.get(action, "payload") || initialState.data;
      state.data = { ...getPayload };
    },
  },
});
export const { setData } = permissionsSlice.actions;
export default permissionsSlice.reducer;
