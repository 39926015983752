import React from "react";
import MyFormik, { IProps, IState } from "app/_components/formik";
import initDataProto, { IValues } from "./form_proto";
import dayjs from "dayjs";
import CompForm from "./comp_form";
import _,{get} from "lodash";
import { valid, validEdit } from "./form_validator";

interface MyProps extends IProps {
  initData?: IValues;
}
interface MyState extends IState {
  initData: IValues;
}

class Index extends MyFormik<MyProps, MyState> {
  static getDerivedStateFromProps(nextProps: MyProps, prevState: MyState) {
    const hasUpdateInitData = nextProps.initData !== prevState.initData;
    if (hasUpdateInitData) {
      const newValue = nextProps.initData;
      if (!_.isUndefined(newValue)) {
        return {
          initData: newValue,
        };
      }
      return null;
    }
    return null;
  }
  protected getChildren() {
    return CompForm;
  }
  protected getValidator() {
    if (this.props.initData) {
      return validEdit;
    }
    return valid;
  }
  protected getInitData() {
    const { initData } = this.props;
    let result = initDataProto;
    if (!_.isEmpty(initData)) result = initData;
    return result;
  }
}

const IndexConverted: React.FC<MyProps> = (props: MyProps) => {
  function isEmptyObject(obj) {
    return !Object.values(obj).some((element) => {
      let hasil = element !== null || element !== "";
      return hasil;
    });
  }

  const [initDataState, setInitDataState] = React.useState<IValues>(
    props.initData
  );
  const onSubmit = async (values: IValues) => {
    let expired_date = dayjs(values?.plate_tax?.expired_date).format(
      "YYYY-MM-DD"
    );
    let expired_date_kir = values?.kir?.expired_date && dayjs(values?.kir?.expired_date).format("YYYY-MM-DD");
    let expired_date_insurance = values?.insurance?.expired_date && dayjs(values?.insurance?.expired_date).format(
      "YYYY-MM-DD"
    );
    let production_year = dayjs(values?.plate?.production_year).format("YYYY");
    let plate_expired_month = dayjs(
      values?.plate_tax?.plate_expired_month
    ).format("YYYY-MM");
    let start_rent = get(values,'rent_vehicle.start');
    if (start_rent) start_rent = dayjs(start_rent).format("YYYY-MM-DD");
    let end_rent = get(values,'rent_vehicle.end');
    if (end_rent) end_rent = dayjs(end_rent).format("YYYY-MM-DD");

    const plate = {
      ...values.plate,
      production_year,
    };
    const plate_tax = {
      ...values.plate_tax,
      plate_expired_month,
      expired_date,
    };
    const kir = {
      ...values.kir,
      expired_date: expired_date_kir,
    };
    const insurance = {
      ...values.insurance,
      expired_date: expired_date_insurance,
    };
    const rent_vehicle = {
      ...values.rent_vehicle,
      start: start_rent,
      end: end_rent,
    };
    let pic_telp = values?.location?.pic_telp ?? null;
    let location = {
      ...values.location,
    };
    if (pic_telp) {
      pic_telp = `+62-${pic_telp}`;
      location = {
        ...values.location,
        pic_telp,
      };
    }
    delete values.kir;
    delete values.insurance;
    delete values.plate;
    delete values.plate_tax;
    delete values.location;
    delete values.rent_vehicle;
    let owner_id = values?.owner_id ?? "";
    values = {
      ...values,
      rent_vehicle,
      kir,
      insurance,
      plate,
      plate_tax,
      location,
    };
    await props.onSubmit(values);
  };
  const initFunction = async () => {
    await setInitDataState(props.initData);
  };
  React.useEffect(() => {
    initFunction();
  }, [props.initData]);
  return <Index onSubmit={onSubmit} initData={initDataState} />;
};

export default IndexConverted;
