import { Table, message, Row, Col, Card, Spin } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";

dayjs.extend(relativeTime);

interface IProps {
  initData: any;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const { initData } = props;
  const { created_at, updated_at } = initData;
  const checkCreatedAt = created_at != "--" && created_at != "";
  const createdAt = checkCreatedAt
    ? `${dayjs(created_at).format("DD MMMM YYYY HH:mm")} WIB`
    : "--";
  const checkUpdatedAt = updated_at != "--" && updated_at != "";
  const updatedAt = checkUpdatedAt
    ? `${dayjs(updated_at).format("DD MMMM YYYY HH:mm")} WIB`
    : "--";
  return (
    <Card className="mb25">
      <Row>
        <Col xs={24} xl={12}>
          <div>
            <span className="text-bold">Lembaga : </span>
            <span>
              {initData?.institution} ({initData?.title ?? "--"})
            </span>
          </div>
          <div>
            <span className="text-bold">Dibuat oleh : </span>
            <span className="mr10">{initData?.updater?.name ?? "--"}</span>
            <span>{initData?.creator?.email}</span>
          </div>
          <div>
            <span className="text-bold">Dibuat pada : </span>
            <span>{createdAt}</span>
          </div>
        </Col>
        <Col xs={24} xl={12}>
          <div>
            <span className="text-bold">Type : </span>
            <span>{initData?.document_type?.name ?? "--"}</span>
          </div>
          <div>
            <span className="text-bold">Terakhir diperbarui oleh : </span>
            <span className="mr10">{initData?.updater?.name ?? "--"}</span>
            <span>{initData?.updater?.email}</span>
          </div>
          <div>
            <span className="text-bold">Terakhir diperbarui pada : </span>
            <span>{updatedAt}</span>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
export default Index;
