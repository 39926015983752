import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeReducer, TypeData, TypeDetail } from "./types";
import _ from "lodash";

const initialState: TypeReducer = {
  data: {
    records: [],
    detail: {},
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 15,
      total: 0,
    },
    filter: null,
    sort: null,
    loading: false,
    search: "",
  },
};

const vehiclesSlice = createSlice({
  name: "vehiclesSlice",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.data.records = payload ?? initialState.data.records;
    },
    setPagination: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const tmpPagination = state.data.pagination;
      const { current_page, per_page, last_page, total } = payload;
      state.data.pagination.current_page =
        current_page ?? tmpPagination.current_page;
      state.data.pagination.per_page = per_page ?? tmpPagination.per_page;
      state.data.pagination.last_page = last_page ?? tmpPagination.last_page;
      state.data.pagination.total = total ?? tmpPagination.total;
    },
    setFilter: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.data.filter = payload ?? initialState.data.filter;
    },
    setSort: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.data.sort = payload ?? state.data.sort;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.data.loading = payload ?? initialState.data.loading;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.data.search = payload ?? initialState.data.search;
    },
    setDetail: (state, action: PayloadAction<TypeDetail>) => {
      const getPayload = _.get(action, "payload") || initialState.data.detail;
      state.data.detail = { ...getPayload };
    },
  },
});
export const {
  setData,
  setFilter,
  setLoading,
  setPagination,
  setSort,
  setDetail,
  setSearch,
} = vehiclesSlice.actions;
export default vehiclesSlice.reducer;
