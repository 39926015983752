import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeReducer, TypeData } from "./types";
import _ from "lodash";

const initialState: TypeReducer = {
  data: {
    records: [],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 15,
      total: 0,
    },
  },
};

const documentTypesSlice = createSlice({
  name: "documentTypesSlice",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TypeData>) => {
      const getPayload = _.get(action, "payload") || initialState.data;
      state.data = { ...getPayload };
    },
  },
});
export const { setData } = documentTypesSlice.actions;
export default documentTypesSlice.reducer;
