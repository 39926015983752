import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeReducer, TypeData } from "./types";
import _ from "lodash";

const initialState: TypeReducer = {
  data: {
    total_billings: 0,
    total_documents: 0,
    total_vehicles: 0,
    total_vendors: 0,
  },
};

const countMastersSlice = createSlice({
  name: "countMastersSlice",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TypeData>) => {
      const getPayload = _.get(action, "payload") || initialState.data;
      state.data = { ...getPayload };
    },
  },
});
export const { setData } = countMastersSlice.actions;
export default countMastersSlice.reducer;
