import React from "react";
import s from "./index.module.scss";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { Row, Button, Tag, Dropdown, Tooltip, message } from "antd";
import ContextMenu from "./_components/context_menu";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";
import ModalAdd from "./_components/add_roles";
import {
  post,
  del,
  get as getRoles,
  setDefault as setDefaultRole,
} from "app/req/roles";
import { useSelector } from "react-redux";
import { selector as selectorRoles } from "app/redux/roles";

dayjs.extend(relativeTime);

interface IProps {
  onChangeRoles: (roleName: any) => void;
  roleSelected: string;
}

const Index: React.FC<IProps> = (props: IProps) => {
  const [visible, setVisible] = React.useState(false);
  const { onChangeRoles, roleSelected } = props;
  const dataRoles = useSelector(selectorRoles);
  const onClickMenu = (item) => {
    onChangeRoles(item.name);
  };
  const getDataRoles = async () => {
    await getRoles();
  };
  // Roles
  const addAction = async (values) => {
    const res = await post(values);
    if (res) {
      message.success("Success Add Role");
      getDataRoles();
    }
  };
  const delAction = async (roleName) => {
    const res = await del(roleName);
    if (res) {
      message.success(`Success Delete Role`);
      getDataRoles();
      onChangeRoles(false);
    }
  };
  const defaultAction = async (roleName) => {
    setDefaultRole(roleName);
    getDataRoles();
  };
  React.useEffect(() => {
    // For Safety Fetch
    let mounted = true;
    if (mounted) getDataRoles();
    return () => (mounted = false);
  }, []);
  return (
    <React.Fragment>
      <Row justify="space-between" align="middle">
        <p className="text-lg">Akses Grup</p>
        <Button
          icon={<AiOutlinePlusCircle />}
          className="p-hor"
          onClick={() => setVisible(true)}
        >
          Tambah Akses Grup
        </Button>
      </Row>
      <div className={s.roleWrapper}>
        {_.isEmpty(dataRoles) ? (
          <Row justify="center" align="middle" className={s.loadingWrapper}>
            <p className="text-bold text-muted">Please Wait ...</p>
          </Row>
        ) : (
          dataRoles.map((item, i) => {
            let myClassname = s.roleItem;
            let isDefault = item.is_default;
            if (roleSelected == item.name) myClassname = s.roleItemActive;
            return (
              <ContextMenu
                actions={{ delAction, defaultAction }}
                role={item}
                key={i}
              >
                <Tooltip
                  title="Right Click for show Menu"
                  mouseEnterDelay={1.3}
                >
                  <div
                    className={myClassname}
                    onClick={() => onClickMenu(item)}
                    onContextMenu={() => onClickMenu(item)}
                  >
                    <div>
                      <div>
                        <span>Nama Akses Grup : {item.name}</span>
                        {isDefault && (
                          <Tag className="ml8">
                            <span className="text-xs">Default</span>
                          </Tag>
                        )}
                      </div>
                      <p className="text-muted">
                        <span className="mr3">Terakhir update : </span>
                        {`${dayjs(item.updated_at, "YYYY-MM-DD HH:mm").format(
                          "DD MMMM YYYY HH:mm"
                        )} WIB`}
                      </p>
                    </div>
                    <IoIosArrowForward />
                  </div>
                </Tooltip>
              </ContextMenu>
            );
          })
        )}
      </div>
      <ModalAdd
        visible={visible}
        setVisible={setVisible}
        onSubmit={addAction}
      />
    </React.Fragment>
  );
};
export default Index;
