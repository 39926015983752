export interface IValues {
  locationActive?: {
    location_id?: any;
  };
  insuranceActive?: {
    vendor_id?: any;
  };
  brand_id?: any;
  vehicle_model_id?: any;
  vehicle_variant_id?: any;
}

const proto: IValues = {};
export default proto;
