import React from "react";
import { Button, Avatar, Image, Typography, Tag, Space, Input } from "antd";
import { API_HOST } from "app/utils/consts";
import s from "./index.module.scss";
import _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DropdownMenu from "./dropdown_popup";
import { camelCaseSpaces } from "app/utils/functions";
import { kindList } from "app/pages/private/vehicle_models/_components/const";
import { SearchOutlined } from "@ant-design/icons";
import { createNullishCoalesce } from "typescript";

const { Text } = Typography;

dayjs.extend(relativeTime);

type TAction = {
  detailAction: (record: any) => any;
  editAction: (record: any) => any;
  renewAction: (record: any) => any;
  movementAction: (record: any) => any;
  delAction: (record: any) => any;
  addDocumentAction: (record: any) => any;
  renewKirAction: (record: any) => any;
  renewInsuranceAction: (record: any) => any;
};

const columns = (actions: TAction, dataFilters: any): any => {
  const { dataVehicleModels, dataBrands } = dataFilters;
  return [
    {
      title: "Tipe Kendaraan",
      render: (item, record) => {
        let avatar = _.get(record, "image_url");
        if (avatar) {
          avatar = `${API_HOST}/storage${avatar}`;
        } else {
          avatar = process.env.PUBLIC_URL + "/images/placeholder.png";
        }
        return (
          <div
            className={s.rowName}
            onClick={() => actions.detailAction(record)}
          >
            <Avatar src={avatar} shape="square" />
            <Text ellipsis>{record?.plate?.type}</Text>
          </div>
        );
      },
    },
    {
      title: "Nomor Plat",
      dataIndex: "plate.plate_number",
      /*
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Cari Plat No.`}
            value={selectedKeys}
            onChange={(e) => {
              setSelectedKeys(e.target.value);
            }}
            onPressEnter={() => {}}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setSelectedKeys(null);
                clearFilters();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      */
      render: (item, record) => {
        const tmp = _.get(record, "plate.plate_number") || "--";
        return tmp;
      },
    },
    {
      title: "Merek",
      dataIndex: "brand_id",
      sorter: true,
      // filters: [...dataBrands],
      render: (item, record) => {
        const tmp = _.get(record, "brand.name") || "--";
        return tmp;
      },
    },
    {
      title: "Model",
      dataIndex: "vehicle_model_id",
      sorter: true,
      // filters: [...dataVehicleModels],
      render: (item, record) => {
        const tmp = _.get(record, "vehicle_model.name") || "--";
        return tmp;
      },
    },
    {
      title: "Jenis",
      width: 80,
      render: (item, record) => {
        const tmp = _.get(record, "vehicle_model.vehicle_variant.name") || "--";
        return camelCaseSpaces(tmp);
      },
    },
    {
      title: "Tgl. Masa Berlaku",
      dataIndex: "expired_date",
      sorter: true,
      render: (item, record) => {
        let tmp = _.get(record, "plate.plate_tax_active.expired_date") || false;
        if (tmp) {
          tmp = dayjs(tmp).format("DD MMMM YYYY");
        } else {
          tmp = "--";
        }
        return tmp;
      },
    },
    {
      title: "Aksi",
      render: (item, record) => {
        return (
          <DropdownMenu actions={actions} record={record}>
            <Button size="small" className="pl15 pr15">
              Aksi
            </Button>
          </DropdownMenu>
        );
      },
    },
  ];
};

export default columns;
