import React from "react";
import _ from "lodash";
import Layout from "app/_components/layout/private";
import s from "./index.module.scss";
import { Row, Col, Button, Divider } from "antd";
import Table from "./_components/table";
import ModalAdd from "./_components/modal/add";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Input } from "antd";
import useLogic from "./_logic";
import Filter from "./_components/filter";
import FilterIndicator from "./_components/filter_indicator";
import DropdownExport from "app/_components/other/shortcut_filter/dropdown_export";
import FilterAdditional from "./_components/filter_additional/form";
import { RiFileExcel2Line } from "react-icons/ri";
const { Search } = Input;

const Index: React.FC<any> = () => {
  const {
    addAction,
    setVisibleAdd,
    visibleAdd,
    exportExcel,
    onClickExport,
    onSearch,
    filterToggle,
    setFilterToggle,
    onSubmitFilterAdvance,
    onResetFilterAdvance,
    isAddButton,
  } = useLogic();

  return (
    <React.Fragment>
      <Layout>
        <div className="content_wrapper">
          <Row
            align="middle"
            justify="space-between"
            className="mb20"
            gutter={[15, 15]}
          >
            <Col>
              <Filter />
            </Col>
            <Col>
              <Button
                className="mr5"
                onClick={onClickExport}
                icon={<RiFileExcel2Line />}
              >
                Export Ke Excel
              </Button>
              {isAddButton && (
                <Button
                  icon={<AiOutlinePlusCircle />}
                  className="p-hor"
                  onClick={() => setVisibleAdd(true)}
                  type="primary"
                >
                  Tambah Dokumen
                </Button>
              )}
            </Col>
          </Row>
          <Row
            align="middle"
            justify="space-between"
            className="mb10"
            gutter={[15, 15]}
          >
            <Col span={24}>
              <FilterIndicator
                onFilterToggle={setFilterToggle}
                filterToggle={filterToggle}
                onSearch={onSearch}
              />
            </Col>
            {filterToggle && (
              <Col span={24}>
                <Divider className="mb15" />
                <FilterAdditional
                  onSubmit={onSubmitFilterAdvance}
                  onReset={onResetFilterAdvance}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <Table searchText={""} />
            </Col>
          </Row>
        </div>
      </Layout>
      <ModalAdd
        visible={visibleAdd}
        setVisible={setVisibleAdd}
        onSubmit={addAction}
      />
    </React.Fragment>
  );
};
export default Index;
