import * as layout from "./form_layout";
import { Button, Col, Form, Row, Checkbox } from "antd";
import { Field, FormikProps } from "formik";
import { withTranslation } from "react-i18next";
import { FiTrello } from "react-icons/fi";
import AntFields from "app/_components/ant_design/ant_custom";
import { IValues } from "./form_proto";
import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { convertToSelect } from "app/utils/functions";
import { selector as selectorRoles } from "app/redux/roles";
import { AiOutlineSave } from "react-icons/ai";

interface ITranslate extends FormikProps<IValues> {
  t?: any;
}

const Index = (props: ITranslate) => {
  const dataRoles = useSelector(selectorRoles);
  const [roles, setRoles] = React.useState<any>([]);

  React.useEffect(() => {
    if (dataRoles) {
      const tmp = convertToSelect({
        datas: dataRoles,
        valueName: "name",
        labelName: "name",
      });
      setRoles(tmp);
    }
  }, [props.values]);

  const { formLayout } = layout;
  const { handleSubmit, isSubmitting } = props;
  return (
    <Form {...formLayout}>
      <Row className="mb15" style={{ marginTop: "-15px" }}>
        <div>
          <span className="text-bold">Catatan : </span>
          <span className="text-red">
            Mohon untuk hati hati dalam mengubah Akses Grup !
          </span>
          <p className="text-muted text-xs">
            Setelah mengubah Akses Grup yang dipakai pengguna
          </p>
          <p className="text-muted text-xs">
            Aplikasi akan merefresh ulang halaman dan mengambil akses baru.
          </p>
          <p className="text-muted text-xs">
            Jika pada halaman tersebut tidak mempunyai akses, akses akan
            terblokir !
          </p>
        </div>
      </Row>
      <Row align="middle">
        <Col span={24}>
          <Field name="roles">
            {(fieldProps) => (
              <AntFields
                {...fieldProps}
                formItem={{
                  label: "Nama Akses Grup",
                  noReq: true,
                  labelAlign: "left",
                }}
                prefix={<FiTrello />}
                type="AntSelect"
                selectOptions={roles}
                mode="multiple"
                placeholder="Ex: Insert Role Name"
              />
            )}
          </Field>
        </Col>
        <Col span={24} className="mt5">
          <Row justify="end" align="middle">
            <Button
              icon={<AiOutlineSave />}
              onClick={handleSubmit as any}
              loading={isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Simpan
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default withTranslation()(Index as any);
